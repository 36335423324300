import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  submitEquipmentRegisterForm,
  getEquipmentList,
  deleteEquipment,
} from "../../../reducers/EquipmentReducer";
import { GetUploadPayload } from "./utils";
import { getCompanyList } from "../../../reducers/CompanyReducer";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridPagination
} from "@mui/x-data-grid";
import {
  Card,
  Typography,
  IconButton,
  Grid,
  Box,
  TextField,
  MenuItem,
  Tooltip,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Sample_equipment_file from "../../../utils/Sample_equipment_file.xlsx";
import { getUserList, setLoggedInUserData } from '../../../reducers/UserReducer';
import SearchBar from '../../../utils/Search';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { ThemeProvider, createTheme } from '@mui/material';
import { industryList, industyCategories } from '../CreateEquipment/utils'

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles({
  actions: {
    color: "#3E5272",
  },
  root: {
    maxWidth: "100%",
    margin: "20px",
  },
  link: {
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "rgb(180, 180, 180)",
    },
  },
  header: {
    maxWidth: "730px",
    marginLeft: "-5rem"
  },
  childDiv: {
    paddingTop: "10px",
  },
  tableheader: {
    marginTop: "-31px"
  },
  cardStyle: {
    height: "350px",
    width: "1314px",
    marginLeft: "-5rem",
    marginBottom: "-20px"
  },
  textInput: {
    paddingLeft: "10px"
  },
  dropDiv: {
    display: "flex",
    marginLeft: "-8rem"
  },
  dropDiv2: {
    paddingLeft: "70px"
  }
});

const EquipmentList = (props) => {
  const {
    equipmentList = [],
    restClient: { rest },
    getEquipmentList,
    companyList = [],
    getCompanyList,
    loggedInUserData,
    userData,
    getUserList,
    userList,
    totalCount
  } = props;

  const history = useHistory();
  const classes = useStyles();
  const [file, setFile] = useState();
  const [pageState, setPageState] = useState({
    page: 1,
    limit: 100,
    sort: null,
    searchData: '',
  });

  // console.log("equipmentList", equipmentList);
  // console.log("loggedInUserData", loggedInUserData);
  // console.log("userData", userData);

  const [rowCountState, setRowCountState] = React.useState(totalCount);
  // const [filteredEquipmentDataList, setFilteredEquipmentDataList] = useState([]);
  const [search, setSearch] = React.useState('');
  const ref = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [companyValue, setCompanyValue] = useState(null);
  const [industryValue, setIndustryValue] = useState(null);
  const [categoryValue, setCategoryValue] = useState('');
  const [filteredEquipmentList, setFilteredEquipmentList] = useState(equipmentList);

  // useEffect(() => {
  //   if (equipmentList.length > 0) {
  //     setFilteredEquipmentList(equipmentList);
  //   }
  // }, [equipmentList])
  useEffect(() => {
    const getList = async () => {
      if (rest && loggedInUserData) {
        await getEquipmentList(pageState, loggedInUserData);
        await getCompanyList(pageState);
      }
    };
    getList();
  }, [rest, getEquipmentList, loggedInUserData, getCompanyList, pageState]);

  useEffect(() => {
    if (rest) {
      const getList = async () => {
        getUserList();
      }
      getList();
    }
  }, [rest, getUserList])

  useEffect(() => {
    if(equipmentList.length > 0) {
      setRowCountState((prevRowCountState) =>
        totalCount !== undefined ? totalCount : prevRowCountState
      );
      setFilteredEquipmentList(equipmentList);
    }
  }, [totalCount, equipmentList]);

  useEffect(() => {
    if (search) {
      const filteredList = equipmentList?.filter(user => {
        const modelYearString = user.modelYear ? user.modelYear.toString() : '';
        const comparableRetailPriceString = user.comparableRetailPrice ? user.comparableRetailPrice.toString() : '';
        return (
          (user.equipmentName && user.equipmentName.toLowerCase().includes(search.toLowerCase())) ||
          (user.make && user.make.toLowerCase().includes(search.toLowerCase())) ||
          (user.model && user.model.toLowerCase().includes(search.toLowerCase())) ||
          (modelYearString && modelYearString.includes(search.toLowerCase())) ||
          (user.majorCategory && user.majorCategory.toLowerCase().includes(search.toLowerCase())) ||
          (user.mediaLink && user.mediaLink.toLowerCase().includes(search.toLowerCase())) ||
          (comparableRetailPriceString && comparableRetailPriceString.includes(search.toLowerCase()))
        );
      });
  
      setFilteredEquipmentList(filteredList);
      // if(filteredList?.length >= 0){
      setRowCountState(filteredList.length);
      // }
    } else {
      setFilteredEquipmentList(equipmentList);
      // if(equipmentList?.length > 0){
      setRowCountState(totalCount);
      // }
    }
  }, [search, totalCount]);


  // useEffect(() => {
  //   const getList = async () => {
  //     if (rest && loggedInUserData) {
  //       await getEquipmentList(pageState, loggedInUserData);
  //       await getCompanyList(pageState);
  //     }
  //   };
  //   getList();
  // }, [rest, getEquipmentList, loggedInUserData, getCompanyList, pageState]);

  // useEffect(() => {
  //   if (rest) {
  //     const getList = async () => {
  //       getUserList();
  //     }
  //     getList();
  //   }
  // }, [rest, getUserList])

  // useEffect(() => {
  //   if (rest) {
  //     const getEquipmentCompany = async () => {
  //       getCompanyList();
  //     };
  //     getEquipmentCompany();
  //   }
  // }, [rest, getCompanyList]);

  // React.useEffect(() => {
  //   setRowCountState((prevRowCountState) =>
  //     totalCount !== undefined ? totalCount : prevRowCountState
  //   );
  // }, [totalCount, setRowCountState]);

  function handleChange(event) {
    setFile(event.target.files[0])
    setDisabled(false);
  }

  const handleEquipmentEdit = (event, equipment) => {
    history.push(`/equipment/create?action=edit&id=${equipment.id}`);
  };
  const handleEquipmentView = (event, equipment) => {
    history.push(`/view/equipment?action=ViewColumn&id=${equipment.id}`);
  };
  const handleEquipmentDelete = (event, equipment) => {
    props.deleteEquipment(equipment.id, equipmentList, totalCount);
  };


  const columns = [
    {
      field: "equipmentName",
      headerName: "Equipment",
      width: 200,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.equipmentName} placement="bottom" arrow>
            <Box
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    fontWeight: "bold",
                  },
                }}
                onClick={(event) => {
                  handleEquipmentView(event, params.row);
                }}
              >
                {params.row.equipmentName}
              </Box>
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "companyName",
      headerName: "Company",
      width: 160
    },
    {
      field: "make",
      headerName: "Make",
      width: 100,
    },
    {
      field: "model",
      headerName: "Model",
      width: 120,
    },
    {
      field: "modelYear",
      headerName: "Model Year",
      width: 120
    },
    {
      field: "majorCategory",
      headerName: "Major Category",
      type: "string",
      width: 150,
    },
    // {
    //   field: "fleetType",
    //   headerName: "Fleet Type",
    //   width: 100,
    // },
    {
      field: "mediaLink",
      headerName: "Media",
      width: 100,
      renderCell: (params) => (
        <a
          href={`https://${params.value}`}
          rel="noreferrer"
          target="_blank"
          className={classes.link}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "comparableRetailPrice",
      headerName: "Retail Price",
      type: "string",
      width: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View Equipment">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleEquipmentView(event, params.row);
                }}
              >
                <ViewWeekIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Equipment">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleEquipmentEdit(event, params.row);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Equipment">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  window.confirm(
                    "Are you sure want to delete " +
                    params.row.equipmentName +
                    " ?"
                  ) && handleEquipmentDelete(event, params.row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const ImportExport = (event) => {
    const regex = /^.*\.(xlsx|xls)$/;
    if (regex.test(file.name.toLowerCase()) && companyList) {
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            const payload = GetUploadPayload(reader.result, companyList, userData, userList, loggedInUserData);
            if (payload?.length > 0) {
              const invalidCreatedBy = payload.some(item => !item.createdBy);
              if (invalidCreatedBy) {
                if (loggedInUserData.user.roleSystem === "regular") {
                    alert("Invalid 'createdBy' in the uploaded Excel file.");
                } 
                return;
                
            } else {
              props.submitEquipmentRegisterForm(payload, "upload", loggedInUserData);
            }
            } else {
              return 0;
            }
          };
          reader.readAsBinaryString(file);
        }
      } else {
        console.log("This browser does not support HTML5.");
      }
    } else {
      console.log("Please upload a valid Excel file.");
    }
    const timer = setTimeout(() => {
      ref.current.form.reset();
      setDisabled(true);
    }, 2000);
    return () => clearTimeout(timer);
  };


  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              EQUIPMENT
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item lg={6} xs={6}>
                <div className={classes.search}>
                  {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                {/* <div className={classes.childDiv}>
                  <GridToolbarContainer>
                    <GridToolbarExport />
                  </GridToolbarContainer>
                </div> */}
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.childDiv}>
                  <Tooltip title="Create New Equipment">
                    <Link to="/equipment/create">
                      <IconButton sx={{ color: "#3E5272" }}>
                        <AddBoxIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  {/* <Tooltip title="Delete multiple equipments">
                    <Link>
                      <IconButton sx={{ color: "#3E5272" }}>
                        <DeleteIcon onClick={handleMultidelete} />
                      </IconButton>
                    </Link>
                  </Tooltip> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  // const onFilterChange = (event) => {
  //   // Here you save the data you need from the filter model and perform server-side search/filtering
  //   // Once the server-side search is completed, you can update the searchInput state with the new value

  //   // Example: Simulating server-side search delay with setTimeout
  //   setSearch(event.target.value);
  //   // if (search.length > 4 || search.trim() == '') {
  //   if (event?.target?.value?.length >= 3)
  //     setTimeout(() => {
  //       setPageState((old) => ({ ...old, searchData: event.target.value }));
  //     }, 2000);
  //   if (event?.target?.value?.length < 1)
  //     setPageState((old) => ({ ...old, searchData: '' }));
  //   // }
  // };

  const onFilterChange = (event) => {
    setSearch(event.target.value);
  };
  
  // console.log(pageState.search);
  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setCompanyValue(selectedCompany);

    // Filter equipment based on selected company and industry and category
    filterEquipment(selectedCompany, industryValue, categoryValue);
  };

  const handleIndustryChange = (event) => {
    const selectedIndustry = event.target.value;
    setIndustryValue(selectedIndustry);

    // Filter equipment based on selected company and industry and category
    filterEquipment(companyValue, selectedIndustry,  categoryValue);
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategoryValue(selectedCategory);

    // Filter equipment based on selected company and industry and category
    filterEquipment(companyValue, industryValue, selectedCategory);
  };

  const filterEquipment = (selectedCompany, selectedIndustry, selectedCategory) => {
    let filteredList = equipmentList;

    if (selectedCompany) {
      filteredList = filteredList.filter((item) => item.companyId === selectedCompany);
    }

    if (selectedIndustry) {
      filteredList = filteredList.filter((item) => item.industry === selectedIndustry);
    }

    if (selectedCategory) {
      filteredList = filteredList.filter((item) => item.majorCategory === selectedCategory);
    }

    setFilteredEquipmentList(filteredList);

    // setPageState((old) => ({ ...old, total: filteredList.length }));
  };

  const transformedData = industyCategories.reduce((acc, category) => {
    const categoryOptions = category.majorCategory.map((subcategory) => ({
      value: subcategory,
      label: subcategory,
    }));

    return [...acc, ...categoryOptions];
  }, []);


  return (
    <div >
      <div className={classes.tableheader}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3 className={classes.header}>
            <form>
              Upload Equipment Excel File (.xlsx) :
              <input type="file" onChange={handleChange} ref={ref} className={classes.textInput} />
              <Button component="label" variant="contained" endIcon={<UploadFileOutlinedIcon />} onClick={ImportExport} disabled={disabled}>
                Upload file
              </Button>
            </form>
          </h3>
          <a
            href={Sample_equipment_file}
            download="Sample_equipment_file"
            style={{ textDecoration: "none", marginRight: "-5rem", width: "21%" }}
          >
            <Button variant="outlined" size="small" sx={{ color: "#3E5272", width: "100%" }}>
              Download Sample Excel file
            </Button>
          </a>
        </Box>
        <SearchBar
          searchText={search}
          filterChange={onFilterChange}
          onClickFunc={handleClearText}
        />
      </div>
      <div className={classes.dropDiv}>
        <div style={{marginLeft: "-1rem"}}>
          <Box component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch', marginLeft: "-4rem" },
            }}
          >
            <TextField
              id="filled-select-currency"
              select
              label="Select Company"
              variant="outlined"
              onChange={handleCompanyChange}
              size="small"

            >
              {companyList.map((option) => (
                <MenuItem key={option.value} value={option.id}>
                  {option.companyName}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </div>
        <div className={classes.dropDiv2}>
          <Box component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch', marginLeft: "-4rem" },
            }}
          >
            <TextField
              id="filled-select-currency"
              select
              label="Select Industry"
              variant="outlined"
              onChange={handleIndustryChange}
              size="small"

            >
              {industryList.map((option) => (
                <MenuItem value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </div>
        {/* <div>
      {industyCategories.map((category, index) => (
        <div key={index}>
          <ul>
            {category.majorCategory.map((subcategory, subIndex) => (
              <li key={subIndex}>{subcategory}</li>
            ))}
          </ul>
        </div>
      ))}
    </div> */}
        <div className={classes.dropDiv2}>
          <Box component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch', marginLeft: "-4rem" },
            }}
          >
            <TextField
              id="filled-select-currency"
              select
              label="Select Category"
              variant="outlined"
              onChange={handleCategoryChange}
              size="small"

            >
              {transformedData.map((option) => (
                <MenuItem value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </div>
      </div>
      <Card className={classes.cardStyle}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={filteredEquipmentList || []}
            columns={columns}
            rowCount={rowCountState}
            paginationMode="server"
            rowsPerPageOptions={[100]}
            pagination
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            checkboxSelection
            disableSelectionOnClick
            sortingMode="server"
            onSortModelChange={(col) => {
              if (col.length > 0) {
                if (col[0].sort === "asc") {
                  setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
                } else {
                  setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
                }
              } else {
                setPageState((old) => ({ ...old, sort: null }));
              }
            }}
            sx={{
              "& .css-ptiqhd-MuiSvgIcon-root": {
                color: "#ee9907",
              },
              // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              //   display: 'none'
              // }
            }}
            // density='standard'
            // components={{ Toolbar: DataGridTitle }}
            components={{
              Toolbar: DataGridTitle,
              Pagination: GridPagination,
            }}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => ({
  restClient: state.restClient,
  companyList: state.company && state?.company?.companyList?.companyList,
  equipmentList: state.equipment && state?.equipment?.equipmentList?.equipmentList,
  loggedInUserData: state.user && state.user.loggedInUserData,
  totalCount: state.equipment && state?.equipment?.equipmentList?.total,
  userData: state.user.userData,
  userList: state.user && state.user.userList.userList
});
export default connect(mapStateToProps, {
  submitEquipmentRegisterForm,
  getEquipmentList,
  deleteEquipment,
  getCompanyList,
  setLoggedInUserData,
  getUserList
})(EquipmentList);
