import React, { useEffect, useState } from "react";
import Styles from "./styles";
import { Form, Field } from "react-final-form";
import Card from "./card";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./utils";
import { connect } from 'react-redux';
import { submitPaymentForm } from '../../reducers/TransactionReducer';
import { Button } from '@mui/material';
import { setLoggedInUserData } from "../../reducers/UserReducer";
import { submitPaymentMessage } from "../../reducers/DealCommunicationReducer";
import { enqueueSnackbar } from '../../reducers/NotifierReducer';
import { editDeal } from "../../reducers/DealReducer";
import { useDispatch } from 'react-redux';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


const CardPayment = (props) => {
  const { loggedInUserData, dealData } = props;
  const transactionId = "6231b09f5292800b4074918e";
  const dealId = props.dealData.dealID;
  // const dealID = props.dealData.dealID;
  const dispatch = useDispatch();

  // const userId = props.user && props.user.user && props.user.user._id;
  // console.log("dealSta", dealId);
  const [isValidEmail, setValidEmail] = useState(true);

  const validateEmail = (value) => {
    // console.log('Validating email:', value);

    if (!value) {
      // console.log('Email is required');
      return 'Email is required';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(value);
    // console.log('Invalid email address');
    setValidEmail(isValid);

  };

  useEffect(() => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window["Stripe"].setPublishableKey(process.env.REACT_APP_STRIPE_KEY);
      };
      window.document.body.appendChild(s);
    }
  }, []);

  const onSubmit = async (values) => {

    function updateUnreadMessages(masterObj, filterObj) {
      if (masterObj.userId === filterObj.participant._id) {
        masterObj.totalUnReadMessages += 1;
        masterObj.readMessage = false;
      }
    }
    // Iterate through masterData and filterData

    if (dealData?.UnreadMessages) {
      // Iterate through UnreadMessages and users
      dealData?.UnreadMessages.forEach((masterObj) => {
        props?.users.forEach((filterObj) => {
          updateUnreadMessages(masterObj, filterObj);
        });
      });
    }

    const transactioncommunication = {
      transactioncommunication: {
        dealId: dealId,
        transactionId: transactionId,
        message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} made payment of $${values.amount} successfully`,
        transactionEquipment: dealData.dealEquipment,
        from: loggedInUserData.user._id,
        to: dealData.dealParticipants.map((p) => { return p.participant._id }),
        notificationType: "Payment",
        notes: "notes",
        askPrice: "$435",
        offerPrice: `$${values.amount}`,
        priceUnit: "USD",
        addedBy: loggedInUserData.user._id,
      },
      dealname: dealData.dealName,
      dealparticipantemails: dealData.dealParticipants.map((p) => { return p.participant.email }),
    }
    // const participantemail = {
    //   dealname: dealData.dealName,
    //   dealparticipantemails: dealData.dealParticipants.map((p) => { return p.participant.email })
    // }
    // const transaction = { isACHPayment: false, token: response, email: values.email, amount: values.amount }
    const dealdata = {
      dealClosingPayment: {
        paymentMethod: "Card",
        paymentDate: new Date(),
        paymentStatus: "Completed"
      },
      UnreadMessages: dealData.UnreadMessages
    }
    await sleep(300);
    try {
      window.Stripe.card.createToken(
        {
          number: values.number,
          exp_month: values.expiry.split("/")[0],
          exp_year: values.expiry.split("/")[1],
          cvc: values.cvc,
          name: values.name,
        },
        (status, response) => {
          if (status === 200) {
            const transaction = { isACHPayment: false, token: response, email: values.email, amount: values.amount }
            const payload = { transaction, dealdata, dealId }
            if (isValidEmail) {
              props.submitPaymentForm(payload);
              props.submitPaymentMessage(transactioncommunication);
              props.setOpen(false);
            }
            // props.editDeal(dealID, {
            //   dealClosingPayment: {
            //     paymentMethod: "Card",
            //     paymentDate: new Date(),
            //     paymentStatus: "Completed"
            //   }
            // });
          } else {
            dispatch(enqueueSnackbar({
              message: `${response.error.message}`,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                }
              },
            }));
          }
        }
      );
    } catch (error) { }
  };

  return (
    <Styles>
      <Form
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          active,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Card
                number={values.number || ""}
                name={values.name || ""}
                expiry={values.expiry || ""}
                cvc={values.cvc || ""}
                focused={active}
              />
              <div>
                <Field
                  required
                  name="amount"
                  component="input"
                  type="number"
                  placeholder="Amount"
                  min={1}
                />
              </div>
              <div>
                <Field
                  required
                  name="email"
                  component="input"
                  type="text"
                  placeholder="Your email"
                  // pattern="^[0-9a-zA-Z.-]+@[a-zA-Z.-]"
                  validate={validateEmail}
                />
              </div>
              {!isValidEmail && <div style={{ color: 'red', paddingLeft: "14px" }}>Invalid email address</div>}

              <div>
                <Field
                  required
                  name="number"
                  component="input"
                  type="text"
                  pattern="[\d| ]{16,22}"
                  placeholder="Card Number"
                  format={formatCreditCardNumber}
                />
              </div>
              <div>
                <Field
                  required
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Name"
                />
              </div>
              <div>
                <Field
                  required
                  name="expiry"
                  component="input"
                  type="text"
                  pattern="\d\d/\d\d"
                  placeholder="Valid Thru"
                  format={formatExpirationDate}
                />
                <Field
                  required
                  name="cvc"
                  component="input"
                  type="text"
                  pattern="\d{3,4}"
                  placeholder="CVC"
                  format={formatCVC}
                />
              </div>
              <div className="buttons">
                <div>
                  <Button
                    type="button"
                    onClick={form.reset}
                    disabled={submitting || pristine}
                    className="reset"
                  >
                    Reset
                  </Button>
                </div>
                <div>
                  <Button variant="contained" data-test-id="Create-Company-Submit" type="submit" className="submit" disabled={submitting}>
                    Submit
                  </Button>
                </div>
                {/* <div>
                  <Link to={'/deal/grid?dealId=' + dealId}>
                    <Button
                      type="button"
                      className="cancel"
                    >
                      Cancel
                    </Button>
                  </Link>
                </div> */}
              </div>
            </form>
          );
        }}
      />
    </Styles >
  );
}
const mapStateToProps = state => ({
  restClient: state.restClient,
  dealData: state.deal && state.deal.dealData,
  loggedInUserData: state.user && state.user.loggedInUserData,
});

export default connect(mapStateToProps, { submitPaymentForm, setLoggedInUserData, submitPaymentMessage, editDeal })(CardPayment);
