import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserAuthenticated } from '../reducers/UserReducer';

const InactivityDetector = ({ timeout, children }) => {
    const dispatch = useDispatch();
    let inactivityTimer;
    let logoutTimeout;

    const resetTimer = () => {
        clearTimeout(inactivityTimer);
        inactivityTimer = setTimeout(() => {
            // dispatch(setUserAuthenticated(false));
            startLogoutTimer(); // Start the logout timer after inactivity timeout
        }, timeout);
    };

    const handleUserActivity = () => {
        resetTimer();
    };

    const startLogoutTimer = () => {
        clearTimeout(logoutTimeout);
        logoutTimeout = setTimeout(() => {
            // Clear the localStorage or perform other logout actions
            // localStorage.auth = "";
            dispatch(setUserAuthenticated(false));
        }, timeout);
    };

    const handleTabClose = () => {
        startLogoutTimer();
    };

    useEffect(() => {
        resetTimer();
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('beforeunload', handleTabClose);
        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, []);

    return (
        <div>
            {children}
        </div>
    );
};

export default InactivityDetector;