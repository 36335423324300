export const initialFormValues = {
  parentCompany: '',
  roleType: '',
  firstName: '',
  lastName: '',
  alias: '',
  countryCode: '',
  webURL: '',
  mobileNumber: '',
  smsNumber: '',
  email: '',
  companyWebsite: '',
  companyTitle: '',
  phone: '',
  roleSystem: 'regular',
  dealCreation: true,
  makeOffer: true,
  acceptOffer: true,
  rejectOffer:true
};

export const adminSelectableRoles = [
  { name: 'Customer Success Lead' },
  { name: 'Cusomer success manager' },
  { name: 'Executive' },
  { name: 'HR lead' },
  { name: 'HR Manager' },
  { name: 'Implementation Lead' },
  { name: 'Implementation Manager' },
  { name: 'Leadership' },
  { name: 'Marketing Lead' },
  { name: 'Marketing Manager' },
  { name: 'Sales Lead' },
  { name: 'Sales Manager' },
  { name: 'Service Provider' },
  { name: 'Trusted Agent' },
  { name: 'Trader' },
  { name: 'User' }
]


export const schema = {
  roleType: {
    presence: { allowEmpty: false, message: 'User type is required' }
  },
  firstName: {
    presence: { allowEmpty: false, message: 'First name is required' }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'Last name is required' }
  },
  countryCode: {
    presence: { allowEmpty: false, message: 'Country is required' }
  },
  mobileNumber: {
    presence: { allowEmpty: false, message: 'Mobile number is required' }
  },
  smsNumber: {
    presence: { allowEmpty: false, message: 'SMS number is required' }
  },
  email: {
    presence: { allowEmpty: false, message: 'Email Address is required' }
  },
  // roleSystem: {
  //   presence: { allowEmpty: false, message: 'Role System is required' }
  // },
  webURL: {
    IsValidCompanyWebsite: {},
  }
};

export const getPayload = (values) => {
  const payload = {
    parentCompany: values.parentCompany,
    "atgRole": {
      roleType: values.roleType,
    },
    "contact": {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      alias: values.alias,
      emailAddress: values.emailAddress,
      webURL: values.webURL,
      countryCode: values.countryCode,
      mobileNumber: values.mobileNumber,
      smsNumber: values.smsNumber
    },
    phone: values.smsNumber,
    roleCompany: values.roleCompany,
    roleSystem: values.roleSystem,
    dealCreation: values.dealCreation,
    acceptOffer: values.acceptOffer,
    makeOffer: values.makeOffer,
    rejectOffer: values.rejectOffer
  }
  return payload;
}
