import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  FormControl,
  Link as MaterialLink,
  TextField,
  FormLabel,
  Typography,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import OtpInput from "react-otp-input";
import { submitLoginForm, setAskLoginOtp } from "../../reducers/UserReducer";
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from "../../styles/appStyles";
import { FormControlWrapper, FormButtonWrapper } from "../../styles/formStyles";

const LoginComponent = (props) => {
  const { setAskLoginOtp } = props;
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [otp, setOTP] = React.useState("");
  // Show Passwords ----
  const [checked, setChecked] = React.useState(false);
  const [type, setType] = React.useState("password");

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleOTPChange = (value) => {
    setOTP(value);
  };
  useEffect(() => {
    setAskLoginOtp(false);
  }, [setAskLoginOtp]);
  const submitForm = (event, resend) => {
    event.preventDefault();
    props.submitLoginForm(userName, password, otp, resend);
  };

  //   ------  SHOW PASSWORD
  const showPassword = (event) => {
    //console.log(event.target);
    setChecked(!checked);
    if (event.target.checked) {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <AppContent>
      <AppContentInner>
        <AppWrapper>
          <AppMain>
            <Container maxWidth="sm">
              <Grid container spacing={5}>
                <Grid item xs={12} lg={12}>
                  <Card>
                    <Header>
                      <HeaderText>
                        <h5 style={{marginTop: "-1px", marginBottom: "-18px"}}>Welcome to AskTakeGo</h5>
                      </HeaderText>
                    </Header>
                    <CardContent>
                      <form onSubmit={submitForm}>
                        {!props.askOTP && (
                          <>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <FormLabel className="form-label" required>
                                  User Email Address
                                </FormLabel>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  id="email"
                                  name="email"
                                  placeholder="Enter Email Id"
                                  value={userName}
                                  onChange={handleUserNameChange}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl>
                                <FormLabel className="form-label" required>
                                  Password
                                </FormLabel>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  id="password"
                                  name="password"
                                  placeholder="Password"
                                  type={type}
                                  value={password}
                                  onChange={handlePasswordChange}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            {/* FOr Show Password */}
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checked}
                                    onClick={showPassword}
                                  />
                                }
                                label="Show Password"
                              />
                            </FormGroup>
                            <FormButtonWrapper>
                              <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                              >
                                Login
                              </Button>
                            </FormButtonWrapper>
                            <div
                              style={{
                                marginTop: "0.5rem",
                                marginBottom: "0.5rem",
                              }}
                            >
                              <Typography align="center">
                                {" "}
                                <Link
                                  to="/forgetpassword"
                                  style={{
                                    color: "#FFBF00",
                                    textDecoration: "none",
                                  }}
                                >
                                  Forgot Password?
                                </Link>{" "}
                              </Typography>
                            </div>
                            {/* <FormButtonWrapper>
                              <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                              >
                                Login
                              </Button>
                            </FormButtonWrapper> */}
                            <div style={{ marginTop: "0.5rem" }}>
                              <Typography align="center">
                                {" "}
                                <Link
                                  to="/register"
                                  style={{
                                    color: "#FFBF00",
                                    textDecoration: "none",
                                  }}
                                >
                                  Don't have an ATG account? SignUp
                                </Link>{" "}
                              </Typography>
                            </div>
                          </>
                        )}
                        {props.askOTP && (
                          <>
                            <p>
                              For security purposes, a Security code has been
                              sent to your email address. Please input the code
                              below. If you do not receive your code in the next
                              15 minutes, check your spam folder or you may
                              request a new code
                              <MaterialLink
                                component="button"
                                variant="body2"
                                onClick={(e) => submitForm(e, true)}
                              >
                                &nbsp; here
                              </MaterialLink>
                              .
                            </p>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <OtpInput
                                  value={otp}
                                  onChange={(otp) => handleOTPChange(otp)}
                                  numInputs={6}
                                  isInputNum={true}
                                  separator={<span>&nbsp;</span>}
                                  data-test-id="Login-OTP"
                                  inputStyle={{
                                    width: "3rem",
                                    height: "3rem",
                                    margin: "0 1rem",
                                    fontSize: "2rem",
                                    borderRadius: 6,
                                    border: "1px solid rgba(0,0,0,0.3)",
                                  }}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormButtonWrapper className="text-center">
                              <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                size="large"
                                data-test-id="Login-Submit"
                                className="my-2"
                              >
                                Complete Login
                              </Button>
                            </FormButtonWrapper>
                          </>
                        )}
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </AppMain>
        </AppWrapper>
      </AppContentInner>
    </AppContent>
  );
};

const mapStateToProps = ({ user }) => ({
  askOTP: user.askOTP,
});
export default connect(mapStateToProps, { submitLoginForm, setAskLoginOtp })(
  LoginComponent
);
