import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import {
  IconButton,
  Grid,
  Button,
  FormControl,
  TextField,
  Card,
  Typography,
  useTheme,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  FormLabel,
  FormGroup,
  FormControlLabel,
  ListItemAvatar,
  Tooltip,
  Box,
  Tab,
  Tabs,
  InputAdornment,
  Chip,
} from '@mui/material';
import { makeStyles } from '@mui/styles'
import Avatar from '@mui/material/Avatar';
import SendIcon from '@mui/icons-material/Send';
import { getDealMessageList, submitDealMessage, submitAcceptMessage, submitRejectMessage } from '../../reducers/DealCommunicationReducer';
import useQuery from '../../utils/useQuery';
import TabsContainer from "./Card";
import { editDeal } from "../../reducers/DealReducer";
import { Stack, styled } from '@mui/system';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Slider from 'react-slick';  // For horizontal sliding, install react-slick and slick-carousel
import { BlobServiceClient } from "@azure/storage-blob";
import uploadImage from "./blobStorage";
import ImageDisplay from "./image";
import deleteImage from "./deleteBlob";
import { editTransactionBlobs } from "../../reducers/DealCommunicationReducer";
import { FileCard, FullScreen, ImagePreview, VideoPreview } from "@files-ui/react";


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  messageRow: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: "0 16px 4px 16px",
    flex: "0 0 auto",
    marginRight: "30%",
    "&.contact": {
      "& $bubble": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        marginLeft: 28,
        "& $time": {
          marginLeft: 12
        }
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopLeftRadius: 20
        }
      },
      "&.last-of-group": {
        "& $bubble": {
          borderBottomLeftRadius: 20
        }
      }
    },
    "&.me": {
      paddingLeft: 40,

      "& $avatar": {
        order: 2,
        margin: "0 0 0 16px"
      },

      "& $bubble": {
        marginLeft: "auto",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        "& $time": {
          justifyContent: "flex-end",
          right: 0,
          marginRight: 12
        }
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopRightRadius: 20
        }
      },

      "&.last-of-group": {
        "& $bubble": {
          borderBottomRightRadius: 20
        }
      }
    },
    "&.contact + .me, &.me + .contact": {
      paddingTop: 20,
      marginTop: 20
    },
    "&.first-of-group": {
      "& $bubble": {
        borderTopLeftRadius: 20,
        paddingTop: 13
      }
    },
    "&.last-of-group": {
      "& $bubble": {
        borderBottomLeftRadius: 20,
        paddingBottom: 13,
        "& $time": {
          display: "flex"
        }
      }
    }
  },
  messageRowRight: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: "0 16px 4px 16px",
    flex: "0 0 auto",
    "&.contact": {
      "& $bubble": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        marginLeft: 28,
        "& $time": {
          marginLeft: 12
        }
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopLeftRadius: 20
        }
      },
      "&.last-of-group": {
        "& $bubble": {
          borderBottomLeftRadius: 20
        }
      }
    },
    "&.me": {
      paddingLeft: 40,

      "& $avatar": {
        order: 2,
        margin: "0 0 0 16px"
      },

      "& $bubble": {
        marginLeft: "auto",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        "& $time": {
          justifyContent: "flex-end",
          right: 0,
          marginRight: 12
        }
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopRightRadius: 20
        }
      },

      "&.last-of-group": {
        "& $bubble": {
          borderBottomRightRadius: 20
        }
      }
    },
    "&.contact + .me, &.me + .contact": {
      paddingTop: 20,
      marginTop: 20
    },
    "&.first-of-group": {
      "& $bubble": {
        borderTopLeftRadius: 20,
        paddingTop: 13
      }
    },
    "&.last-of-group": {
      "& $bubble": {
        borderBottomLeftRadius: 20,
        paddingBottom: 13,
        "& $time": {
          display: "flex"
        }
      }
    }
  },
  avatar: {
    position: "absolute",
    left: 0,
    margin: 0
  },
  bubble: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    maxWidth: "100%",
    wordWrap: "break-word",
    boxShadow: theme.shadows[1]
  },
  message: {
    whiteSpace: "pre-wrap",
    lineHeight: 1.2
  },
  time: {
    position: "absolute",
    display: "none",
    width: "100%",
    fontSize: 11,
    marginTop: 8,
    top: "100%",
    left: 0,
    whiteSpace: "nowrap"
  },
  inputWrapper: {
    borderRadius: 24
  },
  rightMessage: {
    width: "fit-content",
    alignSelf: "end"
  },
  ButtonGroup: {
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  AcceptButton: {
    borderRadius: "30px",
    color: "white",
    backgroundColor: "rgb(62, 82, 114)",
    '&:hover': {
      backgroundColor: "#fbbc05",
      color: "black"
    }
  },
  RejectButton: {
    borderRadius: "30px",
    color: "#fff",
    backgroundColor: "#9c27b0",
    '&:hover': {
      backgroundColor: "#6C3483"
    }
  },
  accept: {
    textAlign: "start",
  },
  reject: {
    textAlign: "end",
  },
  dialogButtons: {
    display: "flex"
  },
  achPayment: {
    paddingRight: "30px",
  },
  achlink: {
    textDecoration: "none",
  },
  cardlink: {
    textDecoration: "none"
  },
  achButton: {
    backgroundColor: "rgb(62, 82, 114)",
    color: "white",
    borderRadius: "30px",
    '&:hover': {
      backgroundColor: "#fbbc05",
      color: "black"
    }
  },
  cardButton: {
    backgroundColor: "#A569BD",
    borderRadius: "30px",
    '&:hover': {
      backgroundColor: "#884EA0"
    }
  },
  cancelButton: {
    backgroundColor: "#f50057",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#f50057",
      color: "#fff"
    }
  },
  makePayment: {
    textAlign: "center",
  },
}));

const FileCardsDisplay = ({ files, dealId, editTransactionBlobs, allFiles, transactionId }) => {
  const fileName = files?.blobPath; //array of strings
  const fileGroups = files?.blobPath?.length > 0 && fileName.reduce((acc, file) => {
    const extension = file.split('.').pop();
    if (!acc[extension]) {
      acc[extension] = [];
    }
    acc[extension].push(file);
    return acc;
  }, {});
  const baseUrl = process.env.REACT_APP_BLOB_URL;

  const parsedFiles = Object.keys(fileGroups).reduce((acc, key) => {
    acc[key] = fileGroups[key].map(url => `${baseUrl}deal/${dealId}/` + url);
    return acc;
  }, {});
  const [imgSrc, setImgSrc] = React.useState(undefined);
  const [videoSrc, setVideoSrc] = React.useState(undefined);

  const handleDelete = (fileType, fileName) => {
    console.log(`Delete ${fileName} from ${fileType}`);
    // Implement the delete functionality here
    const decision = window.confirm("Are u sure you need to delete the file permanently?");
    const res = decision && deleteImage('deal', `${dealId}/${fileName}`);
    let transactioncommunication = {};
    transactioncommunication.blobPath = allFiles.filter((file) => file === fileName);
    editTransactionBlobs(transactionId, transactioncommunication);
  };

  const handleSee = (imageSource) => {
    setImgSrc(imageSource);
  };

  const handleWatch = (videoSource) => {
    setVideoSrc(videoSource);
  };

  const handleDownload = (fileId, downloadUrl) => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileId; // or any other filename you wish to use
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {Object.entries(parsedFiles).map(([fileType, fileArray]) =>
        fileArray.map((fileUrl) => {
          const fileName = fileUrl.split('/').pop();
          return (
            <FileCard
              key={fileName}
              id={fileName}
              name={fileName}
              onSee={() => handleSee(fileUrl)}
              onWatch={() => handleWatch(fileUrl)}
              onDownload={() => handleDownload(fileName, fileUrl)}
            // Add other props as needed
            />
          );
        })
      )}
      <FullScreen open={imgSrc !== undefined} onClose={() => setImgSrc(undefined)}>
        <ImagePreview src={imgSrc} />
      </FullScreen>
      <FullScreen open={videoSrc !== undefined} onClose={() => setVideoSrc(undefined)}>
        <VideoPreview src={videoSrc} autoPlay controls />
      </FullScreen>
    </>
  );
};

const BorderRight500 = styled('div')(({ theme }) => ({
  borderRight: '1px solid #e0e0e0',
}));

const UserList = ({ loggedInUserData, sortedUserList, setSelectedUsers }) => {
  const [value, setValue] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleCheckboxChange = (event, user) => {
    if (event.target.checked) {
      setSelectedUsers([user]);
    }
  };

  const tabLabels = sortedUserList.map(user => `${user.participant.contact.firstName[0]} ${user.participant.contact.lastName[0]}`);
  return (
    <Grid item xs={3} as={BorderRight500}>
      <List>
        <ListItem>
          <ListItemIcon>
            <Avatar alt={loggedInUserData.user?.contact?.firstName[0] + " " + loggedInUserData.user?.contact?.lastName[0]} src="https://material-ui.com/static/images/avatar/1.jpg" />
          </ListItemIcon>
          <ListItemText primary={loggedInUserData.user?.contact?.firstName[0] + " " + loggedInUserData.user?.contact?.lastName[0]} />
          <ListItemAvatar>
            <Tooltip title="Add Group Chat">
              <IconButton>
                <GroupAddOutlinedIcon />
              </IconButton>
            </Tooltip>
          </ListItemAvatar>
        </ListItem>
      </List>
      <Divider />
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        {/* {tabLabels.map((label, index) => (
            <Tab key={index} label={label} {...a11yProps(index)} />
          ))}
        </Tabs> */}
        {(sortedUserList || []).map((user, index) => (
          <Tab
            key={user.participant._id}
            label={`${user.participant.contact.firstName[0]} ${user.participant.contact.lastName[0]}`}
            id={`vertical-tab-${index}`}
            aria-controls={`vertical-tabpanel-${index}`}
            onClick={(event) => handleCheckboxChange(event, user)}
            checked={sortedUserList?.some((u) => u.participant._id == user.participant._id)}
            fullWidth
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              width: '100%',
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              marginTop: 0.2
            }}
          />
        ))}
      </Tabs>
      {/* {(sortedUserList || []).map((user, index) => (
          <TabPanel key={user.participant._id} value={value} index={index}>
            {`${user.participant.contact.firstName} ${user.participant.contact.lastName}`}
          </TabPanel>
        ))} */}
    </Grid >
  );
};
function getBlobUrls(uploadResults) {
  return uploadResults.map(result => result.fileUrl);
}

// async function uploadMultipleFiles(containerName, subcontainer, files) {
//   const uploadPromises = files.map(file => uploadImage(containerName, subcontainer, file));
//   const results = await Promise.all(uploadPromises);
//   const urls = getBlobUrls(results);
//   const newMessages = urls.map(url => ({ text: 'New file uploaded', fileUrl: url }));
//   console.log('All files uploaded successfully', results);
// }
async function uploadMultipleFiles(containerName, subcontainer, files) {
  const uploadPromises = files.map(file => uploadImage(containerName, subcontainer, file));
  const results = await Promise.all(uploadPromises);
  const urls = getBlobUrls(results);
  return urls;
}
const DealMessageChat = (props) => {
  const { dealCommunicationList = [], restClient: { rest }, getDealMessageList, loggedInUserData, dealData, editTransactionBlobs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [unreadMessageList, setUnreadMessageList] = useState([]);
  const [filteredUserList, setFilterUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  // const [sortedUserList, setSortedUserList] = useState([]);
  const query = useQuery();
  const dealID = query.get('dealId');
  const [open, setOpen] = React.useState(false);
  const messagesEndRef = useRef(null);
  const [files, setFiles] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  // useEffect(() => {
  //   setSortedUserList(props?.users);
  //   setSelectedUsers(props?.users)
  // }, [])

  const userId = props.user && props.user.user && props.user.user._id;

  // const DealMakeOffer = dealCommunicationList && dealCommunicationList.find((item) => item.notificationType === "Make Offer");

  const DealAcceptOffer = Array.isArray(dealCommunicationList) && dealCommunicationList.find((item) => item.notificationType === "Accept Offer");

  const DealRejectOffer = Array.isArray(dealCommunicationList) && dealCommunicationList.find((item) => item.notificationType === "Reject Offer");

  // const DealPayment = dealCommunicationList?.find && dealCommunicationList.find((item) => item.notificationType === "Payment");

  const ENTER_KEY_CODE = 13;

  const [msg, setMsg] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [intialized, setIntialized] = useState(true);

  // const [file, setFile] = useState(null);
  // const [progress, setProgress] = useState(0);
  // const [isUploading, setIsUploading] = useState(false);
  // const [isUploadComplete, setIsUploadComplete] = useState(false);

  // const handleFileChange = (event) => {
  //   setFile(event.target.files[0]);
  //   setProgress(0);
  //   setIsUploading(false);
  //   setIsUploadComplete(false);
  // };

  // const handleUpload = () => {
  //   if (!file) return;
  //   setIsUploading(true);

  //   // Simulate file upload process
  //   const uploadSimulation = setInterval(() => {
  //     setProgress((prevProgress) => {
  //       if (prevProgress >= 100) {
  //         clearInterval(uploadSimulation);
  //         setIsUploading(false);
  //         setIsUploadComplete(true);
  //         return 100;
  //       }
  //       return prevProgress + 10;
  //     });
  //   }, 1000);
  // };

  const transactionId = "6231b09f5292800b4074918e";

  const handleMessageChange = (event) => {
    if (selectedUsers?.length <= 0 && msg.trim() == "") {
      alert("You must select atleast one user to send message.");
    } else {
      setMsg(event.target.value);
      setBtnDisabled(!event.target.value);;
    }
  }

  const listmsg = filteredUserList.find(item => item.message);

  useEffect(() => {
    if (filteredUserList.length > 0) {
      scrollToBottom();
    }
    setTimeout(() => {
      if (filteredUserList.length > 0) {
        setIntialized(false);
      }
    }, 2000);
  }, [filteredUserList, scrollToBottom]);

  const handleFocusChange = () => {
    console.log("Focus executed")
    const loggedInUserId = loggedInUserData.user._id;
    const messagesCountOfUser = unreadMessageList?.length > 0 ? unreadMessageList?.filter((user) => user.userId === loggedInUserId)[0]?.totalUnReadMessages : props?.dealData?.UnreadMessages.filter((user) => user.userId === loggedInUserId)[0].totalUnReadMessages;
    if (unreadMessageList?.length > 0 && messagesCountOfUser) {
      unreadMessageList.forEach(message => {
        // Check if the userId matches the loggedInUserId
        if (message.userId === loggedInUserId) {
          // Update totalUnReadMessages to zero
          message.totalUnReadMessages = 0;
          message.readMessage = true;
        }
      });
      props.editDeal(dealID, { "UnreadMessages": unreadMessageList }, true);
    }
    else if (Object.keys(props.dealData).length > 0 && unreadMessageList?.length === 0 && messagesCountOfUser > 0) {
      props?.dealData?.UnreadMessages.filter((userData) => {
        if (userData.userId === loggedInUserId) {
          userData.totalUnReadMessages = 0;
          userData.readMessage = true;
        }
        return true;
      });
      props.editDeal(dealID, { "UnreadMessages": props?.dealData?.UnreadMessages }, true);
    }
  }


  // for send message to api
  const sendMessage = async () => {
    if (msg || files.length > 0) {
      let blobPath = [];
      if (files.length > 0) {
        blobPath = files.map((file) => file.name);
        await uploadMultipleFiles("deal", `${dealID}`, files);
        setFiles([]);
      }
      const payload = {
        dealId: dealID,
        transactionId: transactionId,
        message: msg,
        transactionEquipment: props.equipment,
        from: userId,
        to: selectedUsers.map((p) => { return p.participant._id }),
        notificationType: "General Message",
        notes: "notes",
        askPrice: "$435",
        offerPrice: "$410",
        priceUnit: "USD",
        addedBy: userId,
        blobPath: blobPath,
      }
      function updateUnreadMessages(masterObj, filterObj) {
        if (masterObj.userId === filterObj.participant._id) {
          masterObj.totalUnReadMessages += 1;
          masterObj.readMessage = false;
        } else if (masterObj.userId === loggedInUserData.user._id) {
          masterObj.totalUnReadMessages = 0;
          masterObj.readMessage = true;
        }
      }
      const dealId = props?.dealCommunicationList[0]?.dealId;
      // Iterate through masterData and filterData

      if (dealId && dealId?.UnreadMessages && unreadMessageList?.length === 0) {
        // Iterate through UnreadMessages and users
        dealId?.UnreadMessages.forEach((masterObj) => {
          selectedUsers.forEach((filterObj) => {
            updateUnreadMessages(masterObj, filterObj);
          });
        });
        props.editDeal(dealID, { "UnreadMessages": dealId?.UnreadMessages }, true);
        props.submitDealMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
      } else if (unreadMessageList?.length > 0) {
        unreadMessageList.forEach((masterObj) => {
          selectedUsers.forEach((filterObj) => {
            updateUnreadMessages(masterObj, filterObj);
          });
        });
        props.editDeal(dealID, { "UnreadMessages": unreadMessageList }, true);
        props.submitDealMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
      } else {
        props?.dealData?.UnreadMessages.forEach((masterObj) => {
          selectedUsers.forEach((filterObj) => {
            updateUnreadMessages(masterObj, filterObj);
          });
        });
        props.editDeal(dealID, { "UnreadMessages": props?.dealData?.UnreadMessages }, true);
        props.submitDealMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
      }
      // scrollToBottom();
      setMsg("");
      setBtnDisabled(true);
    }
  }

  const acceptMessage = () => {
    const transactioncommunication = {
      dealId: dealID,
      transactionId: transactionId,
      message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} accepted the offer`,
      transactionEquipment: props.equipment,
      from: userId,
      to: selectedUsers.map((p) => { return p.participant._id }),
      notificationType: "Accept Offer",
      notes: "notes",
      askPrice: "$435",
      offerPrice: "$410",
      priceUnit: "USD",
      addedBy: userId
    };
    const participantemail = {
      dealname: dealData?.dealName,
      dealparticipantemails: dealData?.dealParticipants?.filter((un) => un.participant.acceptOffer === true).map((p) => { return p.participant.email })
    }
    function updateUnreadMessages(masterObj, filterObj) {
      if (masterObj.userId === filterObj.participant._id) {
        masterObj.totalUnReadMessages += 1;
        masterObj.readMessage = false;
      }
    }
    const dealId = props?.dealCommunicationList[0]?.dealId;
    // Iterate through masterData and filterData

    if (dealId && dealId?.UnreadMessages) {
      // Iterate through UnreadMessages and users
      dealId?.UnreadMessages.forEach((masterObj) => {
        selectedUsers.forEach((filterObj) => {
          updateUnreadMessages(masterObj, filterObj);
        });
      });
    }
    const payload = { transactioncommunication, participantemail }
    props.submitAcceptMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
  }

  const rejectMessage = () => {
    const transactioncommunication = {
      dealId: dealID,
      transactionId: transactionId,
      message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} rejected an offer`,
      transactionEquipment: props.equipment,
      from: userId,
      to: selectedUsers.map((p) => { return p.participant._id }),
      notificationType: "Reject Offer",
      notes: "notes",
      askPrice: "$435",
      offerPrice: "$410",
      priceUnit: "USD",
      addedBy: userId
    }
    const participantemail = {
      dealname: dealData.dealName,
      dealparticipantemails: dealData.dealParticipants.filter((un) => un.participant.rejectOffer === true).map((p) => { return p.participant.email })
    }
    const payload = { transactioncommunication, participantemail }
    function updateUnreadMessages(masterObj, filterObj) {
      if (masterObj.userId === filterObj.participant._id) {
        masterObj.totalUnReadMessages += 1;
        masterObj.readMessage = false;
      }
    }
    const dealId = props?.dealCommunicationList[0]?.dealId;
    // Iterate through masterData and filterData

    if (dealId && dealId?.UnreadMessages) {
      // Iterate through UnreadMessages and users
      dealId?.UnreadMessages.forEach((masterObj) => {
        selectedUsers.forEach((filterObj) => {
          updateUnreadMessages(masterObj, filterObj);
        });
      });
    }
    props.submitRejectMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
  }

  const handleEnterKeyChange = (event) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      sendMessage();
      // scrollToBottom();
    }
  }
  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileDelete = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const ChatMessages = (filteredUserList.length > 0 ? filteredUserList : []).map((chatMessagesDto, index) =>
    <div>
      {(chatMessagesDto.from?._id !== userId) ?
        <div className={classes.messageRow}>
          <List>
            <ListItem key={index.toString()}>
              <ListItemIcon>
                <Avatar alt="Venu" src="https://material-ui.com/static/images/avatar/1.jpg">
                </Avatar>
              </ListItemIcon>
              <div className={classes.bubble}>
                <div className={classes.message}>
                  <FileCardsDisplay files={chatMessagesDto} dealId={dealID} editTransactionBlobs={props.editTransactionBlobs} />
                  <ListItemText
                    primary={`${chatMessagesDto?.from?.contact?.firstName[0]} ${chatMessagesDto?.from?.contact?.lastName[0]} (${chatMessagesDto.from?._id === dealData.seller ? "Seller" : "Buyer"}) : ${chatMessagesDto?.message}`}
                  />
                </div>
                <div>
                  {(dealData?.dealClosingSale?.find((un) => un.seller === loggedInUserData.user._id) && chatMessagesDto?.notificationType === "Make Offer" && !DealAcceptOffer && !DealRejectOffer) ?
                    <div className={classes.ButtonGroup}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <div className={classes.accept}>
                            <Button variant="contained" onClick={acceptMessage} className={classes.AcceptButton}>Accept Offer</Button>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <div className={classes.reject}>
                            <Button variant="contained" onClick={(event) => { window.confirm("Are you sure want to reject offer?") && rejectMessage() }} className={classes.RejectButton}>Reject Offer</Button>
                          </div>
                        </Grid>
                      </Grid>
                    </div> : null}
                  {(dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id) && chatMessagesDto?.notificationType === "Accept Offer") ?
                    <div className={classes.ButtonGroup}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div className={classes.makePayment}>
                            <Button variant="contained" onClick={handleClickOpen} className={classes.AcceptButton}>Make Payment</Button>
                            <Dialog open={open} onClose={handleClose}>
                              <DialogTitle>Choose Any Payment Method</DialogTitle>
                              <DialogContent>
                                <div className={classes.dialogButtons}>
                                  <TabsContainer users={selectedUsers} setOpen={setOpen} />
                                </div>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose} className={classes.cancelButton}>Cancel</Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </Grid>
                      </Grid>
                    </div> : null}
                </div>
                <div>
                  <Typography variant="caption" style={{ color: '#ff6f00', float: "right" }}>
                    {chatMessagesDto.createdAt}
                  </Typography>
                </div>
              </div>
            </ListItem>
          </List>
        </div>
        :
        <>
          {(chatMessagesDto?.blobPath?.length === 0 && !Boolean(chatMessagesDto?.message)) ? //For no images and no messages
            null :
            <div className={classes.messageRowRight}>
              <List sx={{ alignSelf: "end" }}>
                <ListItem key={index.toString()} className={classes.rightMessage}>
                  <div className={classes.bubble}>
                    <div className={classes.message}>
                      <FileCardsDisplay files={chatMessagesDto} dealId={dealID} editTransactionBlobs={props.editTransactionBlobs} />
                      <ListItemText
                        primary={chatMessagesDto.message}
                        secondary={<Typography variant="caption" style={{ color: '#ff6f00', float: "right" }}>
                          {chatMessagesDto.createdAt}
                        </Typography>}
                      />
                    </div>
                  </div>
                  <ListItemIcon>
                    <Avatar alt="Venu" src="https://tse3.mm.bing.net/th?id=OIP.ZX_-sfAfuG9WjeGBRMkl9gHaHa&pid=Api&P=0">
                    </Avatar>
                  </ListItemIcon>
                </ListItem>
              </List>
            </div>
          }
        </>
      }
    </div>
  );
  useEffect(() => {
    scrollToBottom();
  }, [ChatMessages]);

  useEffect(() => {
    const getMessageList = async () => {
      try {
        if (dealID && rest) {
          await getDealMessageList(dealID);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getMessageList();
  }, [rest, getDealMessageList, dealID]);


  useEffect(() => {
    const handleRealTimeMessages = async (message) => {
      setUnreadMessageList(message?.transactioncommunication?.latestUnreadMessages);
      const filtereredEquipment = message?.transactioncommunication?.transactionEquipment?.filter(te =>
        props.equipment?.some(e => (e._id === te)));
      const fromUserFilter = selectedUsers?.some((u) => u?.participant?._id === message?.transactioncommunication?.from?._id) || message?.transactioncommunication?.from?._id === userId;

      const toUserFilter = message?.transactioncommunication?.to?.filter((p) => {
        return (selectedUsers?.some(u => u?.participant?._id === p._id) ||
          p._id === userId)
      });

      if (filtereredEquipment?.length > 0 && fromUserFilter && toUserFilter.length > 0) {
        await setFilterUserList((prevList) => {
          // Create a copy of the prevList using Array.from
          const newList = Array.from(prevList);
          // Add the new message object's property
          newList.push(message?.transactioncommunication);
          return newList;
        });
      }
    };
    rest?.service('transactioncommunication').on('created', handleRealTimeMessages);
    return () => {
      rest?.service('transactioncommunication').removeListener('created', handleRealTimeMessages);
    };

  }, [rest, selectedUsers, props.equipment, userId, unreadMessageList, filteredUserList]);

  useEffect(() => {
    if (dealCommunicationList.length > 0) {
      const filteredTest = dealCommunicationList.reduce((acc, communication) => {
        const filteredEquipment = communication.transactionEquipment?.filter(te =>
          props.equipment?.some(e => (e._id === te)));
        const fromUserFilter = selectedUsers?.some(u => u.participant._id === communication?.from?._id) || communication?.from?._id === userId;   //

        const toUserFilter = communication.to.filter((p) => {
          return (selectedUsers?.some(u => u.participant._id === p._id) ||
            p._id === userId)
        });

        if (filteredEquipment.length > 0 && fromUserFilter && toUserFilter.length > 0) {
          acc.push(communication);
        }
        return acc;
      }, []);

      setFilterUserList(filteredTest);
    }
  }, [dealCommunicationList, userId, props.equipment, selectedUsers]);
  return (
    <div>
      <Card elevation={1} className="flex flex-col h-512 rounded-8"
      >
        <div
          className="flex flex-shrink-0 items-center justify-between px-24 h-64"
          style={{
            background: "#607d8b",
            color: theme.palette.getContrastText('#607d8b'),
            paddingLeft: "40px",
          }}
        >
          <Typography className="text-center text-16 font-400">Deal Communication</Typography>
        </div>
        <div style={{ height: "506px", flex: 1 }}>
          <Grid container>
            <UserList
              loggedInUserData={loggedInUserData}
              sortedUserList={props?.users}
              setSelectedUsers={setSelectedUsers} />
            <Grid container xs={9}>
              <Grid xs={12} sx={{ height: "430px", flex: 1, overflowY: "auto" }} ref={messagesEndRef}>
                <div style={{ marginBottom: "20px" }}>{filteredUserList ? ChatMessages : ""}</div>
              </Grid>
              {/* <Grid
                xs={12}
                style={{
                  padding: 5,
                  display: "flex",
                  flexDirection: "row",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff", // Ensure background color for better visibility
                  zIndex: 0 // Ensure it stays on top of scrolling content
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Type deal message"
                    fullWidth
                    id="DealMessage"
                    data-test-id="deal-message-id"
                    placeholder="Deal Message"
                    type="text"
                    name="DealMessage"
                    value={msg}
                    onChange={handleMessageChange}
                    onKeyDown={handleEnterKeyChange}
                  // InputProps={{
                  //   onFocus: handleFocusChange,
                  // }}
                  />
                </FormControl>
                <IconButton aria-label="send" color="primary" disabled={btnDisabled} onClick={sendMessage} >
                  <SendIcon />
                </IconButton>
              </Grid> */}
              <Grid
                xs={12}
                style={{
                  padding: 5,
                  display: "flex",
                  flexDirection: "row",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff", // Ensure background color for better visibility
                  zIndex: 0, // Ensure it stays on top of scrolling content
                }}
              >
                {/* <IconButton aria-label="Upload File" color="primary" disabled={!selectedUsers.length}>
                  <TextField
                    id="icon-button-file"
                    type="file"
                    onChange={handleFileChange}
                  // sx={{ display: "none" }}
                  />
                  <UploadFileOutlinedIcon />
                </IconButton> */}
                {/* <input
                  accept="*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="contained" component="span">
                    <UploadFileOutlinedIcon />
                  </Button>
                </label>
                {file && <TextField label="File Name" value={file.name} variant="outlined" fullWidth disabled />}
                {isUploading && <LinearProgress variant="determinate" value={progress} />}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpload}
                  disabled={isUploadComplete || !file}
                >
                  Upload
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  disabled={!isUploadComplete}
                  onClick={() => alert('File sent successfully!')}
                >
                  Send
                </Button> */}
                {/* <FormControl fullWidth>
                  <TextField
                    variant="outlined"
                    label="Type deal message"
                    fullWidth
                    id="DealMessage"
                    data-test-id="deal-message-id"
                    placeholder="Deal Message"
                    type="text"
                    name="DealMessage"
                    value={msg}
                    onChange={handleMessageChange}
                    onKeyDown={handleEnterKeyChange}
                  // InputProps={{
                  //   onFocus: handleFocusChange,
                  // }}
                  />
                </FormControl>
                <IconButton aria-label="send" color="primary" disabled={btnDisabled} onClick={sendMessage} >
                  <SendIcon />
                </IconButton> */}
                {/* <UploadTextField /> */}
                <Box sx={{ width: "100%" }}>
                  <TextField
                    fullWidth
                    multiline
                    sx={{
                      minHeight: "80px"
                    }}
                    variant="outlined"
                    placeholder="Enter your text here..."
                    value={msg}
                    onChange={handleMessageChange}
                    onKeyDown={handleEnterKeyChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <input
                            accept="*"
                            style={{ display: 'none' }}
                            id="file-upload"
                            type="file"
                            multiple
                            onChange={handleFileUpload}
                          />
                          <label htmlFor="file-upload">
                            <IconButton component="span">
                              <AttachFileIcon />
                            </IconButton>
                          </label>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton disabled={btnDisabled && files.length === 0} onClick={sendMessage}>
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box mb={2}>
                    {files.length > 0 && (
                      <Slider {...settings}>
                        {files.map((file, index) => (
                          <Stack>
                            <Chip
                              key={index}
                              label={file.name}
                              onDelete={() => handleFileDelete(file.name)}
                              deleteIcon={<CancelOutlinedIcon />}
                              style={{ marginRight: 8 }}
                            />
                          </Stack>
                        ))}
                      </Slider>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  restClient: state.restClient,
  dealCommunicationList: state.dealCommunication && state.dealCommunication.dealCommunicationList,
  deal: state.deal && state.deal.dealList,
  dealData: state.deal && state.deal.dealData,
  user: state.user.userData,
  loggedInUserData: state.user && state.user.loggedInUserData,
});

export default connect(mapStateToProps, { getDealMessageList, submitDealMessage, submitAcceptMessage, submitRejectMessage, editDeal, editTransactionBlobs })(DealMessageChat);
