import React from 'react';
import './footer.css'

function Footer() {
    return (
        <footer class="atg-footer" id="atg-footer">
            <div class="footer-wrapper">
    
            </div>
            <div class="footer-strip">
                <div class="copyright">                  
                <p>Copyright 2024 by <a href="https://asktakego.com/" rel='noreferrer' target='_blank' class='atg-footer-link'>AskTakeGo.inc</a> All rights reserved.</p>              
                </div>
            </div>
        </footer>
    )
}
export default Footer;
