export const initialFormValues = {
  comparableRetailPrice: '',
  industry: '',
  majorCategory: '',//change name as categoryType
  company: '',
  branchID: '',
  companyEquipmentID: '',//Need to Remove or change it to companyAssetID.
  fleetType: '',
  equipmentName: '',
  equipmentDescription: '',
  make: '',
  model: '',
  modelYear: '',//year   
  meterRead: '',
  serialNum: '',
  quantity: '',
  pricingModel: '',
  reservePrice: '',
  listingPrice: '',
  currentLocationCity: '',
  locationLat: '',
  locationLong: '',
  locationStatus: '',
  needStatus: '',
  needStartDate: '',
  needEndDate: '',
  usageStatus: '',
  availabilityStartDate: '',
  availabilityEndDate: '',
  availabilityStatus: '',
  qtrProjected: '',
  mediaLink: '',
  photoLink: '',//bl0bStorage
  pricingTiers: '', //
  assetDescription: '',
  createdBy: '',

};
export const schema = {
  // companyEquipmentID: {
  //   presence: { allowEmpty: false, message: 'companyEquipmentID is required' },
  // },
  // branchID: {
  //   presence: { allowEmpty: false, message: 'BranchID is required' },
  // },
  // listingPrice: {
  //   presence: { allowEmpty: false, message: 'listingPrice is required' },
  // },
  modelYear: {
    presence: { allowEmpty: false, message: 'Model Year is required' },
  },
  model: {
    presence: { allowEmpty: false, message: 'Model is required' },
  },
  make: {
    presence: { allowEmpty: false, message: 'Make is required' },
  },
  equipmentName: {
    presence: { allowEmpty: false, message: 'Equipment Name is required' },
  },
  equipmentDescription: {
    presence: { allowEmpty: false, message: 'Equipment Description is required' },
  },
  createdBy: {
    presence: { allowEmpty: false, message: 'CreatedBy is required' },
  },
};
export const getPayload = (values, userData, selectParentCompany) => {
  const role = userData.user.roleSystem === 'regular';
  const payload = {
    comparableRetailPrice: values.comparableRetailPrice,
    industry: values.industry,
    majorCategory: values.majorCategory,
    company: role ? userData.user.parentCompany._id : selectParentCompany,
    branchID: values.branchID,
    companyEquipmentID: values.companyEquipmentID,
    fleetType: values.fleetType,
    equipmentName: values.equipmentName,
    equipmentDescription: values.equipmentDescription,
    make: values.make,
    model: values.model,
    modelYear: values.modelYear,
    meterRead: values.meterRead,
    serialNum: values.serialNum,
    quantity: values.quantity,
    pricingModel: values.pricingModel,
    reservePrice: values.reservePrice,
    listingPrice: values.listingPrice,
    usageStatus: values.usageStatus,
    qtrProjected: values.qtrProjected,
    mediaLink: values.mediaLink,
    photoLink: values.photoLink,
    pricingTiers: values.pricingTiers,
    pictures: values.pictures,
    documents: values.documents,
    createdBy: role ? userData.user._id : values.createdBy,
    "assetState": {
      assetDescription: values.assetDescription,
      "locationStateTransition": {
        locationLat: values.locationLat,
        locationLong: values.locationLong,
        currentLocationCity: values.currentLocationCity,
        locationStatus: values.locationStatus
      },
      "needStateTransition": [
        {
          "startDate": values.needStartDate,
          "endDate": values.needEndDate,
          needStatus: values.needStatus,
        }
      ],
      "usageStateTransition": [
        {
          usageStatus: values.usageStatus,
        }
      ],
      "availabilityStateTransition": [
        {
          "startDate": values.availabilityStartDate,
          "endDate": values.availabilityEndDate,
          availabilityStatus: values.availabilityStatus,
        }
      ],
    },
  }
  return payload;
}


export const industyCategories = [
  {
    industry: "Agricultural Equipment And Machinery",
    majorCategory: ["Applicators", 
                     "Bale Processors", 
                     "Bale or Hay Trailors", 
                     "Balers", 
                     "Combines", 
                     "Dairy Farming Equipment", 
                     "Disc Mowers", 
                     "Feeder & Mixer Wagons", 
                     "Fertilizer Spreaders", 
                     "Flail Mowers", 
                     "Forage Harvesters", 
                     "Forage Wagons", 
                     "Fruit & Vegetable Cultivation", 
                     "Grain Augers", 
                     "Grain Carts & Gravity Wagons", 
                     "Grain Handling", 
                     "Harvester Headers", 
                     "Hay Rakes", 
                     "Hay Tedders", 
                     "Irrigation", 
                     "Lawn Mowers", 
                     "Livestock Equipment", 
                     "Mower Conditioners", 
                     "Planting Equipment", 
                     "Potato Harvesters", 
                     "Precision Agriculture", 
                     "Rotary Mowers", 
                     "Swathers", 
                     "Tillage Equipment", 
                     "Tractors"]
  },
  {
    industry: "Construction Equipment & Heavy Machinery",
    majorCategory: ["Aggregate Equipment", 
                    "Asphalt & Concrete Saws", 
                    "Asphalt Crack Sealing Equipment", 
                    "Asphalt Distributors", 
                    "Asphalt Milling Machines", 
                    "Asphalt Mixing Plants", 
                    "Asphalt Pavers", 
                    "Backhoe Loaders", 
                    "Block, Brick & Paver Making Machines", 
                    "Cable Laying & Stringing", 
                    "Compact Track Loaders", 
                    "Compactors", 
                    "Compressors", 
                    "Concrete Batching Plants", 
                    "Concrete Buggies", 
                    "Concrete Pumps", 
                    "Construction Engines", 
                    "Cranes", 
                    "Crawler Loaders", 
                    "Digger Derricks", 
                    "Directional Drilling", 
                    "Dozers", 
                    "Dredges", 
                    "Excavators", 
                    "Generator Sets", 
                    "Lifts", 
                    "Light Towers", 
                    "Motor Graders", 
                    "Personnel Carriers", 
                    "Piling Rigs", 
                    "Pipelayers", 
                    "Portable Cement Mixers", 
                    "Power Trowels", 
                    "Road Marking Equipment", 
                    "Rock Drilling", 
                    "Scrapers", 
                    "Screed", 
                    "Shotcrete Machines", 
                    "Skid Steer Loaders", 
                    "Skip Loaders", 
                    "Slipform Pavers", 
                    "Soil Stabilizers", 
                    "Suction Excavators", 
                    "Topography & Surveying Equipment", 
                    "Trenchers", 
                    "Wheel Loaders"]
  },
  {
    industry: "Material Handling Equipment",
    majorCategory: ["Aircraft Tugs", 
    "Airport Ground Support Equipment (GSE)", 
    "Forklifts", 
    "Maritime Cranes & Port Equipment", 
    "Order Pickers", 
    "Overhead Cranes", 
    "Pallet Stackers", 
    "Pallet Trucks", 
    "Reach Stackers & Container Handlers", 
    "Reach Trucks", 
    "Sideloaders", 
    "Telehandlers", 
    "Tow Tractors", 
    "Turret Trucks(VNA)", 
    "Vacuum Lifts", 
    "Sheet Metal Lifters"]
  },
  {
    industry: "Machine Tools",
    majorCategory: ["Balancing Machines",
      "Bar & Tube Straightening Machines",
      "Bar Feeders/Loaders",
      "Bar Peeling Machines",
      "Beam & Profile Processing Lines",
      "Bending & Forming",
      "Boring Mills",
      "Broaching, Slotting",
      "CNC lathes",
      "Centre Lathes",
      "Coil Handling",
      "Crank Presses",
      "Cut-to-Length Lines",
      "Deburring machines",
      "Deep Hole Drills",
      "Drill Lines",
      "EDM machines",
      "Eccentric Presses",
      "Engraving Machines",
      "Facing & Centering Machines",
      "Forging",
      "Forging Presses",
      "Foundry & casting",
      "Gantry/Planer/Portal Mills",
      "Gear Cutting",
      "Gear Finishing",
      "Gear Generators",
      "Gear Hobbing",
      "Gear Inspection",
      "Gear Shaping",
      "Grinding machines",
      "Heavy Duty Lathes",
      "Honing machines",
      "Laser Cutters",
      "Lathes",
      "Machining Centers",
      "Metal Rolling",
      "Metalworking Measuring Instruments",
      "Milling Machines",
      "Multi-spindle Drilling Machines",
      "Notching Machines",
      "Pillar Drills & Drill Presses",
      "Plasma Cutters",
      "Press Brakes",
      "Presses",
      "Punch Machines",
      "Punch Presses",
      "Radial drills",
      "Riveting Machines",
      "Rotary Tables",
      "Saws",
      "Shearing equipment",
      "Sheet Metal Processing",
      "Slitters & Slitting Lines",
      "Stamping Presses",
      "Threading and Tapping",
      "Transfer Machines",
      "Turret Lathes",
      "Vertical Lathes",
      "Water jet cutters",
      "Welding equipment",
      "Wire machinery"]
  },
  {
    industry: "Marine, Aircraft and Rail",
    majorCategory: ["Aircraft",
      "Boat Trailers",
      "Marine - Other",
      "Marine Commercial",
      "Marine Recreational",
      "Railroad Equipment",
      "Snow Plow & Sander Trucks",
      "Underground Mining",
      "Marine engines and generators",
      "Marine equipment"]
  },
  {
    industry: "Printing",
    majorCategory: ["3D Printers",
      "3D Scanners",
      "Book Binding",
      "Book sewing machines",
      "CTF (computer to film)",
      "CTP (computer to plate)",
      "Collators and Gatherers",
      "Die Cutters",
      "Digital printing",
      "Envelope production and printing",
      "Flatbed Digital Cutting Tables & Plotters",
      "Flexographic printing",
      "Folding machines",
      "Guillotines",
      "Hot Stamping",
      "Label printing",
      "Large Wide Format Printers",
      "Letterpress",
      "Mail Folding & Inserting Machines",
      "Offset press",
      "Pad printing",
      "Paper Drilling",
      "Perfect Binders",
      "Plate Punch and Benders",
      "Polywrapping and Inserting",
      "Post-press",
      "Pre-press",
      "Rotary printing",
      "Rotogravure printing",
      "Saddle Stitchers",
      "Screen printing",
      "Sheet-fed press",
      "Stackers",
      "Three-knife Trimmers",
      "UV-Сoating",
      "Web-fed press"]
  },
  {
    industry: "Processing",
    majorCategory: ["Agitators, Blenders, Mixers",
      "Air Conditioning",
      "Blow Molding",
      "Blowers",
      "Boilers",
      "Bridge Saws",
      "Briquetting",
      "CNC Stone Routers",
      "Cartoning Machines",
      "Centrifugal Separators",
      "Coating & Laminating",
      "Cogeneration Plants",
      "Complete Plants",
      "Condensers",
      "Conveyors",
      "Cooling Towers",
      "Dehumidifiers",
      "Dryers",
      "Dust Collectors",
      "Evaporators",
      "Extruders",
      "Fans & Ventilation",
      "Film Blowing Machines",
      "Filter Presses",
      "Furnaces",
      "Glass equipment",
      "Granulators & Pelletizers",
      "Hammermills",
      "Heat Exchangers",
      "Heaters",
      "Homogenizers",
      "IBC Tanks",
      "Ice Machines",
      "Industrial Chillers",
      "Industrial Compressors",
      "Industrial Vacuum Cleaners",
      "Injection Molding",
      "Labelers",
      "Packaging Machinery",
      "Paint Booths",
      "Paper Cup Machines",
      "Paper-making Machines",
      "Paper/Plastic Bag Machines",
      "Plastics & Rubber",
      "Pugmill Mixing Plants",
      "Reactors",
      "Refrigeration Compressors",
      "Sandblasting & Shotblasting",
      "Stone equipment",
      "Tanks & Kettles",
      "Toilet Paper & Napkin Machines",
      "Ultrasonic Cleaning",
      "Vertical Form Fill Sealing Machines (VFFS)",
      "Vibratory Separators",
      "Water Treatment",]
  },
  {
    industry: "Test, Lab, Medical Equipment",
    majorCategory: ["Air Purifiers",
      "Air Showers/Pass Boxes",
      "Amplifiers",
      "Anesthesia Machines",
      "Antenna, Cable & PIM Analyzers",
      "Audio Analyzers",
      "Autoclaves & Sterilizers",
      "Biosafety Cabinets",
      "Biotechnology & Life Science",
      "Cable Testers",
      "Chromatography",
      "Communication Analyzers",
      "Comparators",
      "Coordinate Measuring & Video Measuring Machines",
      "Defibrillators",
      "Dental equipment",
      "EKG Machines",
      "Electrical Safety Testers",
      "Electrical Test Instruments",
      "Environmental Chambers",
      "Flow, Electric & Gas Meters",
      "Frequency Counters",
      "Gas Analyzers",
      "General Analytical Equipment",
      "General Laboratory Equipment",
      "General Medical Equipment",
      "Gloveboxes",
      "Hospital Beds",
      "Incubators",
      "Infusion Pumps",
      "Lab Freezers & Refrigerators",
      "Laboratory Centrifuges",
      "Laboratory Chillers",
      "Laboratory Fume Hoods",
      "Laboratory Ovens",
      "Laminar Flow Cabinets",
      "Logic Analyzers",
      "Materials Testing",
      "Medical Imaging",
      "Medical Lasers",
      "Medical Ultrasound",
      "Microscopes",
      "Multimeters",
      "Network Analyzers",
      "Ohmmeters",
      "Optical Spectrum Analyzers",
      "Oscilloscopes",
      "Patient Monitors",
      "Pharmaceutical Industry",
      "Photometers & Spectrophotometers",
      "Power Analyzers",
      "Power Supplies",
      "Pulse Oximeters",
      "Rheometers & Viscometers",
      "Signal Analyzers",
      "Spectrometers",
      "Spectroscopy",
      "Spectrum Analyzers",
      "Test Generators",
      "Thermal Cyclers",
      "Time-Domain Reflectometers (TDR)",
      "Titrators",
      "Ventilators",
      "Water Baths",
      "X-Ray Analytical Equipment",
      "pH Meters"]
  },
  {
    industry: "Trucks",
    majorCategory: ["Agricultural Trailers",
      "Ambulance Vehicles",
      "Automotive Equipment",
      "Beverage Trucks",
      "Booster Trailers",
      "Box Trailers",
      "Box trucks",
      "Bucket (boom) trucks",
      "Buses",
      "Cab Chassis trucks",
      "Campers and Caravans",
      "Coach Buses",
      "Concession Trailers",
      "Concrete Mixers",
      "Conventional trucks",
      "Crawler Carriers & Dumpers",
      "Curtainsider Trailers",
      "Dry Bulk & Pneumatic Trailers",
      "Dump Trailers",
      "Dump trucks",
      "Fire Trucks",
      "Flatbed Trailers",
      "Flatbed trucks",
      "Forestry Trailers",
      "Fuel & Lube Trucks",
      "Garbage trucks",
      "Grain Hopper Trailers",
      "Livestock Trailers",
      "Minibuses",
      "Modular Trailers & SPMT",
      "Pickup Trucks",
      "Reefer Trailers",
      "Reefer trucks",
      "School Buses",
      "Septic/Sewer/Vacuum Trucks",
      "Service & Utility Trucks",
      "Swap Bodies",
      "Tanker Trailers",
      "Tanker trucks",
      "Tow trucks",
      "Towable Heaters",
      "Trailers",
      "Transit Buses",
      "Truck Engines",
      "Vans",
      "Winch Trucks"
    ]
  },
  {
    industry: "Waste and Recycling",
    majorCategory: ["Construction Waste Recycling", 
    "Industrial Balers", 
    "Industrial Shredders", 
    "Recycling and disposal", 
    "Scrap Handling", 
    "Waste compactyors", 
    "Wastewater recycling", 
    "Street Sweepers"
  ]
  },
  {
    industry: "Woodworking",
    majorCategory: ["Boring, Dowel, Inserting and Gluing", 
    "CNC wood routers", 
    "Edgebanders", 
    "Mortisers and tenoners", 
    "Planers and Moulders", 
    "Sanders", 
    "Veneer Machines", 
    "Window Productions", 
    "Wood CNC Machining Centers", 
    "Wood Finishing Equipment", 
    "Wood lathes", 
    "Wood Milling Machines",
    "Wood Presses", 
    "Wood Sawmills", 
    "Wood Saws"
  ]
  },
  {
    industry: "Other",
    majorCategory: ["ATV & UTV", 
    "Audiovisual/Broadcast", 
    "Centrifugal pumps", 
    "Electric pumps", 
    "Electric and electronic component", 
    "Floor scrubbers and sweepers", 
    "Golf carts", 
    "Positive displacement pumps", 
    "Power plants", 
    "Pressure washers", 
    "Salt/ Sand Spreaders", 
    "Snow Blowers", 
    "Solar Power", 
    "Stump Grinders", 
    "Telecommunications Equipment", 
    "Transformers", 
    "Turbines", 
    "Turf Equipment", 
    "Vaccum Pumps", 
    "Water Pumps", 
    "Wind Turbines"
  ]
  },

];

export const industryList = industyCategories.filter((un) => un.industry).map((un) => un.industry);