import { BlobServiceClient } from '@azure/storage-blob';

const sasToken = "sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2024-06-22T15:45:29Z&st=2024-06-06T07:45:29Z&spr=https,http&sig=pr7JNodALaXMA%2FY7%2BhT%2FinlZBaLe67%2BiDIn1c0SUGNA%3D";
const blobServiceClient = new BlobServiceClient(`https://atgdevstore.blob.core.windows.net?${sasToken}`);

async function doesBlobExist(containerName, blobName) {
    try {
        // Get a container client
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // Get a blob client
        const blobClient = containerClient.getBlobClient(blobName);

        // Check if the blob exists by attempting to get its properties
        await blobClient.getProperties();
        return true; // Blob exists
    } catch (error) {
        if (error.statusCode === 404) {
            return false; // Blob does not exist
        }
        throw error; // Some other error occurred
    }
}

export default doesBlobExist;