import { BlobServiceClient } from '@azure/storage-blob';

async function uploadImage(containerName, subcontainer, file) {
    const sasToken = "sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2025-05-01T12:12:23Z&st=2024-02-01T04:12:23Z&spr=https,http&sig=gnTm2TuTY0BjyowjsjFVgdf0xVAAnxruPRx5tR3rDME%3D";
    const blobServiceClient = new BlobServiceClient(`${process.env.REACT_APP_BLOB_SAS_URL}`);

    // Get a container client
    const containerClient = blobServiceClient.getContainerClient(containerName);

    const blobName = `${subcontainer}/${file.name}`;
    // Get a block blob client
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    // Upload data to blob storage
    await blockBlobClient.uploadData(file, {
        blockSize: 4 * 1024 * 1024, // 4MB block size
        concurrency: 20,
        onProgress: ev => console.log(ev)
    });
    const fileUrl = blockBlobClient.url;
    console.log(`Upload of file '${file.name}' completed`);
    return { fileUrl, fileName: file.name, etag: blockBlobClient.etag, lastModified: blockBlobClient.lastModified };
}

export default uploadImage;