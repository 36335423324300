import styled from 'styled-components';
import Image from "../assets/images/image.jpg"

export const AppContent = styled.div`
    flex: 1 1;
    display: flex;
    padding: 0;
    flex-direction: column;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    /* padding-top: 78px; */ 
`;
export const AppContentInner = styled.div`
    margin: -3rem 0.5rem -1rem;
`;

export const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
`;
export const AppMain = styled.div`
    position: relative;
    min-height: 68vh;
    .cardView{ 
       background-color: white;
    }
`;

export const Header = styled.div`
    padding: 1rem;
    margin: 0.5rem 0.5rem 0 0.5rem;
`;
export const HeaderText = styled.div`
    text-align: center;
    margin-bottom: 1rem;
    color:#9E9E9E;
    & h5 {
        font-size: 1.1875rem;
        font-weight: 500;
        color:#778899;
    }
`;

export const LoginBackground = styled.div`
background-size:cover;
background-repeat:no-repeat;
background-image:url(${Image});
`;

export const AdminBackground = styled.div`
background-color:#9E9E9E
`;

export const Title = styled.div`
  font-size: 1.5em;
  text-align: left;
  color: palevioletred;
  width:40%;
  display:inline-block;

`;
export const Description = styled.div`
  font-size: 1.5em;
  text-align: left;
  color: palevioletred;
  width:60%;
  vertical-align:top;
  display:inline-block;

`;