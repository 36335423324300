import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
  submitDealRegisterForm,
  getDealList, 
  dealInvitationAccept,
  dealInvitationReject, 
  deleteDeal } from '../../reducers/DealReducer';
import { submitMakeOfferMessage } from "../../reducers/DealCommunicationReducer";
import {
  Card,
  Typography,
  IconButton,
  Grid,
  Box,
  Tooltip,
  FormGroup,
  Switch,
  Tabs,
  Tab,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer, GridToolbarExport, GridPagination
} from "@mui/x-data-grid";
import { withStyles, makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import { orange, blue } from '@mui/material/colors';
import { setLoggedInUserData } from '../../reducers/UserReducer';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SearchBar from '../../utils/Search';
import { ThemeProvider, createTheme } from '@mui/material';
import Badge from '@mui/material/Badge';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Stack } from '@mui/system';
import CustomizedTooltips from "./toolTip"

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: '#0737AB', // Color when unchecked
    '&.Mui-checked': {
      color: '#0737AB', // Color when checked
      '& + .MuiSwitch-track': {
        backgroundColor: '#0737AB', // Track color when checked
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#0737AB', // Track color when unchecked
  },
}));


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '20px'
  },
  tableTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 1.5rem'
  },
  actions: {
    color: "rgb(17, 82, 147)"
  },
  fullWidth: {
    marginLeft: "20px"
  },
  link: {
    color: "rgb(105, 105, 105)"
  },
  childDiv: {
    paddingTop: "10px",
  },
  tableheader: {
    marginTop: "-3rem"
  },
  cardStyle: {
    height: "390px",
    width: "1314px",
    marginLeft: "-5rem",
    marginBottom: "-1rem"
  },
  formgroup: {
    marginLeft: "5rem"
  },
  toggleRadio: {
    display: "inline-block",
    marginLeft: "-10rem"
  },
  rejectText: {
    backgroundColor: "#EC7063",
  },
  // tabs: {
  //   marginTop: "10px"
  // },
  // tab1: {
  //   width: "100px",
  //   backgroundColor: "#3CB371",
  //   height: "45px",
  //   '&:hover': {
  //     backgroundColor: '#76ff03',
  //   },
  // },
  // tab2: {
  //   width: "100px",
  //   backgroundColor: "#ff9100",
  //   height: "45px",
  //   '&:hover': {
  //     backgroundColor: '#FFB533',
  //   },
  // }
}));
const DealList = props => {
  const { dealList = [], totalCount, getDealList, loggedInUserData } = props;
  const history = useHistory();
  const classes = useStyles();
  const [filterChecked, setFilterCheked] = useState((loggedInUserData.user.roleSystem === 'admin') ? false : true);
  const [pageState, setPageState] = useState({
    page: 1,
    limit: 100,
    sort: null,
    searchData: '',
    participant: loggedInUserData.user.roleSystem === 'admin' ? '' : loggedInUserData.user._id
  });

  const [search, setSearch] = React.useState('');
  const [filteredDealList, setFilteredDealList] = useState([]);
  const [rowCountState, setRowCountState] = React.useState(totalCount);
  // console.log("dealList", dealList);

  // const [areActiveDeals, setAreActiveDeals] = useState(true);
  // const [alignment, setAlignment] = React.useState(true);
  const transactionId = "6231b09f5292800b4074918e";
  // const [newDeal, setNewDeal] = useState([]);
  // console.log("newDeal", newDeal);

  // useEffect(() => {
  //   // Set filteredUserList to userList initially
  //   if(dealList.length > 0){
  //     setFilteredDealList(dealList);
  //   }
  // }, [dealList]);

  useEffect(() => {
    // Filter user list when search state changes
    if(dealList?.length > 0){
    const filteredList = dealList.filter(user =>
      (user.dealName && user.dealName.toLowerCase().includes(search.toLowerCase())) ||
      (user.dealDescription && user.dealDescription.toLowerCase().includes(search.toLowerCase())) ||
      (user.dealStartDate && user.dealStartDate.toLowerCase().includes(search.toLowerCase())) ||
      (user.creatorCompany && user.creatorCompany.toLowerCase().includes(search.toLowerCase()))
    );
    
    setFilteredDealList(filteredList);
    if(filteredList.length > 0){
    setRowCountState(filteredList?.length)
    }}
  }, [search, dealList]);

  useEffect(() => {
    const getList = async () => {
      if (pageState ) {
        const isInvited = true;
        await getDealList(pageState, loggedInUserData, isInvited);
      } else {
        await getDealList(pageState);
      }
    }
    getList();
  }, [getDealList, loggedInUserData,  pageState]);


  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);

  // useEffect(() => {
  //   if (dealList?.length > 0) {
  //     setNewDeal(dealList);
  //   }
  // }, [dealList]);

  // const handleActiveDeal = (event) => {
  //   setAlignment(event.target.checked);
  //   if (event.target.checked) {
  //     setNewDeal(dealList);
  //   } else {
  //     setNewDeal(dealList);
  //   }
  // }


  const handleActiveDealChange = (event) => {
    setFilterCheked(event.target.checked);
    if (event.target.checked) {
      setPageState((old) => ({ ...old, participant: loggedInUserData.user._id }));
    } else {
      setPageState((old) => ({ ...old, participant: '' }));
    }
  }

  const handleDealEdit = (event, deal) => {
    history.push(`/create/deals/create?action=edit&id=${deal.id}`);
  }
  // const handleDealView=(event, deal)=>{
  //   history.push(`/deal/grid?dealId=${deal.id}`);
  // }
  const handleDealDelete = (event, deal) => {
    props.deleteDeal(deal.id, dealList, totalCount, loggedInUserData.user._id);
  }

  const handleAccept = (event, deal) => {
    const dealId = deal.id;
    const dealInvitesId = (deal.dealInvites || []).filter(
      (un) => un.invitedUser === loggedInUserData.user._id
    );
    const filterInvites = dealInvitesId.map((un) => un._id);
    const result = filterInvites.reduce((acc, cur) => {
      acc = cur;
      return acc;
    }, {});
    const payload = {
      isAccepted: true,
      dealInvites: {
        "dealInvites.$.inviteStatus": "Joined",
      },
      dealParticipants: {
        dealParticipants: [
          {
            startDate: new Date().toISOString(),
            endDate: new Date(
              new Date().setDate(new Date().getDate() + 10)
            ).toISOString(),
            participant: loggedInUserData.user._id,
          },
        ],
      },
    };

    const transactioncommunication = {
      transactioncommunication: {
          dealId: dealId,
          transactionId: transactionId,
          message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} added to this deal successfully`,
          transactionEquipment: deal.dealEquipment,
          from: loggedInUserData.user._id,
          to: deal.dealParticipants.map((p) => { return p.participant._id }),
          notificationType: "General Message",
          notes: "notes",
          askPrice: "",
          offerPrice: "",
          priceUnit: "USD",
          addedBy: loggedInUserData.user._id
      },
      dealname: deal.dealName,
      dealparticipantemails: deal.dealParticipants.map((p) => { return p.participant.email })
  }
    props.dealInvitationAccept(dealId, result, payload, loggedInUserData.user._id);
    props.submitMakeOfferMessage(transactioncommunication);
  };

  const handleReject = (event, deal) => {
    const dealId = deal.id;
    const dealInvitesId = (deal.dealInvites || []).filter(
      (un) => un.invitedUser === loggedInUserData.user._id
    );
    const filterInvites = dealInvitesId.map((un) => un._id);
    const rejectResult = filterInvites.reduce((acc, cur) => {
      acc = cur;
      return acc;
    }, {});
    const payload = {
      isAccepted: false,
      dealInvites: {
        "dealInvites.$.inviteStatus": "Left",
      },
      dealParticipants: {
        // dealParticipants: [
        //   {
        //     startDate: "",
        //     endDate: "",
        //     participant: "",
        //   },
        // ],
      },
    };

    const transactioncommunication = {
      transactioncommunication: {
          dealId: dealId,
          transactionId: transactionId,
          message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} was rejected this deal`,
          transactionEquipment: deal.dealEquipment,
          from: loggedInUserData.user._id,
          to: deal.dealParticipants.map((p) => { return p.participant._id }),
          notificationType: "General Message",
          notes: "notes",
          askPrice: "",
          offerPrice: "",
          priceUnit: "USD",
          addedBy: loggedInUserData.user._id
      },
      dealname: deal.dealName,
      dealparticipantemails: deal.dealParticipants.map((p) => { return p.participant.email })
  }
    props.dealInvitationReject(dealId, rejectResult, payload, loggedInUserData.user._id);
    props.submitMakeOfferMessage(transactioncommunication);
  };

  const columns = [
    // {
    //   field: "unreadMessages",
    //   headerName: "Unread Messages",
    //   width: 160,
    //   renderCell: (params) => (
    //     <Link to={`/deal/grid?dealId=${params.id}`} style={{ textDecoration: "none", marginLeft: "10%", pointerEvents: (loggedInUserData.user.roleSystem === "admin") ? "none" : "auto" }} className={classes.link} target="_self">
    //       <Badge badgeContent={(Array.isArray(params.row.UnreadMessages) ? params.row.UnreadMessages.filter((row) => row?.userId === loggedInUserData.user._id).map((x) => x.totalUnReadMessages)[0] : 0)} color="primary">
    //         <ChatBubbleOutlineIcon color="action" />
    //       </Badge>
    //     </Link>
    //   ),
    // },
    {
      field: "dealName",
      headerName: "Deal Name",
      width: 300,
      renderCell: (params) => (
        <>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center">
            <Badge badgeContent={(Array.isArray(params.row.UnreadMessages) ? params.row.UnreadMessages.filter((row) => row?.userId === loggedInUserData.user._id).map((x) => x.totalUnReadMessages)[0] : 0)} color="primary">
              <ChatBubbleOutlineIcon color="action" />
            </Badge>
            <Link to={`/deal/grid?dealId=${params.id}`} style={{ textDecoration: "none", pointerEvents: (loggedInUserData.user.roleSystem === "admin") ? "none" : "auto" }} className={classes.link} target="_self">
              <CustomizedTooltips data={params.row} />
            </Link>
          </Stack>
        </>
      ),
    },
    {
      field: "payment",
      headerName: "Deal Status",
      type: "string",
      width: 150,
    },
    {
      field: "dealDescription",
      headerName: "Deal Description",
      type: "string",
      width: 200,
    },
    {
      field: "dealStartDate",
      headerName: "Deal Start Date",
      type: "string",
      width: 220,
    },
    {
      field: "creatorCompany",
      headerName: "Company",
      width: 220,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const dealInvites = params.row.dealInvites || [];
        const inviteSent = dealInvites.some(un => un.invitedUser === loggedInUserData.user._id && un.inviteStatus === "Sent");
        const inviteJoined = dealInvites.some(un => un.invitedUser === loggedInUserData.user._id && un.inviteStatus === "Joined");
        // const isAdmin = loggedInUserData.user.roleSystem === 'admin';
        const logged = params?.row?.dealParticipants?.some(un => un.participant?._id === loggedInUserData?.user?._id);
        const isLoggedInUser = dealInvites?.createtorid !== loggedInUserData?.user?._id;
        return (
          <>
            {inviteSent && isLoggedInUser ? 
              <div>
                <button
                  onClick={(event) => handleAccept(event, params.row)}
                  className={classes.accept}
                >
                  Accept
                </button>
                &nbsp;&nbsp;
                <button
                  onClick={(event) => handleReject(event, params.row)}
                  className={classes.reject}
                >
                  Reject
                </button>
              </div>
             : 
              <div>
                {logged || (loggedInUserData.user.roleSystem === 'admin') ? 
                <>
                {filterChecked ?
                  <Tooltip title="Edit Deal">
                    <IconButton
                      sx={{ color: "#3E5272" }}
                      onClick={(event) => {
                        handleDealEdit(event, params.row);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  : null}
                  {logged || (loggedInUserData.user.roleSystem === 'admin') ? 
                <Tooltip title="Delete Deal">
                  <IconButton
                    sx={{ color: "#3E5272" }}
                    onClick={(event) => {
                      window.confirm(
                        "Are you sure want to delete " +
                        params.row.dealName +
                        " ?"
                      ) && handleDealDelete(event, params.row);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                :
                null}
                </>
                 : 
                 (
                  <p className={classes.rejectText}>Rejected</p>
                )}
                
              </div>
            }
            {/* {filterChecked && !(loggedInUserData.user.roleSystem === 'admin') ?
              <Tooltip title="Edit Deal">
                <IconButton
                  sx={{ color: "#3E5272" }}
                  onClick={(event) => {
                    handleDealEdit(event, params.row);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              : null}
            <Tooltip title="Delete Deal">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  window.confirm(
                    "Are you sure want to delete " +
                    params.row.dealName +
                    " ?"
                  ) && handleDealDelete(event, params.row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              {filterChecked ? "MY DEALS" : "ALL DEALS"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item lg={6} xs={6}>
                <div className={classes.search}>
                  {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                {/* <div className={classes.childDiv}>
                  <GridToolbarContainer>
                    <GridToolbarExport />
                  </GridToolbarContainer>
                </div> */}
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.childDiv}>
                  <Tooltip title="Create New Deal">
                    <Link to="/create/deals">
                      <IconButton sx={{ color: "#3E5272" }}>
                        <AddBoxIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  // const onFilterChange = (event) => {
  //   // Here you save the data you need from the filter model and perform server-side search/filtering
  //   // Once the server-side search is completed, you can update the searchInput state with the new value

  //   // Example: Simulating server-side search delay with setTimeout
  //   setSearch(event.target.value);
  //   // if (search.length > 4 || search.trim() == '') {
  //   if (event?.target?.value?.length >= 3)
  //     setTimeout(() => {
  //       setPageState((old) => ({ ...old, searchData: event.target.value }));
  //     }, 2000);
  //   if (event?.target?.value?.length < 1)
  //     setPageState((old) => ({ ...old, searchData: '' }));
  //   // }
  // };

  const onFilterChange = (event) => {
    setSearch(event.target.value);
    
  };

  // console.log(pageState.search);
  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };

  // const handleChange = (event) => {
  //   let value = event.target.checked;
  //   setAlignment(value);
  //   // console.log("value", value);
  //   // if (value === "active") {
  //   //   setNewDeal(dealList);
  //   // } else {
  //   //   setNewDeal(dealList);
  //   // }
  // };


  return (
    <div className={classes.tableheader}>
      <div className={classes.toggleRadio}>
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          {/* <div>
          <FormGroup row className={classes.formgroup}>
            <Typography variant="h6" color="main">CLOSED DEALS</Typography>
            <PurpleSwitch checked={alignment} onChange={handleChange} name="checkedC" />
            <Typography variant="h6" color="main">ACTIVE DEALS</Typography>
          </FormGroup>
        </div> */}
        <div>
          <FormGroup row className={classes.formgroup}>
            <Typography variant="h6" color="main">ALL DEALS</Typography>
            <CustomSwitch checked={filterChecked} onChange={handleActiveDealChange} name="checkedC" disabled={(loggedInUserData.user.roleSystem === 'admin')} />
            <Typography variant="h6" color="main">MY DEALS</Typography>
          </FormGroup>
        </div>
        </Box>
        
      </div>
      {/* <div className={classes.toggleRadio}>
        <div>
          <FormGroup row className={classes.formgroup}>
            <Typography variant="h6" color="main">Closed Deals</Typography>
            <PurpleSwitch checked={alignment} onChange={handleChange} name="checkedC" />
            <Typography variant="h6" color="main">Active Deals</Typography>
          </FormGroup>
        </div>
        <div>
          <FormGroup row className={classes.formgroup}>
            <Typography variant="h6" color="main">ALL DEALS</Typography>
            <PurpleSwitch checked={filterChecked} onChange={handleActiveDealChange} name="checkedC" disabled={(loggedInUserData.user.roleSystem === 'admin')} />
            <Typography variant="h6" color="main">MY DEALS</Typography>
          </FormGroup>
        </div>
      </div> */}
      <SearchBar
        searchText={search}
        filterChange={onFilterChange}
        onClickFunc={handleClearText}
      />
      <Card className={classes.cardStyle}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={filteredDealList || []}
            columns={columns}
            rowsPerPageOptions={[100]}
            checkboxSelection
            disableSelectionOnClick
            rowCount={filteredDealList.length >= 0 ? filteredDealList.length : rowCountState}
            paginationMode="server"
            pagination
            pageSize={pageState.pageSize}
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            sortingMode="server"
            onSortModelChange={(col) => {
              if (col.length > 0) {
                if (col[0].sort === "asc") {
                  setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
                } else {
                  setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
                }
              } else {
                setPageState((old) => ({ ...old, sort: null }));
              }
            }}
            sx={{
              "& .css-ptiqhd-MuiSvgIcon-root": {
                color: "#ee9907",
              },
              // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              //   display: 'none'
              // }
            }}
            // density='standard'
            // components={{ Toolbar: DataGridTitle }}
            components={{
              Toolbar: DataGridTitle,
              Pagination: GridPagination,
            }}
          />
        </ThemeProvider>
      </Card>
    </div>
  )
}
const mapStateToProps = state => ({
  dealList: state.deal && state.deal.dealList.dealList,
  loggedInUserData: state.user && state.user.loggedInUserData,
  totalCount: state.deal && state.deal.dealList.total,
  limit: state.deal && state.deal.dealList.limit,
});
export default connect(mapStateToProps, { submitDealRegisterForm, getDealList, deleteDeal, setLoggedInUserData, dealInvitationAccept, dealInvitationReject, submitMakeOfferMessage })(DealList);
