import React, { useState, useEffect } from "react";
import { Visibility, VisibilityOff, AttachMoney as AttachMoneyIcon } from '@mui/icons-material';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getDealById } from "../../reducers/DealReducer";
import { setLoggedInUserData } from "../../reducers/UserReducer";
import useQuery from "../../utils/useQuery";
import DealSome from "../DealCloseOut";
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
  Card,
  Grid,
  Checkbox,
  Button,
  TextField,
  Box,
  Typography,
  InputAdornment,
  IconButton
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { submitTransactionRegisterForm } from "../../reducers/TransactionReducer";
import { ThemeProvider, createTheme } from '@mui/material';
import { submitMakeOfferMessage } from "../../reducers/DealCommunicationReducer";


const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  grid: {
    // paddingBottom: "25px",
    // marginTop: "-90px",
    marginTop: 10,
  },
  makeOfferContainer: {
    // margin: 10,
    display: "flex",
    justifyContent: "flex-end",
    gap: 10,
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  makeOffer: {
    color: "rgb(204, 102, 0)",
    fontSize: "large",
    fontWeight: 700,

    // marginTop: "270px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "medium",
      // fontWeight: 700,
    },
  },

  makeOfferFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },

  gridChild: {
    // paddingTop: "20px",
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  icon: {
    // marginTop: "-3px",
    // paddingRight: "5px",

    color: "rgb(204, 102, 0)",
    alignItems: "center",
  },
  parent: {
    display: "grid",
    gridTemplateColumns: "7rem 2rem 7rem",
    paddingLeft: "2px",
    marginTop: "-2px",
  },
  input: {
    padding: "7px 0px 8px 0px",
    width: "150px",
    textAlign: "center",
    borderRadius: 3,
    border: "1px solid #555",
  },
  topGrid: {
    paddingTop: "20px",
  },
  makeSeller: {
    paddingLeft: "90px",
    color: "rgb(204, 102, 0)",
    fontSize: "large",
    fontWeight: 700,
  },
  salConst: {
    paddingLeft: "3px",
    paddingBottom: "20px",
    // color: "#696969",
    fontSize: "X-large",
    fontWeight: "bolder",
  },
  salConsttwo: {
    paddingLeft: "150px",
    paddingBottom: "20px",
    // color: "#696969",
    fontSize: "large",
    fontWeight: "bolder",
    marginTop: "-2px",
    display: "flex"
  },
  bottonGrid: {
    paddingLeft: "90px",
    marginTop: "-78px",
  },
  link: {
    textDecoration: "none",
  },
  mediaLink: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      backgroundColor: "rgb(180, 180, 180)",
    },
  },
  button: {
    textAlign: "right",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  btnwidth: {
    width: "134%",
    paddingTop: "10px",
  },
  dataGrid: {
    // paddingBottom: "1px",
  },
  numberField: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  MakeButton: {
    color: "white",
    backgroundColor: "rgb(62, 82, 114)",
    "&:hover": {
      backgroundColor: "#fbbc05",
      //   color: "black"
    },
  },
  childDiv: {
    paddingTop: "10px",
    paddingLeft: "50px",
  },
  dealEquip: {
    paddingLeft: "10px",
  },
}));

const DataGridDemo = (props) => {
  const {
    restClient: { rest },
    dealData,
    getDealById,
    loggedInUserData,
    dealCommunicationList = []
  } = props;
  const classes = useStyles();
  const [data, setData] = useState();
  const [rows, setRows] = React.useState();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [showSerialNumbers, setShowSerialNumbers] = useState(false);

  // console.log("dealData", dealData);

  const toggleSerialNumberVisibility = () => {
    setShowSerialNumbers(!showSerialNumbers);
  };

  const val = dealData?.dealParticipants?.filter((p) => p.participant._id !== loggedInUserData.user._id);

  const query = useQuery();
  const dealID = query.get("dealId");
  const [first, setFirst] = useState([]);
  const transactionId = "6231b09f5292800b4074918e";


  useEffect(() => {
    if (dealID && rest) {
      const getDeal = async () => {
        getDealById(dealID);
      };
      getDeal();
    }
  }, [dealID, rest, getDealById]);

  useEffect(() => {
    if (Array.isArray(dealData.dealEquipment)) {
      setRows(dealData.dealEquipment);
    }
  }, [dealData.dealEquipment]);

  const getRowData = () => {
    // const equipmentsData = rows
    //   .filter((object) => selectedRows.includes(object._id))
    //   .map((un) => un._id);
    const transaction = {
      dealId: dealID,
      equipment: first,
      selectionName: "selectionName",
      price: data,
      addedBy: loggedInUserData.user._id,
    };
    const transactioncommunication = {
      transactioncommunication: {
        dealId: dealID,
        transactionId: transactionId,
        message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} Made an offer: $${data}`,
        transactionEquipment: first,
        from: loggedInUserData.user._id,
        to: dealData?.seller,
        notificationType: "Make Offer",
        notes: "notes",
        askPrice: "",
        offerPrice: data,
        priceUnit: "USD",
        addedBy: loggedInUserData.user._id,
      },
      dealname: dealData.dealName,
      dealparticipantemails: dealData.dealParticipants.filter((un) => un.participant.makeOffer === true).map((p) => { return p.participant.email }),
    }

    const payload = { transaction };

    function updateUnreadMessages(masterObj, filterObj) {
      if (masterObj.userId === filterObj.participant._id) {
        masterObj.totalUnReadMessages += 1;
        masterObj.readMessage = false;
      }
    }
    const dealId = props?.dealCommunicationList[0]?.dealId;
    // Iterate through masterData and filterData

    if (dealId && dealId?.UnreadMessages) {
      // Iterate through UnreadMessages and users
      dealId?.UnreadMessages.forEach((masterObj) => {
        val?.forEach((filterObj) => {
          updateUnreadMessages(masterObj, filterObj);
        });
      });
    }
    props.submitTransactionRegisterForm(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
    props.submitMakeOfferMessage(transactioncommunication);
  };

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const handleCheckboxChange = (event, rowId) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    }
  };

  const handleTextFieldChange = (event, rowId) => {
    if (rowId) {
      let val = 0;
      const elems = document.getElementsByName("num1");
      elems.forEach(function (valueCheck) {
        if (valueCheck.value !== "") val = val + parseFloat(valueCheck.value);
      });
      setData(val);
      setFirst([...first, rowId]);
    }
  };

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              DEAL EQUIPMENT
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item lg={12} xs={6}>
                <div className={classes.search}>
                  {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function formatCurrency(value) {
    if (value) {
      return `$${value}`;
    } else {
      return null;
    }
  }

  const reserveValue = loggedInUserData?.user?._id === dealData?.seller;

  const isSeller = loggedInUserData?.user?._id === dealData?.seller;

  const isBuyer = dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData?.user?._id);

  const usertype = loggedInUserData?.user?._id === dealData?.seller ? "Seller" : dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id) ? "Buyer" : loggedInUserData?.user?.atgRole?.roleType[0];
  // console.log("logged", loggedInUserData);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: isSeller ? "#2ecd71" : isBuyer ? "#1a75c3" : "#f1c40f",
              color: "white"
            },
          },
          columnSeparator: {
            visibility: "hidden",
          },
        },
      },
    },
  });

  return (
    <div>
      <div className={classes.topGrid}>
        <Grid container spacing={0}>
          <Grid item xs={12} lg={4}>
            <Typography variant="h6" className={classes.salConst} style={{ color: isSeller ? "#2ecd71" : isBuyer ? "#1a75c3" : "#f1c40f" }}>
              {dealData && dealData.dealName}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography variant="h6" className={classes.salConsttwo} style={{ color: isSeller ? "#2ecd71" : isBuyer ? "#1a75c3" : "#f1c40f" }}>
              {/* <div>UserType :</div> */}
              <div>{usertype}</div>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <div className={classes.button}>
              <Link to="/dealpage" className={classes.link}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  text-decoration="none"
                >
                  Back To Deals
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={classes.dataGrid}>
        <Card sx={{ height: 400, width: "100%" }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              rows={rows || []}
              // autoHeight
              columns={[
                // {
                //   headerName: "",
                //   width: 70,
                //   field: "",
                //   renderCell: (rowdata) => (
                //     <Checkbox
                //       checked={selectedRows.includes(rowdata.row._id)}
                //       onChange={(event) =>
                //         handleCheckboxChange(event, rowdata.row._id)
                //       }
                //     />
                //   ),
                // },
                {
                  field: "equipmentName",
                  headerName: "Equipment",
                  width: 270,
                },
                {
                  field: "modelYear",
                  headerName: "Model Year",
                  width: 100,
                  valueFormatter: (params) => {
                    const date = new Date(params.value);
                    return date.getFullYear();
                  },
                },
                {
                  field: "make",
                  headerName: "Make",
                  width: 120,
                },
                {
                  field: "serialNum",
                  headerName: "Serial No",
                  width: 200,
                  renderCell: (params) => (
                    <div>
                      {showSerialNumbers ? params.value : "••••••••••••••••"}
                      <IconButton
                        aria-label="toggle serial number visibility"
                        onClick={toggleSerialNumberVisibility}
                        edge="end"
                      >
                        {showSerialNumbers ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                  ),
                },
                ...(reserveValue ? [{
                  field: "reservePrice",
                  headerName: "Reserve Price(USD)",
                  width: 150,
                  valueFormatter: (params) => formatCurrency(params.value ? params.value : "")
                }] : []),
                {
                  field: "listingPrice",
                  headerName: "Listing Price(USD)",
                  width: 150,
                  valueFormatter: (params) => formatCurrency(params.value ? params.value : "")
                },
                ...(!isSeller ? [{
                  field: "price",
                  width: 200,
                  headerName: "Buyer Price",
                  renderCell: (rowdata) => (
                    <TextField
                      // disabled={!selectedRows.includes(rowdata.row._id)}
                      // value={data}
                      className={classes.numberField}
                      variant="outlined"
                      size="small"
                      onChange={(event) => {
                        handleTextFieldChange(event, rowdata.row._id);
                      }}
                      id="textBox"
                      name="num1"
                      type="number"
                      inputProps={{ min: 0 }}
                      style={{ padding: "10px 0px 10px 0px", width: "147px" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ),
                }] : []),
                // {
                //   field: "dollar",
                //   headerName: "",
                //   width: 50,
                //   renderCell: () => (
                //     <div>
                //       <AttachMoneyIcon style={{ color: "rgb(204, 102, 0)" }} />
                //     </div>
                //   ),
                // },
              ]}
              getRowId={(row) => generateRandom()}
              pageSize={100}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              sx={{
                "& .css-ptiqhd-MuiSvgIcon-root": {
                  color: "#ee9907",
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                  display: 'none'
                }
              }}
              components={{ Toolbar: DataGridTitle }}
            />
          </ThemeProvider>
        </Card>
      </div>

      <div className={classes.grid}>
        <Grid container spacing={0} className={classes.makeOfferContainer}>
          <Grid item lg={12}>
            <div className={classes.makeOfferFlex}>
              {/* <div className={classes.makeOffer}>
                MAKE OFFER ON ALL SELECTED EQUIPMENTS
              </div> */}
              {!isSeller ?
              <div className={classes.gridChild}>
                <div style={{ lineHeight: 0 }}>
                  <AttachMoneyIcon className={classes.icon} fontSize="small" />
                </div>
                <div>Offer Price </div>
                <div>
                  <input
                    type="text"
                    value={data}
                    readOnly
                    placeholder="US DOLLARS"
                    className={classes.input}
                  />
                </div>
              </div> 
              : null}
              {dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id) ? (
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!data}
                    onClick={getRowData}
                    className={classes.MakeButton}
                  >
                    Make Offer
                  </Button>
                </div>
              ) : null}
            </div>
          </Grid>
          {/* <Grid item lg={12}>
            <div className={classes.makeOfferFlex}>
              <div className={classes.makeOffer}>
                MAKE OFFER ON ALL EQUIPMENTS IN THIS LISTING
              </div>
              <div className={classes.gridChild}>
                <div style={{ lineHeight: 0 }}>
                  <AttachMoneyIcon className={classes.icon} fontSize="small" />
                </div>
                <div>Offer Price</div>
                <div>
                  <input
                    type="number"
                    placeholder="US DOLLARS"
                    className={classes.input}
                  />
                </div>
              </div>
              {loggedInUserData.user._id === dealData.buyer ? (
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    // disabled={!data}
                    disabled
                    onClick={getRowData}
                    className={classes.MakeButton}
                  >
                    Make Offer
                  </Button>
                </div>
              ) : null}
            </div>
          </Grid> */}
        </Grid>
      </div>
      <DealSome />
      {/* <Grid container spacing={0}>
        <Grid item xs={12} lg={12}>
          <div className={classes.button}>
            <Link to="/dealpage" className={classes.link}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                text-decoration="none"
              >
                Go To Deals
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  restClient: state.restClient,
  dealData: state.deal && state.deal.dealData,
  loggedInUserData: state.user && state.user.loggedInUserData,
  dealCommunicationList: state.dealCommunication && state.dealCommunication.dealCommunicationList,
});
export default connect(mapStateToProps, {
  getDealById,
  setLoggedInUserData,
  submitTransactionRegisterForm,
  submitMakeOfferMessage
})(DataGridDemo);
