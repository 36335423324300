import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid
} from "@mui/material";
import { getDealList, getDealById } from '../../reducers/DealReducer';
import { connect } from 'react-redux';
import useQuery from '../../utils/useQuery';
import DealMessageChat from "../DealChat";
import { setLoggedInUserData } from '../../reducers/UserReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  typography: {
    fontWeight: "bold",
    marginTop: "1.0em",
    textAlign: "center",
    color: "orange",
    fontSize: "larger"
  },
  dealcommunication: {
    textAlign: "center",
    paddingTop: "50px"
  },
  container: {
    position: "relative",
    textAlign: "center",
    color: "white",
  },
  topBottom: {
    float: "right"
  },
  rightBar: {
    position: "absolute",
    top: "22vh",
    right: "10%",
    width: "30%",
    height: "60px",
    color: "black",
    fontFamily: "Times New Roman",
    display: "block",
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  topRight: {
    position: "absolute",
    top: "0.2em",
    right: "0.5%",
    width: "30%",
    height: "auto",
    backgroundColor: "white",
    color: "black",
    border: "1px solid black",
    fontFamily: "Times New Roman",
    display: "block",
  },
  salConst: {
    color: "rgb(120, 120, 120)",
    fontWeight: 900,

  },
  button: {
    textAlign: "center",
    paddingBottom: "10px",
    paddingTop: "10px"
  },
  image: {
    maxWidth: "100%",
    width: "410px"
  },
  dialogTitle: {
    padding: "10px 13px"
  },
  close: {
    float: "right",
    paddingTop: "1px",
    marginTop: "2px"
  },
  leftArrow: {
    float: "left",
    marginTop: "4px"
  },
  makeOffer: {
    textAlign: "center",
    marginTop: "5%",
    marginLeft: "7%",
    fontWeight: "bold"
  },
  rightmiddle: {
    marginTop: "10px",
  },
  link: {
    textDecoration: "none"
  },
  margin: {
    width: "30vw"
  },
  rating: {
    border: "1px solid black"
  },
  listIcon: {
    paddingLeft: "20px",
    paddingTop: "4px",
    color: "rgb(120, 120, 120)"
  },
  divs: {
    textAlign: "center",
    // paddingRight: "175px",
    float: "left"
  },
  userDiv: {
    textAlign: "center",
    // paddingLeft: "175px",
    float: "right"
  },
  listLink: {
    display: "flex",
    paddingTop: "30px"
  },
  dropdown: {
    paddingTop: "16px"
  },
  messageChat: {
    paddingTop: "50px"
  }
}));

const DealSome = (props) => {
  const { restClient: { rest }, dealData, getDealById, loggedInUserData } = props;
  const classes = useStyles();
  const [equipment, setEquipment] = useState([]);
  const query = useQuery();
  const dealID = query.get('dealId');
  const [sortedUserList, setSortedUserList] = useState([]);

  useEffect(() => {
    if (Array.isArray(dealData.dealEquipment)) {
      setEquipment(dealData.dealEquipment);
    }
  }, [dealData.dealEquipment])


  useEffect(() => {
    if (dealID && rest) {
      const getDeal = async () => {
        getDealById(dealID);
      }
      getDeal();
    }
  }, [dealID, getDealById, rest])

  useEffect(() => {
    const getList = async () => {
      if (rest) {
        await getDealList();
      }
    }
    getList();
  }, [rest]);

  // try {
  //   let dealParticipantArray = dealData.dealParticipants;
  //   var sortedUserList = (dealParticipantArray || [])?.filter((item) => item.participant._id !== loggedInUserData.user._id);
  // } catch (err) {
  //   console.log(err)
  // }
  useEffect(() => {
    const getList = async () => {
      const otherDealMembers = (dealData?.dealParticipants || [])?.filter((item) => item?.participant?._id != loggedInUserData?.user?._id);
      setSortedUserList(otherDealMembers);
    }
    getList();
  }, [dealData, setSortedUserList, loggedInUserData]);

  return (
    <>
      <div>
        <div style={{ display: "flex" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.dealcommunication}>
                <b className={classes.typography}>DEAL COMMUNICATION</b>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.messageChat}>
          <DealMessageChat users={sortedUserList} equipment={equipment} />
          <br />
          {/* <ChatPage /> */}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  restClient: state.restClient,
  dealList: state.deal.dealList,
  dealData: state.deal && state.deal.dealData,
  loggedInUserData: state.user && state.user.loggedInUserData
});

export default connect(mapStateToProps, { getDealList, getDealById, setLoggedInUserData })(DealSome)