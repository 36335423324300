// src/deleteImage.js
import { BlobServiceClient } from '@azure/storage-blob';

async function deleteImage(containerName, blobName) {
    const sasToken = "sv=2022-11-02&ss=bfqt&srt=co&sp=rwdlacupiytfx&se=2024-06-22T15:45:29Z&st=2024-06-06T07:45:29Z&spr=https,http&sig=pr7JNodALaXMA%2FY7%2BhT%2FinlZBaLe67%2BiDIn1c0SUGNA%3D";
    const blobServiceClient = new BlobServiceClient(`https://atgdevstore.blob.core.windows.net?${sasToken}`);

    // Get a container client
    const containerClient = blobServiceClient.getContainerClient(containerName);

    // Get a block blob client
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    // Delete the blob
    await blockBlobClient.delete();
    console.log(`Deletion of blob '${blobName}' completed`);
}

export default deleteImage;
