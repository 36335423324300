// src/App.js
import React, { useState } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button, Box, Typography, Card } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '../../App.css';
import { pdfjs } from 'react-pdf';
import { read, utils } from 'xlsx';
import DownloadIcon from '@mui/icons-material/Download';
import deleteImage from "./deleteBlob";
import doesBlobExist from "./doesBlobExist";
import DeleteIcon from '@mui/icons-material/Delete';
import { FileCard, FullScreen, ImagePreview, VideoPreview } from "@files-ui/react";
// import { editTransactionBlobs } from "../../reducers/DealCommunicationReducer";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const fileTypes = {
    IMAGE: 'image',
    PDF: 'pdf',
    EXCEL: 'excel',
    WORD: 'word',
    POWERPOINT: 'powerpoint',
    VIDEO: 'video',
    AUDIO: 'audio',
    TEXT: 'text',
    CSV: 'csv',
    OTHER: 'other',
};

const determineFileType = (file) => {
    const extension = (/[.]/.exec(file)) && /[^.]+$/.exec(file)[0];
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg'].includes(extension)) {
        return fileTypes.IMAGE;
    } else if (extension === 'pdf') {
        return fileTypes.PDF;
    } else if (['xls', 'xlsx'].includes(extension)) {
        return fileTypes.EXCEL;
    } else if (['csv'].includes(extension)) {
        return fileTypes.CSV;
    } else if (['doc', 'docx'].includes(extension)) {
        return fileTypes.WORD;
    } else if (['ppt', 'pptx'].includes(extension)) {
        return fileTypes.POWERPOINT;
    } else if (['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'].includes(extension)) {
        return fileTypes.VIDEO;
    } else if (['mp3', 'wav', 'aac', 'flac', 'ogg'].includes(extension)) {
        return fileTypes.AUDIO;
    } else if (['txt', 'md', 'rtf'].includes(extension)) {
        return fileTypes.TEXT;
    } else {
        return fileTypes.OTHER;
    }
};

// const ImageContainer = ({ imageUrl, fileName, dealId }) => {
//     const [exists, setExists] = useState(false);

//     (async () => {
//         const containerName = "deal";
//         const blobName = `${dealId}/${fileName}`;
//         const res = await doesBlobExist(containerName, blobName);
//         setExists(res);
//     })();
//     return (
//         <>
//             {exists &&
//                 <Box
//                     position="relative"
//                     width={150}
//                     height={150}
//                     display="flex"
//                     border="1px solid black"
//                     alignItems="center"
//                     justifyContent="center"
//                 >
//                     <img src={imageUrl} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
//                 </Box>}
//         </>
//     );
// };

// const FileViewer = ({ file, fileName, dealId, handleRemoveFile }) => {
//     const fileType = determineFileType(fileName);
//     // const [excelData, setExcelData] = useState(null);
//     const [fileExists, setFileExists] = useState(null);

//     (async () => {
//         const containerName = "deal";
//         const blobName = `${dealId}/${fileName}`;
//         const res = await doesBlobExist(containerName, blobName);
//         setFileExists(res);
//     })();
//     switch (fileType) {
//         case fileTypes.IMAGE:
//             return <ImageContainer imageUrl={file} fileName={fileName} dealId={dealId} />;;
//         case fileTypes.PDF:
//         case fileTypes.EXCEL:
//         case fileTypes.CSV:
//         case fileTypes.WORD:
//         case fileTypes.POWERPOINT:
//         case fileTypes.TEXT:
//             return (
//                 <>
//                     {fileExists &&
//                         <Box display="flex" alignItems="center" justifyContent="space-between" padding={2} component={Card}>
//                             <Typography variant="body1" noWrap>
//                                 {fileName}
//                             </Typography>
//                             <Box display="flex" alignItems="center">
//                                 <IconButton href={file} download>
//                                     <DownloadIcon />
//                                 </IconButton>
//                                 <IconButton onClick={() => handleRemoveFile()}>
//                                     <CancelIcon />
//                                 </IconButton>
//                             </Box>
//                         </Box>
//                     }
//                 </>
//             );
//         case fileTypes.VIDEO:
//             return <video controls src={file} style={{ width: '100%' }} />;
//         case fileTypes.AUDIO:
//             return <audio controls src={file} />;
//         case fileTypes.OTHER:
//         default:
//             return <div>Unsupported file type</div>;
//     }
// };

// const ImageWithCancelIcon = ({ files, fileUrl, fileName, dealId, editTransactionBlobs }) => {
//     const [open, setOpen] = useState(false);
//     const [file, setFile] = useState(null);
//     const [fileType, setFileType] = useState(null);

//     const handleClickOpen = (file) => {
//         setFile(file);
//         setFileType(determineFileType(file));
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     const handleRemoveFile = async () => {
//         const decision = window.confirm("Are u sure you need to delete the file permanently?");
//         const res = decision && await deleteImage('deal', `${dealId}/${fileName}`);
//         let transactioncommunication = {};
//         transactioncommunication.blobPath = files?.blobPath.filter((file) => file === fileName);
//         const dealCommunicationId = dealId;
//         await editTransactionBlobs(files?.id, transactioncommunication, dealCommunicationId);
//         setOpen(false);
//     };

//     const downloadUrl = fileUrl;

//     return (
//         <div className="image-container">
//             <div
//                 className="file-thumbnail"
//                 onClick={() => determineFileType(fileName) === fileTypes.IMAGE && handleClickOpen(fileUrl)}
//             >
//                 <FileViewer file={fileUrl} fileName={fileName} dealId={dealId} handleRemoveFile={handleRemoveFile} />
//             </div>
//             <Dialog
//                 open={open}
//                 onClose={handleClose}
//                 maxWidth="xs"
//                 fullWidth
//             >
//                 <DialogTitle>
//                     File Preview
//                 </DialogTitle>
//                 <DialogContent dividers>
//                     {fileType === fileTypes.IMAGE && (
//                         <img src={file} alt="Preview" style={{ width: '100%' }} />
//                     )}
//                     {fileType === fileTypes.PDF && (
//                         <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>
//                             <Viewer fileUrl={file} />
//                         </Worker>
//                     )}
//                     {fileType === fileTypes.OTHER && (
//                         <iframe src={file} title="file-preview" style={{ width: '100%', height: '80vh' }} />
//                     )}
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleClose} color="primary">
//                         Close
//                     </Button>
//                     <Button href={downloadUrl} download color="primary">
//                         Download
//                     </Button>
//                     <Button startIcon={<DeleteIcon />} onClick={handleRemoveFile}>
//                         Delete
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </div>
//     );
// };
const FileCardsDisplay = ({ files, dealId, editTransactionBlobs, allFiles, transactionId }) => {
    const [imgSrc, setImgSrc] = React.useState(undefined);
    const [videoSrc, setVideoSrc] = React.useState(undefined);

    const handleDelete = (fileType, fileName) => {
        console.log(`Delete ${fileName} from ${fileType}`);
        // Implement the delete functionality here
        const decision = window.confirm("Are u sure you need to delete the file permanently?");
        const res = decision && deleteImage('deal', `${dealId}/${fileName}`);
        let transactioncommunication = {};
        transactioncommunication.blobPath = allFiles.filter((file) => file === fileName);
        editTransactionBlobs(transactionId, transactioncommunication);
    };

    const handleSee = (imageSource) => {
        console.log("watch image:", imageSource);
        setImgSrc(imageSource);
    };

    const handleWatch = (videoSource) => {
        console.log("watch video:", videoSource);
        setVideoSrc(videoSource);
    };

    const handleDownload = (fileId, downloadUrl) => {
        console.log("download file id", fileId);
        console.log("download url", downloadUrl);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileId; // or any other filename you wish to use
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            {Object.entries(files).map(([fileType, fileArray]) =>
                fileArray.map((fileUrl) => {
                    const fileName = fileUrl.split('/').pop();
                    return (
                        <FileCard
                            key={fileName}
                            id={fileName}
                            name={fileName}
                            onSee={() => handleSee(fileUrl)}
                            onWatch={() => handleWatch(fileUrl)}
                            onDownload={() => handleDownload(fileName, fileUrl)}
                        // Add other props as needed
                        />
                    );
                })
            )}
            <FullScreen open={imgSrc !== undefined} onClose={() => setImgSrc(undefined)}>
                <ImagePreview src={imgSrc} />
            </FullScreen>
            <FullScreen open={videoSrc !== undefined} onClose={() => setVideoSrc(undefined)}>
                <VideoPreview src={videoSrc} autoPlay controls />
            </FullScreen>
        </>
    );
};

function ImageDisplay({ files, dealId, editTransactionBlobs }) {
    const fileName = files?.blobPath;//array of strings
    const fileGroups = fileName.reduce((acc, file) => {
        const extension = file.split('.').pop();
        if (!acc[extension]) {
            acc[extension] = [];
        }
        acc[extension].push(file);
        return acc;
    }, {});
    const baseUrl = process.env.REACT_APP_BLOB_URL;

    const parsedFiles = Object.keys(fileGroups).reduce((acc, key) => {
        acc[key] = fileGroups[key].map(url => `${baseUrl}deal/${dealId}/` + url);
        return acc;
    }, {});
    const fileUrl = files?.blobPath.length > 0 && files?.blobPath.map((url) => `${process.env.REACT_APP_BLOB_URL}deal/${dealId}/` + url);
    return (
        <div style={{ padding: 20 }}>
            {/* {fileName.length > 0
                &&
                <ImageWithCancelIcon files={files} fileUrls={parsedFiles} fileName={fileName} dealId={dealId} editTransactionBlobs={editTransactionBlobs} />
            } */}
            <FileCardsDisplay files={parsedFiles} editTransactionBlobs={editTransactionBlobs} dealId={dealId} allFiles={files?.blobPath} transactionId={files?.id} />
        </div>
    );
}

export default ImageDisplay;