import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    submitDealRegisterForm,
    getDealList,
    dealInvitationAccept,
    dealInvitationReject,
    deleteDeal
} from '../../../reducers/DealReducer';
import { submitMakeOfferMessage } from "../../../reducers/DealCommunicationReducer";
import {
    Card,
    Typography,
    IconButton,
    Grid,
    Box,
    Tooltip,
    FormGroup,
    Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import { setLoggedInUserData } from '../../../reducers/UserReducer';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SearchBar from '../../../utils/Search';
import EquipmentTable from "./equipmentGrid"
import { DataGridPro } from '@mui/x-data-grid-pro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ThemeProvider, createTheme } from '@mui/material';
import DealSome from "../DealListGrid/dealCloseOut";
import DealMessageChat from "../DealListGrid/dealChat";
import Badge from '@mui/material/Badge';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Stack } from '@mui/system';
import CustomizedTooltips from "../../DealList/toolTip"

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        color: '#0737AB', // Color when unchecked
        '&.Mui-checked': {
            color: '#0737AB', // Color when checked
            '& + .MuiSwitch-track': {
                backgroundColor: '#0737AB', // Track color when checked
            },
        },
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#0737AB', // Track color when unchecked
    },
}));


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        margin: '20px'
    },
    tableTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0 1.5rem'
    },
    actions: {
        color: "rgb(17, 82, 147)"
    },
    fullWidth: {
        marginLeft: "20px"
    },
    link: {
        color: "rgb(105, 105, 105)"
    },
    childDiv: {
        paddingTop: "10px",
        textAlign: "end",
        paddingLeft: "9rem"
    },
    tableheader: {
        marginTop: "-3rem"
    },
    cardStyle: {
        height: "690px",
        width: "1314px",
        marginLeft: "-5rem",
        marginBottom: "-1rem",
        // "@media (max-width: 768px)": {
        //     width: "100%",
        //     marginLeft: "5rem",
        //     marginBottom: "1rem", 
        // },
    },
    formgroup: {
        marginLeft: "5rem"
    },
    toggleRadio: {
        display: "inline-block",
        marginLeft: "-10rem",
        // "@media (max-width: 768px)": {
        //     width: "100%",
        //     marginLeft: "10rem",
        //     // marginBottom: "1rem", 
        // }
    },
    rejectText: {
        backgroundColor: "#EC7063",
    },
    noScroll: {
        '& .MuiDataGrid-scrollbar': {
            overflowY: 'hidden !important',
            // color: "red"
        },
        '& .MuiDataGrid-main': {
            // height: "fixed",
        },
        '& .MuiBox-root': {
            // height: "fixed",
            position: "absolute"
        }
    },
    DataGridTitleStyle: {
        backgroundColor: "white",
        paddingLeft: "40px",
        width: "1310px",
        marginLeft: "-78px",
        marginTop: "18px",
        // "@media (max-width: 768px)": {
        //     width: "100%",
        //     marginLeft: "78px",
        // },
    }
    // tabs: {
    //   marginTop: "10px"
    // },
    // tab1: {
    //   width: "100px",
    //   backgroundColor: "#3CB371",
    //   height: "45px",
    //   '&:hover': {
    //     backgroundColor: '#76ff03',
    //   },
    // },
    // tab2: {
    //   width: "100px",
    //   backgroundColor: "#ff9100",
    //   height: "45px",
    //   '&:hover': {
    //     backgroundColor: '#FFB533',
    //   },
    // }
}));

const scrollToTop = () => {
    window.scrollTo({ top: 133, behavior: 'smooth' });
};

const DealList = props => {
    const { dealList = [], totalCount, getDealList, loggedInUserData } = props;
    const history = useHistory();
    const classes = useStyles();
    const [filterChecked, setFilterChecked] = useState((loggedInUserData.user.roleSystem === 'admin') ? false : true);
    const [pageState, setPageState] = useState({
        page: 1,
        limit: 100,
        sort: null,
        searchData: '',
        participant: loggedInUserData.user.roleSystem === 'admin' ? '' : loggedInUserData.user._id
    });

    // console.log("pageState",pageState);
    const gridRef = useRef(null);

    const [search, setSearch] = React.useState('');
    const [filteredDealList, setFilteredDealList] = useState([]);
    const [rowCountState, setRowCountState] = React.useState(totalCount);
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState(
        [],
    );
    const [originalDealList, setOriginalDealList] = useState([]);
    // console.log("dealList", dealList);
    //    console.log("filterChecked",filterChecked);

    const transactionId = "6231b09f5292800b4074918e";

    // useEffect(() => {
    //     // Set filteredUserList to userList initially
    //     if (dealList.length > 0) {
    //         setFilteredDealList(dealList);
    //     }
    // }, [dealList]);

    useEffect(() => {
        // Filter user list when search state changes
        if (dealList?.length > 0) {
            const filteredList = dealList.filter(user =>
                (user.dealName && user.dealName.toLowerCase().includes(search.toLowerCase())) ||
                (user.dealDescription && user.dealDescription.toLowerCase().includes(search.toLowerCase())) ||
                (user.dealStartDate && user.dealStartDate.toLowerCase().includes(search.toLowerCase())) ||
                (user.creatorCompany && user.creatorCompany.toLowerCase().includes(search.toLowerCase()))
            );
            // console.log("filteredList-1",filteredList);
            setFilteredDealList(filteredList);
            if (filteredList.length > 0) {
                setRowCountState(filteredList?.length)
            }
        }
    }, [search, dealList]);

    useEffect(() => {
        const getList = async () => {
            if (pageState, loggedInUserData) {
                const isInvited = true;
                await getDealList(pageState, loggedInUserData, isInvited);
            } else {
                await getDealList(pageState);
            }
        }
        getList();
    }, [getDealList, loggedInUserData, pageState]);


    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            totalCount !== undefined ? totalCount : prevRowCountState
        );
    }, [totalCount, setRowCountState]);


    const handleActiveDealChange = (event) => {
        setFilterChecked(event.target.checked);
        if (event.target.checked) {
            setPageState((old) => ({ ...old, participant: loggedInUserData.user._id }));
        } else {
            setPageState((old) => ({ ...old, participant: '' }));
        }
    }

    const handleDealEdit = (event, deal) => {
        history.push(`/create/deals/create?action=edit&id=${deal.id}`);
    }

    const handleDealDelete = (event, deal) => {
        props.deleteDeal(deal.id, dealList, totalCount, loggedInUserData.user._id);
    }

    const toggleToMyDeals = () => {
        setFilterChecked(true);
        setPageState((old) => ({ ...old, participant: loggedInUserData.user._id }));
    };

    const handleAccept = async (event, deal) => {
        const dealId = deal.id;
        const dealInvitesId = (deal.dealInvites || []).filter(
            (un) => un.invitedUser === loggedInUserData.user._id
        );
        const filterInvites = dealInvitesId.map((un) => un._id);
        const result = filterInvites.reduce((acc, cur) => {
            acc = cur;
            return acc;
        }, {});
        const payload = {
            isAccepted: true,
            dealInvites: {
                "dealInvites.$.inviteStatus": "Joined",
            },
            dealParticipants: {
                dealParticipants: [
                    {
                        startDate: new Date().toISOString(),
                        endDate: new Date(
                            new Date().setDate(new Date().getDate() + 10)
                        ).toISOString(),
                        participant: loggedInUserData.user._id,
                    },
                ],
            },
        };

        const transactioncommunication = {
            transactioncommunication: {
                dealId: dealId,
                transactionId: transactionId,
                message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} added to this deal successfully`,
                transactionEquipment: deal.dealEquipment,
                from: loggedInUserData.user._id,
                to: deal.dealParticipants.map((p) => { return p.participant._id }),
                notificationType: "General Message",
                notes: "notes",
                askPrice: "",
                offerPrice: "",
                priceUnit: "USD",
                addedBy: loggedInUserData.user._id
            },
            dealname: deal.dealName,
            dealparticipantemails: deal.dealParticipants.map((p) => { return p.participant.email })
        }
        await props.dealInvitationAccept(dealId, result, payload, loggedInUserData.user._id);
        await props.submitMakeOfferMessage(transactioncommunication);
        await getDealList({ ...pageState, participant: loggedInUserData.user._id }, loggedInUserData, true);
        setFilterChecked(true);
    };

    const handleReject = async (event, deal) => {
        const dealId = deal.id;
        const dealInvitesId = (deal.dealInvites || []).filter(
            (un) => un.invitedUser === loggedInUserData.user._id
        );
        const filterInvites = dealInvitesId.map((un) => un._id);
        const rejectResult = filterInvites.reduce((acc, cur) => {
            acc = cur;
            return acc;
        }, {});
        const payload = {
            isAccepted: false,
            dealInvites: {
                "dealInvites.$.inviteStatus": "Left",
            },
            dealParticipants: {
                // dealParticipants: [
                //   {
                //     startDate: "",
                //     endDate: "",
                //     participant: "",
                //   },
                // ],
            },
        };

        const transactioncommunication = {
            transactioncommunication: {
                dealId: dealId,
                transactionId: transactionId,
                message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} was rejected this deal`,
                transactionEquipment: deal.dealEquipment,
                from: loggedInUserData.user._id,
                to: deal.dealParticipants.map((p) => { return p.participant._id }),
                notificationType: "General Message",
                notes: "notes",
                askPrice: "",
                offerPrice: "",
                priceUnit: "USD",
                addedBy: loggedInUserData.user._id
            },
            dealname: deal.dealName,
            dealparticipantemails: deal.dealParticipants.map((p) => { return p.participant.email })
        }
        await props.dealInvitationReject(dealId, rejectResult, payload, loggedInUserData.user._id);
        await props.submitMakeOfferMessage(transactioncommunication);
        await getDealList({ ...pageState, participant: loggedInUserData.user._id }, loggedInUserData, true);
        setFilterChecked(true);
    };

    // const handleDetailPanelExpandedRowIdsChange = (newExpandedRowIds) => {
    //     if (newExpandedRowIds.length > 0) {
    //         setDetailPanelExpandedRowIds(newExpandedRowIds.length > 1 ? [newExpandedRowIds[newExpandedRowIds.length - 1]] : newExpandedRowIds);
    //         if (newExpandedRowIds.length > 0) {
    //             scrollToTop(); // Scroll to top when a row is expanded
    //         }

    //         // Save the original deal list if not already saved
    //         if (originalDealList.length === 0) {
    //             setOriginalDealList(filteredDealList);
    //         }

    //         // Move the expanded deal to the top of the list
    //         const expandedDeal = filteredDealList.find(deal => deal.id === newExpandedRowIds[0]);
    //         setFilteredDealList([expandedDeal, ...filteredDealList.filter(deal => deal.id !== newExpandedRowIds[0])]);

    //         // Ensure the grid is available before attempting to scroll
    //         setTimeout(() => {
    //             if (gridRef.current) {
    //                 const gridElement = gridRef.current;
    //                 const expandedRowElement = gridElement.querySelector(`[data-id="${newExpandedRowIds[0]}"]`);

    //                 if (expandedRowElement) {
    //                     expandedRowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    //                 }
    //             }
    //         }, 0);
    //     } else {
    //         setDetailPanelExpandedRowIds([]);
    //         // Revert to the original deal list
    //         setFilteredDealList(originalDealList);
    //         setOriginalDealList([]);
    //     }
    // };

    // const renderRow = (params) => {
    //     const deal = params.row;
    //     const invitedUser = (deal.dealInvites || []).find(
    //         (invite) => invite.invitedUser === loggedInUserData.user._id
    //     );
    //     const inviteStatus = invitedUser ? invitedUser.inviteStatus : null;

    //     if (inviteStatus === 'Joined' || inviteStatus === 'Accepted') {
    //         return (
    //             <React.Fragment>
    //                 <Typography>{deal.dealName}</Typography>
    //                 <Typography>{deal.dealDescription}</Typography>
    //             </React.Fragment>
    //         );
    //     }

    //     return (
    //         <Grid container>
    //             <Grid item xs={10}>
    //                 <Typography>{deal.dealName}</Typography>
    //                 <Typography>{deal.dealDescription}</Typography>
    //             </Grid>
    //             <Grid item xs={2}>
    //                 <Tooltip title="Accept Invite">
    //                     <IconButton onClick={(event) => handleAccept(event, deal)}>
    //                         <AddBoxIcon color="primary" />
    //                     </IconButton>
    //                 </Tooltip>
    //                 <Tooltip title="Reject Invite">
    //                     <IconButton onClick={(event) => handleReject(event, deal)}>
    //                         <DeleteIcon color="error" />
    //                     </IconButton>
    //                 </Tooltip>
    //             </Grid>
    //         </Grid>
    //     );
    // };

    const theme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        "& .MuiDataGrid-container--top [role=row]": {
                            background: '#3E5272',
                            color: "white"
                        },
                    },
                    columnSeparator: {
                        visibility: "hidden",
                    },
                    sortIcon: {
                        color: "#ee9907",
                    }
                },
            },
        },
    });


    const columns = [
        // {
        //   field: "unreadMessages",
        //   headerName: "Unread Messages",
        //   width: 160,
        //   renderCell: (params) => (
        //     <Link to={`/deal/grid?dealId=${params.id}`} style={{ textDecoration: "none", marginLeft: "10%", pointerEvents: (loggedInUserData.user.roleSystem === "admin") ? "none" : "auto" }} className={classes.link} target="_self">
        //       <Badge badgeContent={(Array.isArray(params.row.UnreadMessages) ? params.row.UnreadMessages.filter((row) => row?.userId === loggedInUserData.user._id).map((x) => x.totalUnReadMessages)[0] : 0)} color="primary">
        //         <ChatBubbleOutlineIcon color="action" />
        //       </Badge>
        //     </Link>
        //   ),
        // },
        {
            field: "dealName",
            headerName: "Deal",
            width: 300,
            renderCell: (params) => (
                <>
                    <Stack
                        direction="row"
                        // spacing={2}
                        justifyContent="start"
                        alignItems="start"
                        marginTop="-15px"
                        fontFamily="sans-serif"
                        fontSize="smaller"
                    // fontWeight= "400"
                    >
                        {/* <Badge badgeContent={(Array.isArray(params?.row?.UnreadMessages) ? params?.row?.UnreadMessages.filter((row) => row?.userId === loggedInUserData?.user?._id).map((x) => x.totalUnReadMessages)[0] : 0)} color="primary">
                            <ChatBubbleOutlineIcon color="action" />
                        </Badge> */}
                        {/* <Link to={`/deal/grid?dealId=${params.id}`} style={{ textDecoration: "none", pointerEvents: (loggedInUserData.user.roleSystem === "admin") ? "none" : "auto" }} className={classes.link} target="_self"> */}
                            <CustomizedTooltips data={params?.row} />
                        {/* </Link> */}
                    </Stack>
                </>
            ),
        },
        {
            field: "payment",
            headerName: "Deal Status",
            type: "string",
            width: 150,
        },
        {
            field: "dealDescription",
            headerName: "Deal Description",
            type: "string",
            width: 200,
        },
        {
            field: "dealStartDate",
            headerName: "Deal Start Date",
            type: "string",
            width: 220,
        },
        {
            field: "creatorCompany",
            headerName: "Company",
            width: 220,
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 150,
            renderCell: (params) => {
                const dealInvites = params.row.dealInvites || [];
                const inviteSent = dealInvites.some(un => un.invitedUser === loggedInUserData.user._id && un.inviteStatus === "Sent");
                const inviteJoined = dealInvites.some(un => un.invitedUser === loggedInUserData.user._id && un.inviteStatus === "Joined");
                // const isAdmin = loggedInUserData.user.roleSystem === 'admin';
                const logged = params?.row?.dealParticipants?.some(un => un.participant?._id === loggedInUserData?.user?._id);
                const isLoggedInUser = dealInvites?.createtorid !== loggedInUserData?.user?._id;

                return (
                    <>
                        {inviteSent && isLoggedInUser ?
                            <div>
                                <button
                                    onClick={(event) => handleAccept(event, params.row)}
                                    className={classes.accept}
                                >
                                    Accept
                                </button>
                                &nbsp;&nbsp;
                                <button
                                    onClick={(event) => handleReject(event, params.row)}
                                    className={classes.reject}
                                >
                                    Reject
                                </button>
                            </div>
                            :
                            <div>
                                {logged || (loggedInUserData.user.roleSystem === 'admin') ?
                                    <>
                                        {filterChecked ?
                                            <Tooltip title="Edit Deal">
                                                <IconButton
                                                    sx={{ color: "#3E5272" }}
                                                    onClick={(event) => {
                                                        handleDealEdit(event, params.row);
                                                    }}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            : null}
                                        {logged || (loggedInUserData.user.roleSystem === 'admin') ?
                                            <Tooltip title="Delete Deal">
                                                <IconButton
                                                    sx={{ color: "#3E5272" }}
                                                    onClick={(event) => {
                                                        window.confirm(
                                                            "Are you sure want to delete " +
                                                            params.row.dealName +
                                                            " ?"
                                                        ) && handleDealDelete(event, params.row);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            null}
                                    </>
                                    :
                                    (
                                        <p className={classes.rejectText}>Rejected</p>
                                    )}

                            </div>
                        }
                    </>
                );
            },
        },
    ];

    function DataGridTitle() {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant="h5"
                            sx={{
                                // margin: "6px",
                                fontWeight: "bolder",
                                fontSize: "1.2rem",
                                color: "#696969",
                                lineHeight: 1.6,
                            }}>
                            {filterChecked ? "MY DEALS" : "ALL DEALS"}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={1}>
                            <Grid item lg={6} xs={6}>
                                <div className={classes.search}>
                                    {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                                </div>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <div className={classes.childDiv}>
                                    <Tooltip title="Create New Deal">
                                        <Link to="/create/deals">
                                            <IconButton sx={{ color: "#3E5272" }}>
                                                <AddBoxIcon />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    const onFilterChange = (event) => {
        setSearch(event.target.value);

    };

    const handleClearText = () => {
        setSearch('');
        setPageState((old) => ({ ...old, searchData: '' }));
    };


    const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds) => {
        setDetailPanelExpandedRowIds(
            newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
        );
    }, []);


    const getDetailPanelContent = React.useCallback(
        ({ row }) =>
            <>
                <EquipmentTable dealData={row} loggedInUserData={loggedInUserData} dealID={row?.id} />
                <DealSome dealData={row} loggedInUserData={loggedInUserData} dealID={row?.id} />
                {/* <DealMessageChat dealData={row} loggedInUserData={loggedInUserData} dealID={row?.id} /> */}
            </>,
        [loggedInUserData],
    );

    const getDetailPanelHeight = React.useCallback(() => 700, []);

    return (
        <div className={classes.tableheader}>
            <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                    <div className={classes.toggleRadio}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "start",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <FormGroup row className={classes.formgroup}>
                                    <Typography variant="h6" color="main">ALL DEALS</Typography>
                                    <CustomSwitch checked={filterChecked} onChange={handleActiveDealChange} name="checkedC" disabled={(loggedInUserData.user.roleSystem === 'admin')} />
                                    <Typography variant="h6" color="main">MY DEALS</Typography>
                                </FormGroup>
                            </div>
                        </Box>

                    </div>
                </Grid>
                <Grid item lg={6} xs={12}>
                    <SearchBar
                        searchText={search}
                        filterChange={onFilterChange}
                        onClickFunc={handleClearText}
                    />
                </Grid>
            </Grid>
            {/* <Grid item lg={12} xs={6}> */}
            <div className={classes.DataGridTitleStyle}>{DataGridTitle()}</div>
            <Card className={classes.cardStyle}>
                <ThemeProvider theme={theme}>
                    <DataGridPro
                        className={classes.noScroll}
                        // autoWidth
                        slots={{
                            detailPanelExpandIcon: ChevronRightIcon,
                            detailPanelCollapseIcon: ExpandMoreIcon,
                            // toolbar: DataGridTitle,
                        }}
                        rowCount={filteredDealList.length >= 0 ? filteredDealList.length : rowCountState}
                        rows={filteredDealList || []}
                        columns={columns}
                        // components={{
                        //     Row: renderRow,
                        // }}
                        // getRowId={(row) => row.id}
                        getDetailPanelContent={getDetailPanelContent}
                        getDetailPanelHeight={getDetailPanelHeight}
                        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                        componentsProps={{
                            row: { onClick: (params) => handleDetailPanelExpandedRowIdsChange([params.row.id]) },
                            cell: {
                                iconComponents: {
                                    expandedIcon: <ExpandMoreIcon />,
                                    collapsedIcon: <ChevronRightIcon />,
                                },
                            },
                        }}
                    />
                </ThemeProvider>
            </Card>
            {/* {expandedRowId?.id && <EquipmentTable dealData={expandedRowId} loggedInUserData={loggedInUserData} dealID={expandedRowId?.id} />} */}
            {/* </Grid>
            </Grid> */}
        </div>
    )
}
const mapStateToProps = state => ({
    dealList: state.deal && state.deal.dealList.dealList,
    loggedInUserData: state.user && state.user.loggedInUserData,
    totalCount: state.deal && state.deal.dealList.total,
    limit: state.deal && state.deal.dealList.limit,
});
export default connect(mapStateToProps, { submitDealRegisterForm, getDealList, deleteDeal, setLoggedInUserData, dealInvitationAccept, dealInvitationReject, submitMakeOfferMessage })(DealList);
