import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Grid,
  Container,
  Card,
  CardContent,
  FormControl,
  Button,
  TextField,
  Box,
} from "@mui/material";
import validate from "validate.js";
import { verifyUser } from "../../reducers/AccountReducer";
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from "../../styles/appStyles";

import { FormControlWrapper, FormButtonWrapper } from "../../styles/formStyles";

const ForgetPassWord = (props) => {
  const values = {
    email: "",
  };
  const [formState, setFormState] = useState({
    isValid: false,
    values: values,
    touched: {},
    errors: {},
  });
  const schema = {
    email: {
      presence: {
        allowEmpty: false,
        message: "Primary contact email is required",
      },
      email: true,
      length: {
        maximum: 64,
      },
    },
  };

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  const handleChange = (event) => {
    // event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  useEffect(
    (schema) => {
      const errors = validate(formState.values, schema, {
        fullMessages: false,
      });

      setFormState((prevFormState) => ({
        ...prevFormState,
        isValid: !errors,
        errors: errors || {},
      }));
    },
    [formState.values]
  );

  const checkErrors = () => {
    let isErrorExist = false;
    let touched = {};
    for (let key in formState.values) {
      if (formState.values.hasOwnProperty(key)) {
        touched = {
          ...touched,
          [key]: true,
        };
      }
    }

    const errors = validate(formState.values, schema, { fullMessages: false });
    if (errors) {
      isErrorExist = true;
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
      touched: touched,
    }));

    return isErrorExist;
  };
  const submitForm = (event) => {
    event.preventDefault();
    if (!checkErrors()) {
      const { values } = formState;
      const payload = {
        email: values.email,
      };
      props.verifyUser("sendResetPwd", payload);
    }
  };

  return (
    <AppContent>
      <AppContentInner>
        <AppWrapper>
          <AppMain>
            <Container maxWidth="sm">
              <Grid container spacing={5}>
                <Grid item xs={12} lg={12}>
                  <Card>
                    <Header>
                      <HeaderText>
                        <h5>Forgot your Password</h5>
                      </HeaderText>
                    </Header>
                    <CardContent>
                      <form onSubmit={submitForm}>
                        <FormControlWrapper>
                          <FormControl className="w-100">
                            <TextField
                              error={hasError("email")}
                              helperText={
                                hasError("email") ? (
                                  formState.errors.email[0]
                                ) : (
                                  <span>
                                    Enter your email address to reset password
                                  </span>
                                )
                              }
                              variant="outlined"
                              label="Email Address *"
                              fullWidth
                              placeholder="Email Address"
                              data-test-id="Forgot-Password-Email"
                              type="email"
                              name="email"
                              value={formState.values.email}
                              onChange={handleChange}
                            />
                          </FormControl>
                        </FormControlWrapper>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <FormButtonWrapper>
                            <Link to="/login" className="linkStyle">
                              <Button
                                type="submit"
                                color="secondary"
                                data-test-id="View-User-GoBack"
                                variant="contained"
                                size="large"
                                className="my-2"
                              >
                                Cancel
                              </Button>
                            </Link>
                          </FormButtonWrapper>
                          <FormButtonWrapper>
                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              size="large"
                              data-test-id="Forgot-Password-Submit"
                              className="my-2"
                            >
                              Send
                            </Button>
                          </FormButtonWrapper>
                        </Box>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </AppMain>
        </AppWrapper>
      </AppContentInner>
    </AppContent>
  );
};

export default connect(null, { verifyUser })(ForgetPassWord);
