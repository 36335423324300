import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { HeaderText } from '../../styles/appStyles';
import { schema, initialFormValues, getPayload, userSelctableRoles } from './utils';
import { submitRegisterForm } from '../../reducers/UserReducer';
import validate from 'validate.js';
import {
    FormControlWrapper,
    FormButtonWrapper
} from '../../styles/formStyles';

import {
    Grid,
    Container,
    Card,
    CardContent,
    Button,
    Select,
    InputLabel,
    FormControl,
    FormHelperText,
    TextField,
    Typography,
    MenuItem,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Checkbox
} from '@mui/material';

// const useStyles = makeStyles(theme => ({
//     root: {
//         '& .MuiButtonBase-root': {
//             margin: theme.spacing(2),
//         },
//         '& .MuiContainer-root': {
//             //marginTop:""
//         },
//     },
// }));

const RegisterNew = props => {
    // const { restClient: { rest } } = props;
    const [open, setOpen] = React.useState(false);

    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState('');

    const handleCheckboxChange = () => {
        setError('');
        setIsChecked(!isChecked);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleAccept = () => {
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const [formState, setFormState] = useState({
        isValid: false,
        values: initialFormValues,
        touched: {},
        errors: {},
    });


    const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

    const handleChange = (event) => {
        // event.persist();

        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...prevFormState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleStateChange = (field, value) => {
        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [field]: value
            },
            touched: {
                ...prevFormState.touched,
                [field]: true
            }
        }));
    };

    useEffect(() => {
        const errors = validate(formState.values, schema, { fullMessages: false });

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {}
        }));

    }, [formState.values]);

    const handleStartCaseChange = (event) => {
        const { name, value } = event.target;
        handleStateChange(name, StartCase(value));
    }

    const StartCase = (text) => {
        return text
            .split(" ")
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ");
    };

    const checkErrors = () => {
        let isErrorExist = false;
        let touched = {};
        for (let key in formState.values) {
            if (formState.values.hasOwnProperty(key)) {
                touched = {
                    ...touched,
                    [key]: true
                }
            }
        }

        const errors = validate(formState.values, schema, { fullMessages: false });
        if (errors) {
            isErrorExist = true;
        }

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {},
            touched: touched,
        }));

        return isErrorExist;
    }

    const submitForm = event => {
        event.preventDefault();
        if (!checkErrors()) {
            const { values } = formState;
            const payload = getPayload(values);
            if (!isChecked) {
                setError('Please Accept the terms and conditions.');
                return;
              }
            props.submitRegisterForm(payload);
        }
    }

    return (
        <Container maxWidth="sm">
            <Grid container spacing={5}>
                <Grid
                    item
                    xs={12}
                    lg={12}>
                    <Card style={{marginTop: "-50px", marginBottom: "-15px", height: "auto"}}>
                        <HeaderText style={{ marginTop: "10px", fontSize: "x-large", marginBottom: "-10px" }}>
                            Register
                        </HeaderText>
                        <CardContent >
                            <form onSubmit={submitForm}>
                                <FormControlWrapper>
                                    <FormControl>
                                        <TextField
                                            error={hasError('firstName')}
                                            helperText={
                                                hasError('firstName') ? formState.errors.firstName[0] : null
                                            }
                                            variant="outlined"
                                            data-test-id="Register-User-FirstName"
                                            value={formState.values.firstName}
                                            label="First Name"
                                            fullWidth
                                            // size='small'
                                            placeholder="First Name"
                                            type="text"
                                            name="firstName"
                                            onChange={handleStartCaseChange}
                                        />
                                    </FormControl>
                                </FormControlWrapper>
                                <FormControlWrapper>
                                    <FormControl>
                                        <TextField
                                            error={hasError('lastName')}
                                            helperText={
                                                hasError('lastName') ? formState.errors.lastName[0] : null
                                            }
                                            variant="outlined"
                                            data-test-id="Register-User-LastName"
                                            value={formState.values.lastName}
                                            label="Last Name"
                                            fullWidth
                                            // size='small'
                                            placeholder="Last Name"
                                            type="text"
                                            name="lastName"
                                            onChange={handleStartCaseChange}
                                        />
                                    </FormControl>
                                </FormControlWrapper>
                                {/* <FormControlWrapper>
                                    <FormControl>
                                        <TextField
                                            error={hasError('emailAddress')}
                                            helperText={
                                                hasError('emailAddress') ? formState.errors.emailAddress[0] : null
                                            }
                                            variant="outlined"
                                            data-test-id="Register-User-Email"
                                            value={formState.values.emailAddress}
                                            label="Email Address"
                                            fullWidth
                                            placeholder="Email Address"
                                            type="email"
                                            name="emailAddress"
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </FormControlWrapper> */}
                                <FormControlWrapper>
                                    <FormControl>
                                        <TextField
                                            error={hasError('email')}
                                            helperText={
                                                hasError('email') ? formState.errors.email[0] : null
                                            }
                                            variant="outlined"
                                            data-test-id="Register-User-Email"
                                            value={formState.values.email}
                                            label="Email Address"
                                            fullWidth
                                            // size='small'
                                            placeholder="Email Address"
                                            type="email"
                                            name="email"
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </FormControlWrapper>
                                {/* <FormControlWrapper>
                                    <FormControl>
                                        <TextField
                                            error={hasError('password')}
                                            helperText={
                                                hasError('password') ? formState.errors.password[0] : null
                                            }
                                            variant="outlined"
                                            data-test-id="Register-User-Password"
                                            value={formState.values.password || ''}
                                            label="Password"
                                            fullWidth
                                            placeholder="Password"
                                            type="text"
                                            name="password"
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </FormControlWrapper> */}
                                {/* <FormControlWrapper>
                                    <FormControl>
                                        <TextField
                                            error={hasError('confirmPassword')}
                                            helperText={
                                                hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null
                                            }
                                            value={formState.values.confirmPassword || ''}
                                            variant="outlined"
                                            label="Confirm Password"
                                            fullWidth
                                            onChange={handleChange}
                                            data-test-id="Register-User-confirmPassword"
                                            placeholder="Confirm Password"
                                            type="password"
                                            name="confirmPassword"
                                        />
                                    </FormControl>
                                </FormControlWrapper> */}
                                <FormControlWrapper>
                                    <FormControl variant="outlined" className='w-100'
                                    >
                                        <InputLabel id="roleType-select-outlined-label">Role Type</InputLabel>
                                        <Select
                                            error={hasError('roleType')}
                                            labelId="roleType-select-outlined-label"
                                            id="roleType-select-outlined"
                                            label="Role Type"
                                            // size='small'
                                            data-test-id="Register-User-RoleType"
                                            placeholder="Role Type"
                                            value={formState.values.roleType}
                                            onChange={handleChange}
                                            inputProps={{
                                                name: 'roleType',
                                                id: 'roleType',
                                            }}
                                        >
                                            <MenuItem value="0">Select Role Type</MenuItem>
                                            {userSelctableRoles.map((roleType) => <MenuItem value={roleType.name}>{roleType.name}</MenuItem>)}
                                        </Select>
                                        {hasError('roleType') && (
                                            <FormHelperText htmlFor="form-selector" error={hasError('roleType')}>
                                                {formState.errors.roleType[0]}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </FormControlWrapper>
                                <FormControlWrapper>
                                    <FormControl>
                                        <TextField
                                            error={hasError('mobileNumber')}
                                            helperText={
                                                hasError('mobileNumber') ? formState.errors.mobileNumber[0] : null
                                            }
                                            variant="outlined"
                                            data-test-id="Register-User-Phone"
                                            value={formState.values.mobileNumber}
                                            label="Phone"
                                            fullWidth
                                            // size='small'
                                            placeholder="Phone"
                                            type="numaric"
                                            name="mobileNumber"
                                            onChange={handleStartCaseChange}
                                        />
                                    </FormControl>
                                </FormControlWrapper>
                                <FormButtonWrapper className="align-box-row justify-box-row">
                                {error && <p style={{ color: 'red' }}>{error}</p>}
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        className="my-2"
                                        // disabled={!isChecked}
                                        >
                                        Submit
                                    </Button>
                                    {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
                                </FormButtonWrapper>
                                <div style={{ marginTop: '1rem', marginBottom: '1rem', cursor: 'pointer', color: "#FFBF00" }} >
                                    <React.Fragment>
                                        <div variant="outlined" onClick={handleClickOpen}>
                                            <Typography align='center'>
                                                Accept The Terms & Conditions
                                            </Typography>
                                        </div>
                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                        >
                                            <DialogTitle>Terms & Conditions</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    <p>Welcome to  <a href='https://asktakego.qwilr.com/AskTakeGo-Company-Profile-Vu2CJuyET0a2' target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>AskTakeGo Company Profile.</a></p>
                                                    <p>These terms and conditions outline the rules and regulations for the use of <a href='https://asktakego.qwilr.com/AskTakeGo-Company-Profile-Vu2CJuyET0a2' target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>AskTakeGo Company Profile ’s</a> Website.</p>
                                                    <p>By accessing this website we assume you accept these terms and conditions in full.</p>
                                                    <p>Do not continue to use <a href='https://asktakego.qwilr.com/AskTakeGo-Company-Profile-Vu2CJuyET0a2' target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>AskTakeGo Company Profile’s</a> website if you do not accept all of the terms and conditions stated on this page.</p>
                                                    <p>Please read and accept the terms:</p>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={isChecked}
                                                                onChange={handleCheckboxChange}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="I accept the terms and conditions"
                                                    />
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>Close</Button>
                                                <Button
                                                    onClick={handleAccept}
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={!isChecked}
                                                >
                                                    Accept
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </React.Fragment>
                                </div>
                                {/* <FormButtonWrapper className="align-box-row justify-box-row">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        className="my-2"
                                        // disabled={!isChecked}
                                        >
                                        Submit
                                    </Button>
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                </FormButtonWrapper> */}
                                <div style={{ marginTop: '1rem' }}>
                                    <Typography align='center'>  <Link to="/login" style={{ color: '#FFBF00', textDecoration: 'none' }}>Already have an account? - Login</Link>  </Typography>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

const mapStateToProps = state => ({
    restClient: state.restClient,
});

export default connect(mapStateToProps, { submitRegisterForm })(RegisterNew);

