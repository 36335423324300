import React, { useState } from "react";
import { connect } from "react-redux";
import { submitChangePassword } from "../../reducers/AccountReducer";
import { getUserById } from "../../reducers/UserReducer";
// import validate from 'validate.js';
import { makeStyles } from "@mui/styles";
import { Grid, Button, TextField, InputLabel } from "@mui/material";
// import { isValid } from "date-fns";
import {
  Header,
  HeaderText,
} from '../../styles/appStyles';
import {
  FormControlWrapper,
  FormButtonWrapper
} from '../../styles/formStyles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(
  {
    text: {
      // display: "grid",
      // gridTemplateColumns: "10rem 15rem"
      // display: "flex"
    },
    input: {
      width: "250px",
    },
    inputBottom: {
      paddingBottom: "20px",
      margin: "-26px 82px 0px 177px",
    },
    label: {
      // textIndent: "30%"
      paddingTop: "14px",
    },
    changePassword: {
      fontSize: "x-large",
      fontWeight: "800",
      color: "rgb(120, 120, 120)",
    },
    error: {
      color: "red",
    },
  },
  { index: 1 }
);

const UserChangePassword = (props) => {
  const classes = useStyles();
  // const initialValues = {
  //   existingPassword: '',
  //   password: '',
  //   confirmPassword: ''
  // };
  const userId = props.user && props.user.user && props.user.user._id;
  // const password = props.user && props.user && props.user.password;

  const [existingPassword, setExistingPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [error, setError] = useState({
    existingPassword: "",
    password: "",
    confirmPassword: "",
  });

  // const [formState, setFormState] = useState({
  //   isValid: false,
  //   // values: initialValues,
  //   touched: {},
  //   errors: {},
  // });

  // const schema = {
  //   existingPassword: {
  //     presence: { allowEmpty: false, message: 'Existing Password is required' },
  //     format: {
  //       pattern: /^(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
  //       message: function (value, attribute, validatorOptions, attributes, globalOptions) {
  //         return validate.format("Password must be between 8 to 15 characters and contain at least one numeric digit and a special character", {
  //           num: value
  //         });
  //       }
  //     },
  //   },
  //   password: {
  //     presence: { allowEmpty: false, message: 'Password is required' },
  //     format: {
  //       pattern: /^(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
  //       message: function (value, attribute, validatorOptions, attributes, globalOptions) {
  //         return validate.format("Password must be between 8 to 15 characters and contain at least one numeric digit and a special character", {
  //           num: value
  //         });
  //       },
  //     },
  //   },
  //   confirmPassword: {
  //     presence: { allowEmpty: false, message: 'Confirm password is required' },
  //     format: {
  //       pattern: /^(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
  //       message: function (value, attribute, validatorOptions, attributes, globalOptions) {
  //         return validate.format("Password must be between 8 to 15 characters and contain at least one numeric digit and a special character", {
  //           num: value
  //         });
  //       },
  //     },
  //     equality: "password"
  //   }

  // };

  // const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

  // const handleChange = (event) => {
  //   event.persist();

  //   setFormState((prevFormState) => ({
  //     ...prevFormState,
  //     values: {
  //       ...prevFormState.values,
  //       [event.target.name]: event.target.value
  //     },
  //     touched: {
  //       ...prevFormState.touched,
  //       [event.target.name]: true
  //     }
  //   }));
  // };

  // useEffect(() => {
  //   const errors = validate(formState.values, schema, { fullMessages: false });

  //   setFormState((prevFormState) => ({
  //     ...prevFormState,
  //     isValid: !errors,
  //     errors: errors || {}
  //   }));

  // }, [formState.values,schema]);

  // const checkErrors = () => {
  //   let isErrorExist = false;
  //   let touched = {};
  //   for (let key in formState.values) {
  //     if (formState.values.hasOwnProperty(key)) {
  //       touched = {
  //         ...touched,
  //         [key]: true
  //       }
  //     }
  //   }

  //   const errors = validate(formState.values, schema, { fullMessages: false });
  //   if (errors) {
  //     isErrorExist = true;
  //   }

  //   setFormState((prevFormState) => ({
  //     ...prevFormState,
  //     isValid: !errors,
  //     errors: errors || {},
  //     touched: touched,
  //   }));

  //   return isErrorExist;
  // }

  const handleClear = (event) => {
    // event.persist();
    // let { name, value } = event.target;
    setExistingPassword("");
    setPassword("");
    setConfirmPassword("");

    // setFormState((prevFormState) => ({
    //   ...prevFormState,
    //   values: {
    //     formState : ''
    //   },
    // }));
  };

  // const validateInput = e => {
  //   let { name, value } = e.target;
  //   setFormState(prevFormState => {
  //     const stateObj = { ...prevFormState, [name]: "" } })}
  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "existingPassword":
          if (!value) {
            stateObj[name] = "Please enter existingPassword.";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (confirmPassword && value !== confirmPassword) {
            stateObj["confirmPassword"] =
              "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (password && value !== password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const submitForm = (event) => {
    event.preventDefault();
    // if (!checkErrors()) {
    //   const { values } = formState;
    props.submitChangePassword(userId, existingPassword, password);
    setExistingPassword("");
    // }
  };

  return (
    <Grid>
      <Header >
        <HeaderText >Change Password</HeaderText>
      </Header>
      {/* <form onSubmit={submitForm}> */}
      <div className={classes.label}>
        <InputLabel required>Existing Password:</InputLabel>
      </div>
      <FormControlWrapper>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Existing Password"
          data-test-id="Create-Password-existingPassword"
          name="existingPassword"
          id="existingPassword"
          onChange={(e) => setExistingPassword(e.target.value)}
          onBlur={validateInput}
          type="password"
          value={existingPassword}
        />
        {error.existingPassword && (
          <span className={classes.error}>{error.existingPassword}</span>
        )}
      </FormControlWrapper>
      <div className={classes.label}>
        <InputLabel required>New Password:</InputLabel>
      </div>
      <FormControlWrapper>
        <TextField
          value={password}
          variant="outlined"
          fullWidth
          placeholder="New Password"
          data-test-id="Register-User-password"
          name="password"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          onBlur={validateInput}
          type="password"
        />
        {error.password && (
          <span className={classes.error}>{error.password}</span>
        )}
      </FormControlWrapper>
      <div className={classes.label}>
        <InputLabel required>Verify New Password:</InputLabel>
      </div>
      <FormControlWrapper>
        <TextField
          value={confirmPassword}
          variant="outlined"
          fullWidth
          placeholder="Verify Confirm Password"
          data-test-id="Verify-Confirm -Password"
          name="confirmPassword"
          id="confirmPassword"
          onChange={(e) => setConfirmPassword(e.target.value)}
          onBlur={validateInput}
          type="password"
        />
        {error.confirmPassword && (
          <span className={classes.error}>{error.confirmPassword}</span>
        )}
      </FormControlWrapper>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={5}>
            <Grid item>
              <FormButtonWrapper>
                <Link to="/dealpage" className={classes.link}>
                  <Button
                    type="cancel"
                    variant="contained"
                    size="large"
                    sx={{ backgroundColor: "#f50057" }}
                  >
                    Cancel
                  </Button>
                </Link>
              </FormButtonWrapper>
            </Grid>
            <Grid item>
              <FormButtonWrapper>
                <Button
                  onClick={handleClear}
                  type="reset"
                  // color="secondary"
                  variant="contained"
                  size="large"
                  sx={{ backgroundColor: "#f50057" }}
                >
                  Clear
                </Button>
              </FormButtonWrapper>
            </Grid>
            <Grid item>
              <FormButtonWrapper>
                <Button
                  onClick={submitForm}
                  color="primary"
                  variant="contained"
                  size="large"
                  className="Buttons"
                >
                  Submit
                </Button>
              </FormButtonWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </form> */}
    </Grid >
  );
};
const mapStateToProps = (state) => ({
  user: state.user.userData,
});

export default connect(mapStateToProps, { submitChangePassword, getUserById })(
  UserChangePassword
);
