import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  submitEquipmentRegisterForm,
  getMatchedEquipmentList,
  deleteMatchedEquipment,
} from "../../../reducers/EquipmentReducer";
import { getCompanyList } from "../../../reducers/CompanyReducer";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {
  Card,
  Typography,
  IconButton,
  Grid,
  Box,
  Tooltip,
  TextField,
  FormGroup,
  Switch,
  MenuItem
} from "@mui/material";
import {withStyles, makeStyles } from "@mui/styles";
import { orange } from '@mui/material/colors';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { ThemeProvider, createTheme } from '@mui/material';
import { setLoggedInUserData } from '../../../reducers/UserReducer';
import { getDealList } from '../../../reducers/DealReducer';
import SearchBar from '../../../utils/Search';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const PurpleSwitch = withStyles({
  switchBase: {
    color: orange[300],
    '&$checked': {
      color: orange[500],
    },
    '&$checked + $track': {
      backgroundColor: orange[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles({
  actions: {
    color: "#3E5272",
  },
  root: {
    maxWidth: "100%",
    margin: "20px",
  },
  link: {
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "rgb(180, 180, 180)",
    },
  },
  header: {
    maxWidth: "700px",
  },
  childDiv: {
    paddingTop: "10px",
  },
  TextFieldHeight: {
    height: "50px"
  },
  tableheader: {
    marginTop: "-3rem"
  },
  cardStyle: {
    height: "430px", 
    width: "1314px",
    marginLeft: "-5rem", 
    marginBottom: "-1rem"
  },
  formgroup: {
    marginLeft: "-4rem",
    marginTop: "10px"
  }
});

const MatchedEquipment = (props) => {
  const {
    equipmentList = [],
    restClient: { rest },
    getMatchedEquipmentList,
    companyList = [],
    dealList = [],
    getDealList,
    getCompanyList,
    loggedInUserData,
    // filterEquip = [],
    buyerList = []
  } = props;

  const history = useHistory();
  const classes = useStyles();
  const [companyEquip, setCompanyEquip] = useState([]);
  const [matchedEquipList, setMatchedEquipList] = useState([]);
  const [buyerName, setBuyerName] = useState([]);
  const [sellerName, setSellerName] = useState([]);
  // const [buyerList, setBuyerList] = useState([]);
  const [pageState, setPageState] = useState({
    page: 1,
    limit: 1000,
    sort: null,
    searchData: '',
  });
  const [filterCheckbox, setFilterCheckbox] = useState([]);
  const [search, setSearch] = React.useState('');

  useEffect(() => {
    if (equipmentList.length > 0) {
      const filtersData = equipmentList.filter(obj =>
        dealList.some(item =>
          item.dealEquipment.some(objInArray => objInArray._id === obj.id)
        )
      );
      setFilterCheckbox(filtersData);
    }
  }, [equipmentList])

  useEffect(() => {
    // Filter user list when search state changes
    if (equipmentList?.length > 0) {
      const filteredList = equipmentList.filter(user => {
        const listingPriceString = user.listingPrice ? user.listingPrice.toString() : ''; // Convert modelYear to string
        const comparableRetailPriceString = user.comparableRetailPrice ? user.comparableRetailPrice.toString() : '';
        return (
          (user.equipmentName && user.equipmentName.toLowerCase().includes(search.toLowerCase())) ||
          (user.make && user.make.toLowerCase().includes(search.toLowerCase())) ||
          (user.model && user.model.toLowerCase().includes(search.toLowerCase())) ||
          (listingPriceString && listingPriceString.includes(search.toLowerCase())) || // Check if modelYearString includes the search term
          (user.majorCategory && user.majorCategory.toLowerCase().includes(search.toLowerCase())) ||
          (user.mediaLink && user.mediaLink.toLowerCase().includes(search.toLowerCase())) ||
          (user.creatorName && user.creatorName.toLowerCase().includes(search.toLowerCase())) ||
          (comparableRetailPriceString && comparableRetailPriceString.includes(search.toLowerCase()))
        );
      });
  
      setCompanyEquip(filteredList);
    }
  }, [search, equipmentList]);

  // console.log("equipmentList",equipmentList);
  // console.log("dealList", dealList);
  
  const [pageStates, setPageStates] = useState({
    page: 1,
    sort: null,
    limit: 1000,
    searchData: '',
    participant: loggedInUserData.user.roleSystem === 'admin' ? '' : loggedInUserData.user._id
  });

  useEffect(() => {
    if (equipmentList.length > 0) {
      setCompanyEquip(equipmentList);
      }
  }, [equipmentList])

  useEffect(() => {
    const getList = async () => {
      if (pageStates) {
        await getDealList(pageStates);
      }
    }
    getList();
  }, [getDealList,loggedInUserData, pageStates]);

  useEffect(() => {
    const getList = async () => {
      if (rest) {
        if (pageState) {
          await getMatchedEquipmentList(pageState, loggedInUserData);
        }
      }
    };
    getList();
  }, [rest, getMatchedEquipmentList, loggedInUserData, pageState]);

  useEffect(() => {
    const getList = async () => {
      try {
        if (rest) {
          if (pageState) {
            await getCompanyList(pageState);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    getList();
  }, [rest, getCompanyList, pageState]);

  const handleEquipmentEdit = (event, equipment) => {
    history.push(`/equipment/create?action=edit&id=${equipment.id}`);
  };
  const handleEquipmentView = (event, equipment) => {
    history.push(`/view/equipment?action=ViewColumn&id=${equipment.id}`);
  };
  const handleEquipmentDelete = (event, equipment) => {
    props.deleteMatchedEquipment(equipment.id, equipmentList);
  };


  const [data, setData] = React.useState([]);
  const [selectionModel, setSelectionModel] = useState([])
  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleLinkClick = (event, state) => {
    event.preventDefault();
    const listingPriceFilter = selectedRows?.filter((un) => un.listingPrice === null);
    const listFilter = listingPriceFilter[0]?.listingPrice === null;
    if (listFilter) {
    alert('There is no listing price value in the selected equipment to create a deal');
    } else {
      // window.location.href = "/create/deals";
      history.push("/create/deals", state= { selectedRows, buyerName, sellerName });
    }
  };

  // const baseValue = dealList.map((un) => un.dealName);

  const columns = [
    {
      field: "equipmentName",
      headerName: "Equipment",
      width: 280,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.equipmentName} placement="bottom" arrow>
            <Box
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    fontWeight: "bold",
                  },
                }}
                onClick={(event) => {
                  handleEquipmentView(event, params.row);
                }}
              >
                {params.row.equipmentName}
              </Box>
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "dealName",
      headerName: "Deal",
      type: "string",
      width: 250,
      valueGetter: (params) => {
        const deal = dealList.find((deal) =>
          deal.dealEquipment.some((eq) => eq._id === params.row.id)
        );
        return deal ? deal.dealName : "";
      },
    },
    {
      field: "listingPrice",
      headerName: "Listing Price",
      type: "string",
      width: 120,
    },
    {
      field: "creatorName",
      headerName: "Seller",
      width: 130,
    },
    {
      field: "make",
      headerName: "Make",
      width: 110,
    },
    {
      field: "model",
      headerName: "Model",
      width: 100,
    },
    {
      field: "mediaLink",
      headerName: "Media",
      width: 100,
      renderCell: (params) => (
        <a
          href={`https://${params.value}`}
          rel="noreferrer"
          target="_blank"
          className={classes.link}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 140,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View Equipment">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleEquipmentView(event, params.row);
                }}
              >
                <ViewWeekIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Equipment">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleEquipmentEdit(event, params.row);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Equipment">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  window.confirm(
                    "Are you sure want to delete " +
                    params.row.equipmentName +
                    " ?"
                  ) && handleEquipmentDelete(event, params.row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  function DataGridTitle() {

    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              MATCHED EQUIPMENT
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item lg={6} xs={6}>
                <div className={classes.search}>
                  {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                {/* <div className={classes.childDiv}>
                  <GridToolbarContainer>
                    <GridToolbarExport />
                  </GridToolbarContainer>
                </div> */}
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.childDiv}>
                  <Tooltip title="Create New Deal">
                    <Link to={{ pathname: "/create/deals", state: { selectedRows, buyerName, sellerName } }} onClick={handleLinkClick} style={{ pointerEvents: (selectedRows.length > 0) ? "auto" : "none" }}>
                      <IconButton sx={{ color: "#3E5272" }} disabled={(selectedRows.length <= 0) ? true : false}>
                        <AddBoxIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const onFilterChange = (event) => {
    setSearch(event.target.value);
  };

  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };

  const isRowSelectable = (params) => {
    // Check if the row should be selectable based on the filterCheckbox
    return !filterCheckbox.some((filterItem) => filterItem.id === params.id);
  }


  return (
    <div className={classes.tableheader}>
      <div >
      <div style={{textAlign: "end"}}>
      <SearchBar
          searchText={search}
          filterChange={onFilterChange}
          onClickFunc={handleClearText}
        />
        </div>
      </div>
      <Card className={classes.cardStyle}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={companyEquip || []}
            columns={columns}
            // rowCount={totalCount}
            pageSize={100}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            isRowSelectable={isRowSelectable}
            selectionModel={selectionModel}
            onSelectionModelChange={(e) => {
              setSelectionModel(e);
              setData(e);
              const selectedIDs = new Set(e);
              const selected = equipmentList.filter((r) => selectedIDs.has(r.id));
              setSelectedRows(selected);
              let seller = [...new Set(selected.map((o) => o.createdBy))]
              setSellerName(seller)
              let buyer = [...new Set(buyerList.filter((un) => selected.some((o) => o.model === un.model)).map((i) => i.createdBy))]
              setBuyerName(buyer)
            }}
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            sortingMode="server"
            onSortModelChange={(col) => {
              if (col.length > 0) {
                if (col[0].sort === "asc") {
                  setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
                } else {
                  setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
                }
              } else {
                setPageState((old) => ({ ...old, sort: null }));
              }
            }}
            sx={{
              "& .css-ptiqhd-MuiSvgIcon-root": {
                color: "#ee9907",
              },
              // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              //   display: 'none'
              // }
            }}
            // density='standard'
            components={{ Toolbar: DataGridTitle }}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => ({
  restClient: state.restClient,
  companyList: state?.company && state?.company?.companyList?.companyList,
  equipmentList: state?.equipment && state?.equipment?.equipmentList?.equipmentList,
  // filterEquip: state?.equipment && state?.equipment?.equipmentList?.filterEquip,
  buyerList: state?.equipment && state?.equipment?.equipmentList?.buyerList,
  loggedInUserData: state.user && state.user.loggedInUserData,
  dealList: state.deal && state.deal.dealList.dealList,
});
export default connect(mapStateToProps, {
  submitEquipmentRegisterForm,
  getMatchedEquipmentList,
  deleteMatchedEquipment,
  getCompanyList,
  getDealList,
  setLoggedInUserData,
})(MatchedEquipment);