// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.app-container {
  padding-left: 0px;
  padding-right: 0px;
}

body {
  background-color: #E4E4E4
}

.MuiTypography-h6 {
  color: #696969;
  font-size: medium;
  font-weight: 700;
}

/* src/App.css */
.image-container {
  position: relative;
  display: inline-block;
}

.image {
  display: block;
  width: 150px;
  height: 150px;
}

.cancel-icon {
  position: absolute;
  top: 15px;
  left: 138px;
  color: red;
  background-color: white;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-container:hover .cancel-icon {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;AACF;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA,gBAAgB;AAChB;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,UAAU;EACV,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".App {\n  text-align: center;\n}\n\n.app-container {\n  padding-left: 0px;\n  padding-right: 0px;\n}\n\nbody {\n  background-color: #E4E4E4\n}\n\n.MuiTypography-h6 {\n  color: #696969;\n  font-size: medium;\n  font-weight: 700;\n}\n\n/* src/App.css */\n.image-container {\n  position: relative;\n  display: inline-block;\n}\n\n.image {\n  display: block;\n  width: 150px;\n  height: 150px;\n}\n\n.cancel-icon {\n  position: absolute;\n  top: 15px;\n  left: 138px;\n  color: red;\n  background-color: white;\n  border-radius: 50%;\n  opacity: 0;\n  transition: opacity 0.3s;\n}\n\n.image-container:hover .cancel-icon {\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
