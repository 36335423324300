import React, { Suspense, Fragment } from 'react';
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Notifier from './global-components/notifier';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
// import rest from '@feathersjs/rest-client';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { ClimbingBoxLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import { setRestClientConnection } from './reducers/RestClientReducer';
import { setUserAuthenticated, setLoggedInUserData } from './reducers/UserReducer';
import RegisterPage from './pages/Company/Create';
import EquipmentPage from './pages/CreateEquipment';
import EquipmentList from './pages/EquipmentList';
import MatchedEquipmentPage from './pages/MatchedEquipment'
import LoginPage from './pages/Login';
import DealPage from './pages/Deals';
import CompanyList from './pages/Company/CompanyList';
import NetworkPage from './pages/Networks/NetworkList';
import NetworkContactPage from './pages/Networks/NetworkContacts';
import PagesCreateNetwork from './pages/Networks/Create';
import PagesCreateNetworkContact from './pages/Networks/NetworkContactCreate';
import UserPage from './pages/Users/UserList';
import UserViewPage from './pages/Users/ViewUser';
import PagesCreateUser from './pages/Users/Create';
import ViewEquipment from './pages/ViewEquipment';
import ViewCompany from './pages/Company/ViewCompany';
import ForgetPasswordPage from './pages/ForgetPassword';
import PagesChangePassword from './pages/ChangePassword';
import PagesActivate from './pages/Activate';
import DealGridPage from './pages/DealGrid';
import DealChatPage from './pages/DealChat';
import UserProfilePage from './pages/UserProfile';
import RegisterNewPage from './pages/Register'
import CompanyDealPage from './pages/CreateDeals'
import DealListPage from './pages/DealList/index'
import DealTablePage from './pages/ViewDeal'
import CreateBrandPage from './pages/CreateBrand'
import BrandListPage from './pages/BrandList'
import ViewBrandPage from './pages/ViewBrand'
import PendingRequestListPage from './pages/PendingRequestList'
import CompanyBranchListPage from './pages/Company/CompanyBranchList'
import CreateCompanyBranchPage from './pages/Company/CompanyBranchCreate'
import './App.css';
import SnackbarCloseButton from "./SnackBarCloseButton"
import CrmContactListPage from '../src/pages/CrmContactList/index'
import PlacesListPage from '../src/pages/Places/index'
import PagesPayment from './pages/Payment'
import CardPaymentPage from './pages/CardPayment'
import DealInvitationsPage from '../src/pages/DealInvitations'
import VendorListPage from './pages/IntegratedVendor';
import CreateVendorCompanyPage from './pages/CreateIntegratedVendor';
import CompanyRawData from './components/Company/CompanyRawData';
import NewDealGridPage from './pages/NewDealList';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import UserChangePasswordPage from "./pages/UserChangePassword";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.4)'
  },
}));


const ProtectedAdminRoute = ({ component: Component, isAuthenticated, role, ...rest }) => (
  <Route {...rest} render={(props) => {
    return (
      isAuthenticated === true && role === "admin"
        ? <Component {...props} />
        : <Redirect to={{
          pathname: "/login",
          state: { from: props.location }
        }} />
    )
  }} />
);

const ProtectedUserRoute = ({ component: Component, isAuthenticated, role, ...rest }) => (
  <Route {...rest} render={(props) => {
    return (
      isAuthenticated === true && role === "regular"
        ? <Component {...props} />
        : <Redirect to={{
          pathname: "/login",
          state: { from: props.location }
        }} />
    )
  }} />
);

const ProtectedCommonRoute = ({ component: Component, isAuthenticated, role, ...rest }) => (
  <Route {...rest} render={(props) => {
    return (
      isAuthenticated === true
        ? <Component {...props} />
        : <Redirect to={{
          pathname: "/login",
          state: { from: props.location }
        }} />
    )
  }} />
);

const LoginRoute = ({ component: Component, isAuthenticated, role, ...rest }) => (
  <Route {...rest} render={(props) => {
    const pathname = role === 'admin' ? '/users' : "/dealpage";
    return (
      isAuthenticated === true
        ? <Redirect to={{
          pathname: pathname,
          label: "Users",
          state: { from: props.location }
        }} />
        : <Component {...props} />
    )
  }} />
);

const Routes = props => {
  const {
    setRestClientConnection, setUserAuthenticated,
    setLoggedInUserData, isAuthenticated, loggedInUserData, loader
  } = props;
  const location = useLocation();
  const classes = useStyles();
  const roleType = loggedInUserData && loggedInUserData.user && loggedInUserData.user.roleSystem;
  React.useEffect(() => {
    async function configureRestClient() {
      const client = feathers();
      const socketClient = io(process.env.REACT_APP_API_URL);
      client.configure(socketio(socketClient, {   //timeout: 15 min
        timeout: 180000000
      }))
      client.configure(auth({ storageKey: 'auth' }));
      try {
        const user = await client.reAuthenticate();
        setRestClientConnection(client);
        setLoggedInUserData(user);
        setUserAuthenticated(true);

      } catch (error) {
        setUserAuthenticated(false);
        setLoggedInUserData(null);
        setRestClientConnection(client);
      }
    }
    configureRestClient();
  }, [setRestClientConnection, setLoggedInUserData, setUserAuthenticated]);

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#b989c3'} loading={true} />
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <div>
      <SnackbarProvider action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
        <Notifier />
        <Backdrop className={classes.backdrop} open={loader}>
          <CircularProgress />
        </Backdrop>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/Login" />
            <Route path={['/login', '/home', '/equipment', '/deals', '/network', '/network/create', '/networkcontacts',
              '/networkcreatecontacts', '/users', '/users/create', '/company', '/company/create',
              '/view/equipment', '/view/users', '/view/company', '/verify', '/verify/passwordset',
              '/forgetpassword', '/forgotpassword', '/rawdata',
              '/deal/grid', '/userpasswordchange',
              '/dealChat', '/userprofile', '/register', '/deal/chatbox', '/create/deals',
              '/dealpage', '/view/deal', '/brand/create', '/brand', '/view/brand', '/pendingrequest', "/companybranches",
              "/companybranchescreate", "/contacts", "/places", "/payment", "/cardpayment", "/dealinvitations",
              "/matchedequipment", "/vendor", "/create/vendor", "/newDealLayoutPage", "/newDealPage"]}>
              <Switch location={location} key={location.pathname}>
                <LoginRoute exact path="/login" component={LoginPage} isAuthenticated={isAuthenticated} role={roleType} />
                <Route exact path="/forgetpassword" component={ForgetPasswordPage} />
                <ProtectedAdminRoute path="/company/create" component={RegisterPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/company" component={CompanyList} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedCommonRoute path="/equipment/create" component={EquipmentPage} isAuthenticated={isAuthenticated} />
                <ProtectedCommonRoute path="/equipment" component={EquipmentList} isAuthenticated={isAuthenticated} />
                <ProtectedCommonRoute path="/view/equipment" component={ViewEquipment} isAuthenticated={isAuthenticated} />
                <ProtectedUserRoute path="/deals" component={DealPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/network/create" component={PagesCreateNetwork} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/network" component={NetworkPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/networkcreatecontacts" component={PagesCreateNetworkContact} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/networkcontacts" component={NetworkContactPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/users/create" component={PagesCreateUser} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedCommonRoute path="/brand/create" component={CreateBrandPage} isAuthenticated={isAuthenticated} />
                <ProtectedCommonRoute path="/brand" component={BrandListPage} isAuthenticated={isAuthenticated} />
                <ProtectedCommonRoute path="/view/brand" component={ViewBrandPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/users" component={UserPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/view/users" component={UserViewPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/View/company" component={ViewCompany} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedCommonRoute path="/deal/grid" component={DealGridPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedUserRoute path="/deal/chatbox" component={DealChatPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedUserRoute path="/userprofile" component={UserProfilePage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedCommonRoute path="/create/deals" component={CompanyDealPage} isAuthenticated={isAuthenticated} role={roleType} />
                {/* <ProtectedCommonRoute path="/dealpage" component={DealListPage} isAuthenticated={isAuthenticated} role={roleType} /> */}
                <ProtectedCommonRoute path="/dealpage" component={NewDealGridPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedCommonRoute path="/view/deal" component={DealTablePage} isAuthenticated={isAuthenticated} />
                <ProtectedAdminRoute path="/pendingrequest" component={PendingRequestListPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/companybranches" component={CompanyBranchListPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/companybranchescreate" component={CreateCompanyBranchPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/contacts" component={CrmContactListPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/places" component={PlacesListPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedCommonRoute path="/matchedequipment" component={MatchedEquipmentPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedUserRoute path="/dealinvitations" component={DealInvitationsPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedUserRoute path="/userpasswordchange" component={UserChangePasswordPage} isAuthenticated={isAuthenticated} role={roleType} />
                <Route path="/changepassword" component={PagesChangePassword} />
                <Route path="/verify" component={PagesActivate} />
                <Route path="/verify/passwordset" component={PagesActivate} />
                <Route exact path="/forgotpassword" component={PagesActivate} />
                <Route exact path="/register" component={RegisterNewPage} />
                <ProtectedUserRoute path="/payment" component={PagesPayment} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedUserRoute path="/cardpayment" component={CardPaymentPage} isAuthenticated={isAuthenticated} role={roleType} />
                {/* <ProtectedUserRoute path="/newDealPage" component={NewDealGridPage} isAuthenticated={isAuthenticated} role={roleType} /> */}
                <ProtectedAdminRoute path="/vendor" component={VendorListPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/create/vendor" component={CreateVendorCompanyPage} isAuthenticated={isAuthenticated} role={roleType} />
                <ProtectedAdminRoute path="/rawdata" component={CompanyRawData} isAuthenticated={isAuthenticated} role={roleType} />
              </Switch>
            </Route>
          </Switch>
        </Suspense>
      </SnackbarProvider>
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  loggedInUserData: state.user.loggedInUserData,
  loader: state.loader.showLoader
});
const mapDispatchToProps = dispatch => ({
  setRestClientConnection: client => dispatch(setRestClientConnection(client)),
  setUserAuthenticated: flag => dispatch(setUserAuthenticated(flag)),
  setLoggedInUserData: user => dispatch(setLoggedInUserData(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Routes);

