import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Search, Clear } from '@mui/icons-material';

const SearchBar = ({ searchText, filterChange, onClickFunc }) => {
    return (
        <TextField
            id="standard-basic"
            placeholder="Search..."
            variant="outlined"
            type="text"
            size='small'
            value={searchText}
            onChange={filterChange}
            style={{ float: 'right',marginRight: "-5rem", marginBottom: '1rem', backgroundColor: "whitesmoke" }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {searchText ? (
                            <IconButton edge="start" onClick={onClickFunc}>
                                <Clear />
                            </IconButton>
                        ) : (
                            <IconButton edge="end">
                                <Search style={{ color: "orange" }} />
                            </IconButton>
                        )}
                    </InputAdornment>
                ),
            }}
        />
    )
}


export default SearchBar;