import React, { useState, useEffect } from "react";
import { Visibility, VisibilityOff, AttachMoney as AttachMoneyIcon } from '@mui/icons-material';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import useQuery from "../../utils/useQuery";
import { getDealMessageList } from '../../../reducers/DealCommunicationReducer';
import { submitTransactionRegisterForm } from "../../../reducers/TransactionReducer";
import { submitMakeOfferMessage } from "../../../reducers/DealCommunicationReducer";
import { DataGrid } from "@mui/x-data-grid";
import {
    Card,
    Grid,
    Checkbox,
    Button,
    TextField,
    Box,
    Typography,
    InputAdornment,
    IconButton
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from '@mui/material';
import { DealMessageChat } from "../../DealChat/index";
import DealSome from "../DealListGrid/dealCloseOut";


const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeaders: {
                    backgroundColor: '#3E5272',
                    color: 'white',
                },
                columnSeparator: {
                    visibility: "hidden",
                },
                sortIcon: {
                    color: "#ee9907",
                }
            },
        },
    },
});

const useStyles = makeStyles((theme) => ({
    grid: {
        marginTop: 10,
    },
    makeOfferContainer: {
        paddingRight: "5rem",
        display: "flex",
        justifyContent: "flex-end",
        gap: 10,
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    makeOffer: {
        color: "rgb(204, 102, 0)",
        fontSize: "large",
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: "medium",
        },
    },

    makeOfferFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 10,
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            justifyContent: "center",
        },
    },

    gridChild: {
        display: "flex",
        alignItems: "center",
        gap: 10,
    },
    icon: {
        color: "rgb(204, 102, 0)",
        alignItems: "center",
    },
    parent: {
        display: "grid",
        gridTemplateColumns: "7rem 2rem 7rem",
        paddingLeft: "2px",
        marginTop: "-2px",
    },
    input: {
        padding: "7px 0px 8px 0px",
        width: "170px",
        textAlign: "center",
        borderRadius: 3,
        border: "1px solid #555",
    },
    topGrid: {
        paddingTop: "20px",
    },
    makeSeller: {
        paddingLeft: "90px",
        color: "rgb(204, 102, 0)",
        fontSize: "large",
        fontWeight: 700,
    },
    salConst: {
        paddingLeft: "3px",
        paddingBottom: "20px",
        // color: "#696969",
        fontSize: "X-large",
        fontWeight: "bolder",
    },
    salConsttwo: {
        paddingLeft: "3px",
        paddingBottom: "20px",
        // color: "#696969",
        fontSize: "large",
        fontWeight: "bolder",
        marginTop: "-2px",
        display: "flex"
    },
    bottonGrid: {
        paddingLeft: "90px",
        marginTop: "-78px",
    },
    link: {
        textDecoration: "none",
    },
    mediaLink: {
        textDecoration: "none",
        color: "black",
        "&:hover": {
            backgroundColor: "rgb(180, 180, 180)",
        },
    },
    button: {
        textAlign: "right",
        paddingBottom: "10px",
        paddingTop: "10px",
    },
    btnwidth: {
        width: "134%",
        paddingTop: "10px",
    },
    dataGrid: {
        // paddingBottom: "1px",
    },
    numberField: {
        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
    MakeButton: {
        color: "white",
        backgroundColor: "rgb(62, 82, 114)",
        "&:hover": {
            backgroundColor: "#fbbc05",
            //   color: "black"
        },
    },
    childDiv: {
        paddingTop: "10px",
        paddingLeft: "50px",
    },
    dealEquip: {
        paddingLeft: "10px",
    },
    hidePagination: {
        '& .MuiDataGrid-footerContainer': {
            display: 'none',
        },
    },
    totalPrice: {
        fontSize: "medium",
        fontWeight: "800",
        color: "rgb(120, 120, 120)",
    }
}));
const SerialNumberCell = ({ params }) => {
    const [showSerialNumbers, setShowSerialNumbers] = useState(false);

    const toggleSerialNumberVisibility = () => {
        setShowSerialNumbers(!showSerialNumbers);
    };
    return (
        <>
            {params.value ?
                <div>
                    {showSerialNumbers ? params.value : "••••••••••••••••"}
                    <IconButton
                        aria-label="toggle serial number visibility"
                        onClick={toggleSerialNumberVisibility}
                        edge="end"
                    >
                        {!showSerialNumbers ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </div> : ""}
        </>
    );
};

const DataGridDemo = (props) => {
    const {
        dealData,
        loggedInUserData,
        dealID,
        dealCommunicationList = [],
        restClient: { rest },
        submitMakeOfferMessage,
        submitTransactionRegisterForm
    } = props;
    const classes = useStyles();
    const [data, setData] = useState();
    const [rows, setRows] = React.useState([]);

    useEffect(() => {
        if (Array.isArray(dealData.dealEquipment)) {
            setRows(dealData.dealEquipment);
        }
    }, [dealData.dealEquipment]);
    useEffect(() => {
        const getMessageList = async () => {
            try {
                if (dealID && rest) {
                    await getDealMessageList(dealID);
                }
            } catch (e) {
                console.log(e);
            }
        };
        getMessageList();
    }, [rest, getDealMessageList, dealID]);

    const val = dealData?.dealParticipants?.filter((p) => p.participant._id !== loggedInUserData.user._id);
    const [first, setFirst] = useState([]);
    const transactionId = "6231b09f5292800b4074918e";

    const getRowData = () => {
        const transaction = {
            dealId: dealID,
            equipment: first,
            selectionName: "selectionName",
            price: data,
            addedBy: loggedInUserData.user._id,
        };
        const transactioncommunication = {
            transactioncommunication: {
                dealId: dealID,
                transactionId: transactionId,
                message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} Made an offer: $${data}`,
                transactionEquipment: first,
                from: loggedInUserData.user._id,
                to: dealData?.seller,
                notificationType: "Make Offer",
                notes: "notes",
                askPrice: "",
                offerPrice: data,
                priceUnit: "USD",
                addedBy: loggedInUserData.user._id,
            },
            dealname: dealData.dealName,
            dealparticipantemails: dealData.dealParticipants.filter((un) => un.participant.makeOffer === true).map((p) => { return p.participant.email }),
        }

        const payload = { transaction };

        function updateUnreadMessages(masterObj, filterObj) {
            if (masterObj.userId === filterObj.participant._id) {
                masterObj.totalUnReadMessages += 1;
                masterObj.readMessage = false;
            }
        }
        const dealId = props?.dealCommunicationList[0]?.dealId;
        // Iterate through masterData and filterData

        if (dealId && dealId?.UnreadMessages) {
            // Iterate through UnreadMessages and users
            dealId?.UnreadMessages.forEach((masterObj) => {
                val?.forEach((filterObj) => {
                    updateUnreadMessages(masterObj, filterObj);
                });
            });
        }
        props.submitTransactionRegisterForm(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
        props.submitMakeOfferMessage(transactioncommunication);
    };

    function generateRandom() {
        var length = 8,
            charset =
                "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const handleTextFieldChange = (event, rowId) => {
        if (rowId) {
            let val = 0;
            const elems = document.getElementsByName("num1");
            elems.forEach(function (valueCheck) {
                if (valueCheck.value !== "") val = val + parseFloat(valueCheck.value);
            });
            setData(val);
            setFirst([...first, rowId]);
        }
    };

    function formatCurrency(value) {
        if (value) {
            return `$${value}`;
        } else {
            return null;
        }
    }

    const reserveValue = loggedInUserData?.user?._id === dealData?.seller;

    const isSeller = loggedInUserData?.user?._id === dealData?.seller;
    const isBuyer = loggedInUserData?.user?._id === dealData?.buyer;

    const theme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: isSeller ? "#2ecd71" : "#1a75c3",
                            color: "white"
                        },
                    },
                    columnSeparator: {
                        visibility: "hidden",
                    },
                },
            },
        },
    });

    return (
        <div>
            <Card sx={{ width: "90%", marginLeft: "53px" }}>
                <ThemeProvider theme={theme}>
                    <DataGrid
                        autoHeight
                        rows={rows || []}
                        columns={[
                            {
                                field: "equipmentName",
                                headerName: "Equipment",
                                width: 270,
                            },
                            {
                                field: "modelYear",
                                headerName: "Model Year",
                                width: 100,
                                valueFormatter: (params) => {
                                    const date = new Date(params.value);
                                    return date.getFullYear();
                                },
                            },
                            {
                                field: "make",
                                headerName: "Make",
                                width: 120,
                            },
                            {
                                field: "serialNum",
                                headerName: "Serial No",
                                width: 200,
                                renderCell: (params) => <SerialNumberCell params={params} />,
                            },
                            ...(reserveValue ? [{
                                field: "reservePrice",
                                headerName: "Reserve Price(USD)",
                                width: 150,
                                valueFormatter: (params) => formatCurrency(params.value ? params.value : "")
                            }] : []),
                            {
                                field: "listingPrice",
                                headerName: "Listing Price(USD)",
                                width: 150,
                                valueFormatter: (params) => formatCurrency(params.value ? params.value : "")
                            },
                            ((dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id)) ? {
                                field: "price",
                                width: 200,
                                headerName: "Buyer Price",
                                renderCell: (rowdata) => (
                                    <TextField
                                        // disabled={!selectedRows.includes(rowdata.row._id)}
                                        // value={data}
                                        className={classes.numberField}
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => {
                                            handleTextFieldChange(event, rowdata.row._id);
                                        }}
                                        id="textBox"
                                        name="num1"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        style={{ padding: "10px 0px 10px 0px", width: "147px" }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                ),
                            } : ""),
                        ]}
                        getRowId={(row) => generateRandom()}
                        pageSize={5}
                        // rowsPerPageOptions={[5]}
                        pagination={false} // Disable pagination
                        disableSelectionOnClick
                        className={classes.hidePagination}
                        sx={{
                            "& .css-ptiqhd-MuiSvgIcon-root": {
                                color: "#ee9907",
                            },
                            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                                display: 'none'
                            }
                        }}
                    />
                </ThemeProvider>
            </Card>

            <div className={classes.grid}>
                <Grid container spacing={0} className={classes.makeOfferContainer}>
                    <Grid item lg={12}>
                        <div className={classes.makeOfferFlex}>
                            {(dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id)) ? <div className={classes.gridChild}>
                                <div style={{ lineHeight: 0 }}>
                                    {/* <AttachMoneyIcon className={classes.icon} fontSize="small" /> */}
                                </div>
                                <div className={classes.totalPrice}>Total Price </div>
                                <div>
                                    <TextField
                                        type="number"
                                        value={data}
                                        readOnly
                                        size="small"
                                        placeholder="US DOLLARS"
                                        className={classes.input}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyIcon />
                                                </InputAdornment>
                                            ),
                                            inputProps: { readOnly: true }
                                        }}
                                    />
                                </div>
                            </div> : ""}
                            {dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id) ? (
                                <div>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={!data}
                                        onClick={getRowData}
                                        className={classes.MakeButton}
                                    >
                                        Make Offer
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div>
                {/* <DealMessageChat /> */}
            </div>
            <div style={{width: "100%", paddingLeft: "60px", paddingRight: "70px"}}>
            {/* <DealSome /> */}
            </div>
            {/* <DealSome /> */}
        </div>
    );
};
const mapStateToProps = state => ({
    restClient: state.restClient,
    dealCommunicationList: state.dealCommunication && state.dealCommunication.dealCommunicationList,
});

export default connect(mapStateToProps, {
    getDealMessageList, submitMakeOfferMessage,
    submitTransactionRegisterForm
})(DataGridDemo);
