import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { submitUserRegisterForm, getUserPendingList, removeUser } from '../../reducers/UserReducer';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Card, Typography, IconButton, Grid, Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchBar from '../../../src/utils/Search';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "20px",
  },
  tableTitle: {
    display: "flex",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 1.5rem",
  },
  actions: {
    color: "#3E5272",
  },
  childDiv: {
    paddingTop: "10px",
  },
  tableheader: {
    marginTop: "-3rem"
  },
  cardStyle: {
    height: "430px", 
    width: "1314px",
    marginLeft: "-5rem", 
    marginBottom: "-1rem"
  }
}));

const UserList = (props) => {
  const classes = useStyles();
  const {
    userList = [],
    restClient: { rest },
    getUserPendingList,
    totalCount
  } = props;
  const history = useHistory();
  const [isRegisteredUser, setIsRegisteredUser] = useState(true)

  const [pageState, setPageState] = useState({
    page: 1,
    limit: 100,
    sort: null,
    searchData: ""
  });
  const [rowCountState, setRowCountState] = React.useState(totalCount);
  const [filteredPendingUserList, setFilteredPendingUserList] = useState([]);
  const [search, setSearch] = React.useState('');

  // useEffect(() => {
  //   // Set filteredUserList to userList initially
  //   if(userList?.length > 0){
  //     setFilteredPendingUserList(userList);
  //   }
  // }, [userList]);

  useEffect(() => {
    // Filter user list when search state changes
    if(userList?.length >= 0){
    const filteredList = userList.filter(user =>
      (user.firstName && user.firstName.toLowerCase().includes(search.toLowerCase())) ||
      (user.lastName && user.lastName.toLowerCase().includes(search.toLowerCase())) ||
      (user.roleSystem && user.roleSystem.toLowerCase().includes(search.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(search.toLowerCase()))
    );
  
    setFilteredPendingUserList(filteredList);
    if (filteredList.length > 0) {
      setRowCountState(filteredList.length);
    }}
  }, [search, userList]);

  // Get the data from userreducer
  useEffect(() => {
    const getList = async () => {
      try {
        if (rest && isRegisteredUser && pageState) {
          await getUserPendingList(isRegisteredUser,pageState);
        }
      } catch (e) {

      }
    };
    getList();
  }, [rest, getUserPendingList, isRegisteredUser,pageState]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await getUserPendingList(
  //       pageState.page,
  //       pageState.sort,
  //       pageState.searchData,
  //       pageState.limit,
  //       isRegisteredUser
  //     );
  //     await getUserPendingList(pageState.page, pageState.sort, pageState.searchData, pageState.limit, isRegisteredUser);
  //   };
  //   fetchData();
  // }, [
  //   pageState.page,
  //   pageState.sort,
  //   pageState.searchData,
  //   pageState.limit,
  //   getUserPendingList,
  //   isRegisteredUser
  // ]);

  // edit users
  const handleUserEdit = (event, user) => {
    history.push(`/users/create?action=editPending&id=${user.id}`);
  };
  // remove users
  const handleUserDelete = (event, user) => {
    props.removeUser(user.id, userList, totalCount);
  };

  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 250,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      type: "string",
      width: 250,
    },
    {
      field: "roleSystem",
      headerName: "Role",
      type: "string",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email",
      width: 350,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit Pending User">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleUserEdit(event, params.row);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Pending User">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  window.confirm(
                    "Are you sure want to delete " + params.row.firstName + " ?"
                  ) && handleUserDelete(event, params.row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];


  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}
            >
              PENDING USERS
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.search}>
              {/* <GridToolbarContainer>
                <QuickSearchToolbar />
              </GridToolbarContainer> */}
            </div>
          </Grid>
        </Grid>
      </Box>
    )
  }

  // const onFilterChange = (event) => {
  //   // Here you save the data you need from the filter model and perform server-side search/filtering
  //   // Once the server-side search is completed, you can update the searchInput state with the new value

  //   // Example: Simulating server-side search delay with setTimeout
  //   setSearch(event.target.value);
  //   // if (search.length > 4 || search.trim() == '') {
  //   if (event?.target?.value?.length >= 3)
  //     setTimeout(() => {
  //       setPageState((old) => ({ ...old, searchData: event.target.value }));
  //     }, 2000);
  //   if (event?.target?.value?.length < 1)
  //     setPageState((old) => ({ ...old, searchData: '' }));
  //   // }
  // };

  const onFilterChange = (event) => {
    setSearch(event.target.value);
  };

  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };

  return (
    <div className={classes.tableheader}>
      <SearchBar
        searchText={search}
        filterChange={onFilterChange}
        onClickFunc={handleClearText}
      />
      <Card className={classes.cardStyle}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rowCount={filteredPendingUserList.length >= 0 ? filteredPendingUserList.length : rowCountState}
            paginationMode="server"
            rowsPerPageOptions={[100]}
            pagination
            pageSize={pageState.pageSize}
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            rows={filteredPendingUserList || []}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            // sortingMode="server"
            onSortModelChange={(col) => {
              if (col.length > 0) {
                if (col[0].sort === "asc") {
                  setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
                } else {
                  setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
                }
              } else {
                setPageState((old) => ({ ...old, sort: null }));
              }
            }}
            sx={{
              "& .css-ptiqhd-MuiSvgIcon-root": {
                color: "#ee9907",
              },
              // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              //   display: 'none'
              // }
            }}
            // density='standard'
            // components={{ Toolbar: DataGridTitle }}
            components={{
              Toolbar: DataGridTitle,
              Pagination: GridPagination,
            }}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restClient: state.restClient,
  userList: state.user && state.user.userList.userList,
  totalCount: state.user && state.user.userList.total,
  limit: state.user && state.user.userList.limit,
});

export default connect(mapStateToProps, {
  submitUserRegisterForm,
  getUserPendingList,
  removeUser,
})(UserList);