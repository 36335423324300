import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import useQuery from '../../../utils/useQuery';
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from '../../../styles/appStyles';
import { getCompanyList } from '../../../reducers/CompanyReducer';
import {
  FormControlWrapper,
  FormButtonWrapper
} from '../../../styles/formStyles';
import { schema, initialFormValues, getPayload, industyCategories, industryList } from './utils';
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  TextField,
  MenuItem,
  IconButton,
  InputAdornment
} from '@mui/material';
import { submitEquipmentRegisterForm, getEquipmentById, editEquipment } from '../../../reducers/EquipmentReducer';
import validate from 'validate.js';
import { getUserList, setLoggedInUserData } from '../../../reducers/UserReducer';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { withStyles, makeStyles } from "@mui/styles";


validate.validators.IsValidURL = function (value) {
  var regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
  if (!validate.isEmpty(value) && !regex.test(value)) {
    return "Company Web Site Is Not Valid";
  }
  return null
};

const useStyles = makeStyles((theme) => ({
  reservePrice: {
    paddingTop: '20px'
  },
}));


const CreateEquipment = props => {
  const { restClient: { rest }, equipmentData, companyList, userList, getEquipmentById, getCompanyList, getUserList, loggedInUserData, userData } = props;
  const classes = useStyles();
  const query = useQuery();
  const action = query.get('action');
  const equipmentID = query.get('id');
  const [filterUserBranch, setFilterUserBranch] = useState([]);
  const [selectParentCompany, setSelectParentCompany] = useState({});
  // initialFormValues.company = selectParentCompany;
  const [formState, setFormState] = useState({
    isValid: false,
    values: initialFormValues,
    touched: {},
    errors: {},
  });

  const [reservePrice, setReservePrice] = useState('');
  const [listingPrice, setListingPrice] = useState('');
  const [isValid, setIsValid] = useState(true);

  const history = useHistory();

  const [showSerialNumber, setShowSerialNumber] = useState(false);

  const toggleSerialNumberVisibility = () => {
    setShowSerialNumber(!showSerialNumber);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    // Go back to the previous page
    history.goBack();
  };

  const handleFirstInputChange = (event) => {
    const newValue = event.target.value;
    setReservePrice(newValue);
    validateInput(newValue, listingPrice);
  };

  const handleSecondInputChange = (event) => {
    const newValue = event.target.value;
    setListingPrice(newValue);
    validateInput(reservePrice, newValue);
  };

  const validateInput = (first, second) => {
    const isFirstValid = !isNaN(first) && first !== '';
    const isSecondValid = !isNaN(second) && second !== '';

    if (isFirstValid && isSecondValid) {
      setIsValid(parseFloat(first) < parseFloat(second));
    } else {
      setIsValid(true); // Reset validation if either field is empty or not a number
    }
  };

  // console.log("userData",userData);
  // console.log("userList",userList);
  console.log("equipmentData", equipmentData);
  // const [filterUserBranch, setFilterUserBranch] = useState([])

  useEffect(() => {
    const getBranches = async () => {
      if (userData?.user && userData?.user.roleSystem === "regular") {
        const branchSel = userData.user.parentCompany.branch.map((item) => ({ branchID: item.branchID, branchName: item.branchName }));
        setFilterUserBranch(branchSel)
      }
    };
    getBranches();
  }, [rest, userData]);

  const handleBranchChange = (event) => {
    // event.persist();
    const companySel = event.target.value;
    const branchSel = userList.filter((un) => un.id === companySel)[0].branch.map((item) => ({ branchID: item.branchID, branchName: item.branchName }));
    const selectCompany = userList.filter((un) => un.id === companySel)[0].parentCompany;
    setSelectParentCompany(selectCompany);
    setFilterUserBranch(branchSel);
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  const userVal = loggedInUserData?.user?.roleSystem === 'admin';
  // console.log("loggedInUserData", loggedInUserData);

  const [branches, setBranches] = useState([]);
  // const [selectedCompany, setSelectedCompany] = useState("");
  const [majorCategory, setMajorCategory] = useState([]);
  // const [industry, setIndustry] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

  const sanitizeUrl = (url) => {
    return url.replace(/^https?:\/\//, '');
  };

  const handleChange = (event) => {
    // event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'file'
            ? event.target.files[0].name
            : sanitizeUrl(event.target.value)
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [name]: value.trim(), // Trim leading and trailing spaces on blur
      },
      touched: {
        ...prevFormState.touched,
        [name]: true,
      },
    }));
  };

  const handleStartDateChange = (name, date) => {
    handleStateChange(name, date);
  }

  const handleIndustryChange = (event) => {
    // event.persist();
    const selectedIndustry = event.target.value;
    const majorCategory = industyCategories.filter((un) => un.industry === selectedIndustry).map((un) => un.majorCategory).flat(1);
    // setIndustry(selectedIndustry);
    setMajorCategory(majorCategory);
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  useEffect(() => {
    const getMajorCategory = async () => {
      try {
        let arr = [];
        if (action === 'edit' && equipmentID && equipmentData && rest) {
          arr = equipmentData.industry.toString();
          const majorCategory = industyCategories.filter((un) => un.industry === arr);
          setMajorCategory(majorCategory[0].majorCategory);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getMajorCategory();
  }, [action, equipmentID, equipmentData, rest]);

  const handleCompanyChange = (event) => {
    // event.persist();
    const companySel = event.target.value;
    const branchSel = companyList.filter((un) => un.id === companySel)[0].branch.map((item) => ({ branchID: item.branchID, branchName: item.branchName }));
    // setSelectedCompany(companySel);
    setBranches(branchSel);
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleStateChange = (field, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [field]: value
      },
      touched: {
        ...prevFormState.touched,
        [field]: true
      }
    }));
  };
  useEffect(() => {
    if (action === 'edit' && equipmentID && rest) {
      const getEquipment = async () => {
        getEquipmentById(equipmentID);
      }
      getEquipment();
    }
  }, [action, getEquipmentById, equipmentID, rest])

  useEffect(() => {
    if (rest) {
      const getList = async () => {
        getCompanyList();
        getUserList();
      }
      getList();
    }
  }, [rest, getCompanyList, getUserList])

  useEffect(() => {
    if (action === 'edit' && equipmentData) {
      setFormState({
        isValid: false,
        values: equipmentData,
        touched: {},
        errors: {},
      });
      setReservePrice(equipmentData.reservePrice);
      setListingPrice(equipmentData.listingPrice);
      setSelectParentCompany(equipmentData.company);
    }
  }, [action, equipmentData]);

  useEffect(() => {
    const getBranch = async () => {
      try {
        let branches = [];
        if (action === 'edit' && equipmentID && rest && equipmentData && userData && userList) {
          if (equipmentData.branchID && userData?.user && userData?.user?.roleSystem === "admin") {
            branches = userList.filter((c) => c.id === equipmentData.createdBy && c.branch.some((i) => i.branchID === equipmentData.branchID)).map((o) => o.branch[0]);
            setFilterUserBranch(branches);
          }
          else {
            branches = userList.filter((un) => un.id === equipmentData.createdBy)[0].branch.map((item) => ({ branchID: item.branchID, branchName: item.branchName }));
            setFilterUserBranch(branches);
          }
          if (equipmentData.availabilityStatus.length > 0) {
            setSelectedOption("available");
          } else if (equipmentData.needStatus.length > 0) {
            setSelectedOption("need");
          } else {
            setSelectedOption("");
          }
        }
      } catch (e) {
        // console.log(e);
      }
    };
    if (userList && equipmentData) {
      getBranch();
    }
  }, [action, userList, rest, userData, equipmentData, equipmentID]);


  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }));

  }, [formState.values]);

  const handleStartCaseChange = (event) => {
    const { name, value } = event.target;
    handleStateChange(name, StartCase(value));
  }

  const StartCase = (text) => {
    return text
      .split(" ")
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };
  const checkErrors = () => {
    let isErrorExist = false;
    let touched = {};
    for (let key in formState.values) {
      if (formState.values.hasOwnProperty(key)) {
        touched = {
          ...touched,
          [key]: true
        }
      }
    }

    if (!userVal) {
      delete schema.createdBy;
    }
    const errors = validate(formState.values, schema, { fullMessages: false });
    if (errors) {
      isErrorExist = true;
    }
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
      touched: touched,
    }));
    return isErrorExist;
  }
  const submitForm = event => {
    event.preventDefault();
    if (!checkErrors()) {
      const { values } = formState;
      const payload = getPayload(values, userData, selectParentCompany);
      if (selectedOption === "need") {
        payload.assetState.availabilityStateTransition = []
        if (payload.assetState.needStateTransition[0] && payload.assetState.needStateTransition[0].needStatus.length === 0) {
          payload.assetState.needStateTransition = []
        }
      } else if (selectedOption === "available") {
        payload.assetState.needStateTransition = []
        if (payload.assetState.availabilityStateTransition[0] && payload.assetState.availabilityStateTransition[0].availabilityStatus.length === 0) {
          payload.assetState.availabilityStateTransition = []
        }
      } else if (selectedOption === "") {
        payload.assetState.needStateTransition = []
        payload.assetState.availabilityStateTransition = []
      }
      if (action === 'edit' && equipmentID) {
        payload.reservePrice = reservePrice;
        payload.listingPrice = listingPrice;
        props.editEquipment(equipmentID, payload);
      }
      else {
        payload.fleetType = 'Rental';
        payload.qtrProjected = 'Q1';
        payload.pricingModel = 'Negotiated';
        payload.assetState.locationStateTransition.locationStatus = 'Located';
        payload.assetState.usageStateTransition[0].usageStatus = 'InUse';
        payload.reservePrice = reservePrice;
        payload.listingPrice = listingPrice;
        props.submitEquipmentRegisterForm(payload);
      }
    }
  }
  return (
    <AppContent>
      <AppContentInner>
        <AppWrapper>
          <AppMain>
            <Container maxWidth="lg">
              <Grid container spacing={5}>
                <Grid
                  item
                  xs={12}
                  lg={12}>
                  <Card >
                    <Header>
                      <HeaderText >
                        <h5>{action === 'edit' ? 'View / Edit Equipment' : 'Create New Equipment'}</h5>
                      </HeaderText>
                    </Header>
                    <CardContent >
                      <form className='formSpacing' onSubmit={submitForm}>
                        <Grid container spacing={4}>
                          <Grid item lg={6} xs={12}>
                            <FormControlWrapper>
                              <FormControl className='w-100'
                                error={hasError('equipmentName')}
                                helperText={
                                  hasError('equipmentName') ? formState.errors.equipmentName[0] : null
                                }
                              >
                                <TextField
                                  error={hasError('equipmentName')}
                                  helperText={
                                    hasError('equipmentName') ? formState.errors.equipmentName[0] : null
                                  }
                                  variant="outlined"
                                  label="Equipment Name*"
                                  data-test-id="Create-Equipment-EquipmentName"
                                  fullWidth
                                  id="companyEquipmentID"
                                  placeholder="Equipment Name*"
                                  type="text"
                                  name="equipmentName"
                                  value={formState.values.equipmentName}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('equipmentDescription')}
                                helperText={
                                  hasError('equipmentDescription') ? formState.errors.equipmentDescription[0] : null
                                }
                              >
                                <TextField
                                  error={hasError('equipmentDescription')}
                                  helperText={
                                    hasError('equipmentDescription') ? formState.errors.equipmentDescription[0] : null
                                  }
                                  variant="outlined"
                                  multiline
                                  label="Equipment Description* "
                                  data-test-id="Create-Equipment-EquipmentDescription"
                                  fullWidth
                                  id="equipmentDescription"
                                  placeholder="Equipment Description "
                                  type="text"
                                  name="equipmentDescription"
                                  value={formState.values.equipmentDescription}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('fleetType')}
                                helperText={
                                  hasError('fleetType') ? formState.errors.fleetType[0] : null
                                }
                              >
                                <InputLabel id="create-company-country-select-outlined-label">Fleet Type</InputLabel>
                                <Select
                                  error={hasError('fleetType')}
                                  helperText={
                                    hasError('fleetType') ? formState.errors.fleetType[0] : null
                                  }
                                  label="Fleet Type "
                                  data-test-id="Create-Equipment-FleetType"
                                  value={formState.values.fleetType}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'fleetType',
                                    id: 'fleetType',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Fleet Type</MenuItem>
                                  {[{ name: 'Rental' }, { name: 'Owned' }].map((fleetType) => <MenuItem value={fleetType.name}>{fleetType.name}</MenuItem>)}
                                </Select>
                                {hasError('fleetType') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('fleetType')}>
                                    {formState.errors.fleetType[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>

                            <FormControlWrapper>
                              <FormControl className="w-100"
                                error={hasError('make')}
                                helperText={
                                  hasError('make') ? formState.errors.make[0] : null
                                }
                              >
                                <TextField
                                  error={hasError('make')}
                                  helperText={
                                    hasError('make') ? formState.errors.make[0] : null
                                  }
                                  variant="outlined"
                                  label="Manufacturing Company*"
                                  data-test-id="Create-Equipment-Make"
                                  fullWidth
                                  placeholder="Manufacturing Company"
                                  type="text"
                                  name="make"
                                  value={formState.values.make}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('industry')}
                                helperText={
                                  hasError('industry') ? formState.errors.industry[0] : null
                                }
                              >
                                <InputLabel id="create-company-country-select-outlined-label">Industry</InputLabel>
                                <Select
                                  error={hasError('industry')}
                                  helperText={
                                    hasError('industry') ? formState.errors.industry[0] : null
                                  }
                                  label="Industry"
                                  data-test-id="Create-Equipment-industry"
                                  value={formState.values.industry}
                                  onChange={handleIndustryChange}
                                  inputProps={{
                                    name: 'industry',
                                    id: 'industry',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Industry Type*</MenuItem>
                                  {(industryList || []).map((industry) => <MenuItem value={industry}>{industry}</MenuItem>)}
                                </Select>
                                {hasError('industry') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('industry')}>
                                    {formState.errors.industry[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>

                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('majorCategory')}
                                helperText={
                                  hasError('majorCategory') ? formState.errors.majorCategory[0] : null
                                }
                              >
                                <InputLabel id="create-company-country-select-outlined-label">Major Category</InputLabel>
                                <Select
                                  error={hasError('majorCategory')}
                                  helperText={
                                    hasError('majorCategory') ? formState.errors.majorCategory[0] : null
                                  }
                                  label="Major Category"
                                  data-test-id="Create-Equipment-majorCategory"
                                  value={formState.values.majorCategory}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'majorCategory',
                                    id: 'majorCategory',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Category Type</MenuItem>
                                  {(majorCategory || []).map((category) => <MenuItem value={category}>{category}</MenuItem>)}
                                </Select>
                                {hasError('majorCategory') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('majorCategory')}>
                                    {formState.errors.majorCategory[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100"
                                error={hasError('model')}
                                helperText={
                                  hasError('model') ? formState.errors.model[0] : null
                                }
                              >
                                <TextField
                                  error={hasError('model')}
                                  helperText={
                                    hasError('model') ? formState.errors.model[0] : null
                                  }
                                  variant="outlined"
                                  label="Model*"
                                  data-test-id="Create-Equipment-Model"
                                  fullWidth
                                  placeholder="Model"
                                  type="text"
                                  name="model"
                                  value={formState.values.model}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100"
                                error={hasError('modelYear')}
                                helperText={
                                  hasError('modelYear') ? formState.errors.modelYear[0] : null
                                }
                              >
                                <TextField
                                  error={hasError('modelYear')}
                                  helperText={
                                    hasError('modelYear') ? formState.errors.modelYear[0] : null
                                  }
                                  variant="outlined"
                                  label="Model Year*"
                                  data-test-id="Create-Equipment-modelYear"
                                  fullWidth
                                  placeholder="Model Year"
                                  type="text"
                                  name="modelYear"
                                  value={formState.values.modelYear}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100"
                                error={hasError('serialNum')}
                                helperText={
                                  hasError('serialNum') ? formState.errors.serialNum[0] : null
                                }
                              >
                                <TextField
                                  error={hasError('serialNum')}
                                  helperText={
                                    hasError('serialNum') ? formState.errors.serialNum[0] : null
                                  }
                                  variant="outlined"
                                  label="Serial Number"
                                  data-test-id="Create-Equipment-SerailNum"
                                  fullWidth
                                  placeholder="Serial Number"
                                  type={showSerialNumber ? 'text' : 'password'}
                                  name="serialNum"
                                  value={formState.values.serialNum}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  autoComplete="new-password"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle serial number visibility"
                                          onClick={toggleSerialNumberVisibility}
                                          edge="end"
                                        >
                                          {showSerialNumber ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            {userVal ?
                              (<FormControlWrapper>
                                <FormControl variant="outlined" className='w-100'
                                  error={hasError('createdBy')}
                                  helperText={
                                    hasError('createdBy') ? formState.errors.createdBy[0] : null
                                  }
                                >
                                  <InputLabel >Select Creator Name</InputLabel>
                                  <Select
                                    error={hasError('createdBy')}
                                    helperText={
                                      hasError('createdBy') ? formState.errors.createdBy[0] : null
                                    }
                                    label="Select Creator Name"
                                    data-test-id="Create-Equipment-Company"
                                    value={formState.values.createdBy}
                                    onChange={handleBranchChange}
                                    inputProps={{
                                      name: 'createdBy',
                                      id: 'createdBy',
                                    }}
                                  >
                                    <MenuItem value="0" disabled>Select Creator Name</MenuItem>
                                    {(userList || []).map((user) => <MenuItem value={user.id}>{user.name}-({user.companyName})</MenuItem>)}
                                  </Select>
                                  {hasError('createdBy') && (
                                    <FormHelperText htmlFor="form-selector" error={hasError('createdBy')}>
                                      {formState.errors.createdBy[0]}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </FormControlWrapper>)
                              : null}
                            {/* <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('company')}
                                helperText={
                                  hasError('company') ? formState.errors.company[0] : null
                                }
                              >
                                <InputLabel >Select Company Name*</InputLabel>
                                <Select
                                  error={hasError('company')}
                                  helperText={
                                    hasError('company') ? formState.errors.company[0] : null
                                  }
                                  label="Select Company Name* "
                                  data-test-id="Create-Equipment-Company"
                                  value={formState.values.company}
                                  onChange={handleCompanyChange}
                                  inputProps={{
                                    name: 'company',
                                    id: 'company',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Company Name*</MenuItem>
                                  {(companyList || []).map((company) => <MenuItem value={company.id}>{company.companyName}</MenuItem>)}
                                </Select>
                                {hasError('company') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('company')}>
                                    {formState.errors.company[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper> */}
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('branchID')}
                                helperText={
                                  hasError('branchID') ? formState.errors.branchID[0] : null
                                }
                              >
                                <InputLabel >Select Company Branch Name</InputLabel>
                                <Select
                                  error={hasError('branchID')}
                                  helperText={
                                    hasError('branchID') ? formState.errors.branchID[0] : null
                                  }
                                  label="Select Company Branch Name"
                                  data-test-id="Create-Equipment-BranchID"
                                  value={formState.values.branchID}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'branchID',
                                    id: 'branchID',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Company Branch Name*</MenuItem>
                                  {(filterUserBranch || []).map((item) => <MenuItem value={item.branchID}>{item.branchName}</MenuItem>)}
                                </Select>
                                {hasError('branchID') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('branchID')}>
                                    {formState.errors.branchID[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <TextField
                                error={hasError('companyEquipmentID')}
                                helperText={
                                  hasError('companyEquipmentID') ? formState.errors.companyEquipmentID[0] : null
                                }
                                variant="outlined"
                                label="Company EquipmentId"
                                fullWidth
                                placeholder="Company EquipmentID"
                                type="text"
                                data-test-id="Create-Equipment-CompanyEquipmentID"
                                name="companyEquipmentID"
                                id="companyEquipmentID"
                                value={formState.values.companyEquipmentID}
                                onChange={handleStartCaseChange}
                                onBlur={handleBlur}
                              />
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  variant="outlined"
                                  label="Meter Read"
                                  data-test-id="Create-Equipment-MeterRead"
                                  fullWidth
                                  placeholder="Meter Read"
                                  type="text"
                                  name="meterRead"
                                  autoComplete="new-password"
                                  value={formState.values.meterRead}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100"
                                error={hasError('quantity')}
                                helperText={
                                  hasError('quantity') ? formState.errors.quantity[0] : null
                                }
                              >
                                <TextField
                                  error={hasError('quantity')}
                                  helperText={
                                    hasError('quantity') ? formState.errors.quantity : null
                                  }
                                  variant="outlined"
                                  label="Quantity"
                                  data-test-id="Create-Equipment-Quantity"
                                  fullWidth
                                  InputProps={{ inputProps: { min: 0, max: 50 } }}
                                  placeholder="Quantity"
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  name="quantity"
                                  value={formState.values.quantity}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100"
                                error={hasError('currentLocationCity')}
                                helperText={
                                  hasError('currentLocationCity') ? formState.errors.currentLocationCity[0] : null
                                }
                              >
                                <TextField
                                  error={hasError('currentLocationCity')}
                                  helperText={
                                    hasError('currentLocationCity') ? formState.errors.currentLocationCity[0] : null
                                  }
                                  variant="outlined"
                                  label="Current Location City"
                                  data-test-id="Create-Equipment-currentLocationCity"
                                  fullWidth
                                  placeholder="Current Location City"
                                  type="text"
                                  name="currentLocationCity"
                                  value={formState.values.currentLocationCity}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  variant="outlined"
                                  label="Location Latitude"
                                  data-test-id="Create-Equipment-locationLat"
                                  fullWidth
                                  placeholder="Location Latitude"
                                  type="text"
                                  name="locationLat"
                                  value={formState.values.locationLat}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  variant="outlined"
                                  label="Location Longitude"
                                  data-test-id="Create-Equipment-locationLong"
                                  fullWidth
                                  placeholder="Location Longitude"
                                  type="text"
                                  name="locationLong"
                                  value={formState.values.locationLong}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('pricingModel')}
                                helperText={
                                  hasError('pricingModel') ? formState.errors.pricingModel[0] : null
                                }
                              >
                                <InputLabel id="create-company-country-select-outlined-label">Pricing Model</InputLabel>
                                <Select
                                  error={hasError('pricingModel')}
                                  helperText={
                                    hasError('pricingModel') ? formState.errors.pricingModel[0] : null
                                  }
                                  label="Pricing Model"
                                  data-test-id="Create-Equipment-PricingModel"
                                  value={formState.values.pricingModel}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'pricingModel',
                                    id: 'pricingModel',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Pricing Model</MenuItem>
                                  {[{ name: 'Negotiated' }, { name: 'FixedPrice' }, { name: 'Bidding' }, { name: 'Free' }].map((pricingModel) => <MenuItem value={pricingModel.name}>{pricingModel.name}</MenuItem>)}
                                </Select>
                                {hasError('pricingModel') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('pricingModel')}>
                                    {formState.errors.pricingModel[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('locationStatus')}
                                helperText={
                                  hasError('locationStatus') ? formState.errors.locationStatus[0] : null
                                }
                              >
                                <InputLabel id="create-Equipment-state-select-outlined-label">Location Status</InputLabel>
                                <Select
                                  error={hasError('locationStatus')}
                                  helperText={
                                    hasError('locationStatus') ? formState.errors.locationStatus[0] : null
                                  }
                                  label="Location Status"
                                  data-test-id="Create-Equipment-LocationStatus"
                                  value={formState.values.locationStatus}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'locationStatus',
                                    id: 'locationStatus',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Location Status</MenuItem>
                                  {[{ name: 'Locating' }, { name: 'Located' }, { name: 'Moving' }].map((locationStatus) => <MenuItem value={locationStatus.name}>{locationStatus.name}</MenuItem>)}
                                </Select>
                                {hasError('locationStatus') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('locationStatus')}>
                                    {formState.errors.locationStatus[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('usageStatus')}
                                helperText={
                                  hasError('usageStatus') ? formState.errors.usageStatus[0] : null
                                }
                              >
                                <InputLabel id="create-company-state-select-outlined-label">Usage Status</InputLabel>
                                <Select
                                  error={hasError('usageStatus')}
                                  helperText={
                                    hasError('usageStatus') ? formState.errors.usageStatus[0] : null
                                  }
                                  label="Usage Status"
                                  data-test-id="Create-Equipment-UsageState"
                                  value={formState.values.usageStatus}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'usageStatus',
                                    id: 'usageStatus',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Usage Status</MenuItem>
                                  {[{ name: 'New' }, { name: 'InUse' }, { name: 'Waiting' }, { name: 'Parked' }, { name: 'Maintenance' }, { name: 'EOL' }].map((usageStatus) => <MenuItem value={usageStatus.name}>{usageStatus.name}</MenuItem>)}
                                </Select>
                                {hasError('usageStatus') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('usageStatus')}>
                                    {formState.errors.usageStatus[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('qtrProjected')}
                                helperText={
                                  hasError('qtrProjected') ? formState.errors.qtrProjected[0] : null
                                }
                              >
                                <InputLabel id="create-company-state-select-outlined-label">Qtr Projected</InputLabel>
                                <Select
                                  error={hasError('qtrProjected')}
                                  label="Qtr Projected"
                                  data-test-id="Create-Equipment-qtrProjected"
                                  value={formState.values.qtrProjected}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'qtrProjected',
                                    id: 'qtrProjected',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Qtr Projected</MenuItem>
                                  {[{ name: 'Q1' }, { name: 'Q2' }, { name: 'Q3' }, { name: 'Q4' }].map((qtrProjected) => <MenuItem value={qtrProjected.name}>{qtrProjected.name}</MenuItem>)}
                                </Select>
                                {hasError('qtrProjected') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('qtrProjected')}>
                                    {formState.errors.qtrProjected[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  variant="outlined"
                                  label="Photo Link"
                                  data-test-id="Create-Equipment-photoLink"
                                  fullWidth
                                  placeholder="Photo Link"
                                  type="text"
                                  name="photoLink"
                                  value={formState.values.photoLink}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  variant="outlined"
                                  label="Media Link"
                                  data-test-id="Create-Equipment-mediaLink"
                                  fullWidth
                                  placeholder="Media Link"
                                  type="text"
                                  name="mediaLink"
                                  value={formState.values.mediaLink}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  variant="outlined"
                                  label="Pricing Tiers"
                                  data-test-id="Create-Equipment-pricingTiers"
                                  fullWidth
                                  placeholder="Pricing Tiers"
                                  type="text"
                                  name="pricingTiers"
                                  value={formState.values.pricingTiers}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  multiline
                                  variant="outlined"
                                  label="Asset Description"
                                  data-test-id="Create-Equipment-AssetDescription"
                                  fullWidth
                                  placeholder="Asset Description"
                                  type="text"
                                  name="assetDescription"
                                  value={formState.values.assetDescription}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="radio-options"
                                  name="radio-options"
                                  value={selectedOption}
                                  onChange={handleOptionChange}
                                >
                                  <FormControlLabel value="need" control={<Radio disabled={(action === "edit" && selectedOption === "available")} />} label="Need" />
                                  <FormControlLabel value="available" control={<Radio disabled={(action === "edit" && selectedOption === "need")} />} label="Available" />
                                </RadioGroup>
                                {selectedOption === 'need' ? (
                                  <div>
                                    <FormControlWrapper>
                                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                          label="Select Start Date"
                                          name="needStartDate"
                                          minDate={new Date()}
                                          value={formState.values.needStartDate}
                                          onChange={(date) => handleStartDateChange('needStartDate', date.toISOString())}
                                          renderInput={(params) => <TextField variant='outlined' ref={params.error = false} {...params} />}
                                        />
                                      </LocalizationProvider>
                                    </FormControlWrapper>
                                    <FormControlWrapper>
                                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                          label="Select End Date"
                                          name="needEndDate"
                                          minDate={formState.values.needStartDate === '' ? new Date() : formState.values.needStartDate}
                                          value={formState.values.needEndDate}
                                          onChange={(date) => handleStartDateChange('needEndDate', date.toISOString())}
                                          renderInput={(params) => <TextField ref={params.error = false} variant='outlined' {...params} />}
                                        />
                                      </LocalizationProvider>
                                    </FormControlWrapper>
                                    <FormControlWrapper>
                                      <FormControl variant="outlined" className='w-100'
                                      >
                                        <InputLabel id="create-company-state-select-outlined-label">Need Status </InputLabel>
                                        <Select
                                          error={hasError('needStatus')}

                                          label="Need Status "

                                          value={formState.values.needStatus}
                                          onChange={handleChange}
                                          inputProps={{
                                            name: 'needStatus',
                                            id: 'needStatus',
                                          }}
                                        >
                                          <MenuItem value="0" disabled>Select Need Status</MenuItem>
                                          {[{ name: 'Needed' }, { name: 'Need' }, { name: 'Needing' }].map((needStatus) => <MenuItem value={needStatus.name}>{needStatus.name}</MenuItem>)}
                                        </Select>
                                        {hasError('needStatus') && (
                                          <FormHelperText htmlFor="form-selector" error={hasError('needStatus')}>
                                            {formState.errors.needStatus[0]}
                                          </FormHelperText>
                                        )}
                                      </FormControl>
                                    </FormControlWrapper>
                                  </div>
                                ) : (selectedOption === 'available') ? <div className={classes.reservePrice}>
                                    <FormControlWrapper>
                                      <FormControl className={classes.reservePrice}>
                                        <TextField
                                          variant="outlined"
                                          label="Reserve Price"
                                          data-test-id="Create-Equipment-ReservePrice"
                                          fullWidth
                                          placeholder="Reserve Price"
                                          type="number"
                                          name="reservePrice"
                                          InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                          }}
                                          value={reservePrice}
                                          onChange={handleFirstInputChange}
                                          onBlur={handleBlur}
                                        />
                                      </FormControl>
                                    </FormControlWrapper>
                                    <FormControlWrapper>
                                      <FormControl className="w-100">
                                        <TextField
                                          variant="outlined"
                                          label="Comparable Retail Price"
                                          data-test-id="Create-Equipment-ComparableRetailPrice"
                                          fullWidth
                                          placeholder="Comparable Retail Price"
                                          type="number"
                                          name="comparableRetailPrice"
                                          InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                          }}
                                          value={formState.values.comparableRetailPrice}
                                          onChange={handleStartCaseChange}
                                          onBlur={handleBlur}
                                        />
                                      </FormControl>
                                    </FormControlWrapper>
                                    <FormControlWrapper>
                                      <FormControl className="w-100"
                                        error={hasError('listingPrice')}
                                        helperText={
                                          hasError('listingPrice') ? formState.errors.listingPrice[0] : null
                                        }
                                      >
                                        <TextField
                                          error={hasError('listingPrice')}
                                          helperText={
                                            hasError('listingPrice') ? formState.errors.listingPrice[0] : null
                                          }
                                          variant="outlined"
                                          label="Listing Price"
                                          data-test-id="Create-Equipment-ListingPrice"
                                          fullWidth
                                          placeholder="Listing Price"
                                          type="number"
                                          name="listingPrice"
                                          value={listingPrice}
                                          InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                          }}
                                          onChange={handleSecondInputChange}
                                          onBlur={handleBlur}
                                        />
                                        {isValid ? null : <p style={{ color: 'red' }}>Listing Price value must be greater than the Reserve Price value</p>}
                                      </FormControl>
                                    </FormControlWrapper>
                                  <FormControlWrapper>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        label="Select Start Date"
                                        name="availabilityStartDate"
                                        minDate={new Date()}
                                        value={formState.values.availabilityStartDate}
                                        onChange={(date) => handleStartDateChange('availabilityStartDate', date.toISOString())}
                                        renderInput={(params) => <TextField ref={params.error = false} variant='outlined' {...params} />}
                                      />
                                    </LocalizationProvider>
                                  </FormControlWrapper>
                                  <FormControlWrapper>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        label="Select End Date"
                                        name="availabilityEndDate"
                                        minDate={formState.values.availabilityStartDate === '' ? new Date() : formState.values.availabilityStartDate}
                                        value={formState.values.availabilityEndDate}
                                        onChange={(date) => handleStartDateChange('availabilityEndDate', date.toISOString())}
                                        renderInput={(params) => <TextField ref={params.error = false} variant='outlined' {...params} />}
                                      />
                                    </LocalizationProvider>
                                  </FormControlWrapper>
                                  <FormControlWrapper>
                                    <FormControl variant="outlined" className='w-100'
                                    >
                                      <InputLabel id="create-company-state-select-outlined-label">Availability Status </InputLabel>
                                      <Select
                                        error={hasError('availabilityStateTransitionAvailabilityStatus')}
                                        label="Availability Status "
                                        value={formState.values.availabilityStatus}
                                        onChange={handleChange}
                                        inputProps={{
                                          name: 'availabilityStatus',
                                          id: 'availabilityStatus',
                                        }}
                                      >
                                        <MenuItem value="0" disabled>Select Availability Status</MenuItem>
                                        {[{ name: 'Unavailable' }, { name: 'Ready' }, { name: 'Pre-Order' }].map((availabilityStatus) => <MenuItem value={availabilityStatus.name}>{availabilityStatus.name}</MenuItem>)}
                                      </Select>
                                      {hasError('availabilityStatus') && (
                                        <FormHelperText htmlFor="form-selector" error={hasError('availabilityStatus')}>
                                          {formState.errors.availabilityStatus[0]}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </FormControlWrapper>
                                </div> : null}
                              </FormControl>
                            </FormControlWrapper>
                          </Grid>
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <Grid container justifyContent="center" spacing={5}>
                                <Grid item>
                                  <FormButtonWrapper>
                                    {/* <Link to="/equipment" className='linkStyle'> */}
                                    <Button
                                      type="cancel"
                                      color="secondary"
                                      data-test-id="Create-Equipment-Cancel"
                                      variant="contained"
                                      size="large"
                                      onClick={handleCancel}
                                      className="my-2">
                                      cancel
                                    </Button>
                                    {/* </Link> */}
                                  </FormButtonWrapper>
                                </Grid>
                                <Grid item>
                                  <FormButtonWrapper>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      variant="contained"
                                      data-test-id="Create-Equipment-Submit"
                                      size="large"
                                      className="Buttons">
                                      {action === 'edit' ? 'Update' : 'Submit'}
                                    </Button>
                                  </FormButtonWrapper>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </AppMain>
        </AppWrapper>
      </AppContentInner>
    </AppContent>
  );
};
const mapStateToProps = state => ({
  restClient: state.restClient,
  equipmentData: state.equipment.equipmentData,
  companyList: state.company.companyList.companyList,
  userList: state.user && state.user.userList.userList,
  loggedInUserData: state.user && state.user.loggedInUserData,
  userData: state.user.userData,
});
export default connect(mapStateToProps, { submitEquipmentRegisterForm, getEquipmentById, editEquipment, getCompanyList, getUserList, setLoggedInUserData })(CreateEquipment);